import {EcBaseTerritoryItem} from '../../ec-00-base/ec-base-territory.model';

export enum EcDelivereoCities {
  'AMBATO' = 'AMBATO',
  'ATACAMES' = 'ATACAMES',
  'BABAHOYO' = 'BABAHOYO',
  'CAYAMBE' = 'CAYAMBE',
  'COJIMIES' = 'COJIMIES',
  'CUENCA' = 'CUENCA',
  'ESMERALDAS' = 'ESMERALDAS',
  'GUAYAQUIL' = 'GUAYAQUIL',
  'IBARRA' = 'IBARRA',
  'LATACUNGA' = 'LATACUNGA',
  'LA_CONCORDIA' = 'LA_CONCORDIA',
  'LA_LIBERTAD' = 'LA_LIBERTAD',
  'MACHACHI' = 'MACHACHI',
  'MACHALA' = 'MACHALA',
  'MANTA' = 'MANTA',
  'MILAGRO' = 'MILAGRO',
  'OTAVALO' = 'OTAVALO',
  'PEDERNALES' = 'PEDERNALES',
  'PORTOVIEJO' = 'PORTOVIEJO',
  'QUEVEDO' = 'QUEVEDO',
  'QUININDE' = 'QUININDE',
  'QUITO' = 'QUITO',
  'RIOBAMBA' = 'RIOBAMBA',
  'SALINAS' = 'SALINAS',
  'SANGOLQUI' = 'SANGOLQUI',
  'SANTA_ROSA' = 'SANTA_ROSA',
  'SANTO_DOMINGO' = 'SANTO_DOMINGO',
}

export interface EcDelivereoTerritoryItem extends Omit<EcBaseTerritoryItem, 'searchableText' | 'territory1Name'> {
  territory1Name?: string;
  territory3Id: string;
}

/**
 * A list of territories for EcDelivereo.
 *
 * This list contains the most common territories used in Delivereo,
 * identified by their `territory2Id` and `territory3Id` properties.
 *
 * - `territory2Id`: Represents the primary identifier for a territory.
 * - `territory3Id`: Represents an additional identifier for specific territories.
 *
 * Note:
 * - `territory2Id` is used for the rest of the territories.
 * - `territory3Id` is used for Machachi and Sangolqui.
 * - The commented territories are active in delivereo but currently in PROD we support fewer territories as a business decision.
 *
 * Example usage:
 * ```typescript
 * const territories = EcDelivereoTerritoryList.map(item => item.territory2Id);
 * ```
 */
export const EcDelivereoTerritoryList: EcDelivereoTerritoryItem[] = [
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AMBATO',
    territory2Name: EcDelivereoCities.AMBATO,
    territory3Id: EcDelivereoCities.AMBATO,
    territory3Name: EcDelivereoCities.AMBATO,
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CUENCA',
    territory2Name: EcDelivereoCities.CUENCA,
    territory3Id: EcDelivereoCities.CUENCA,
    territory3Name: EcDelivereoCities.CUENCA,
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GUAYAQUIL',
    territory2Name: EcDelivereoCities.GUAYAQUIL,
    territory3Id: EcDelivereoCities.GUAYAQUIL,
    territory3Name: EcDelivereoCities.GUAYAQUIL,
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|MANTA',
    territory2Name: EcDelivereoCities.MANTA,
    territory3Id: EcDelivereoCities.MANTA,
    territory3Name: EcDelivereoCities.MANTA,
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SANGOLQUI',
    territory2Name: EcDelivereoCities.SANGOLQUI,
    territory3Id: EcDelivereoCities.SANGOLQUI,
    territory3Name: EcDelivereoCities.SANGOLQUI,
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITO',
    territory2Name: EcDelivereoCities.QUITO,
    territory3Id: EcDelivereoCities.QUITO,
    territory3Name: EcDelivereoCities.QUITO,
  },
];
