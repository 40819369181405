import {Relation} from 'typeorm';
import {UnionTypeFromArray} from '../../../../utils/common';
import {Currency} from '../../../../utils/models';
import {IBaseUserOwnedEntity} from '../../../core/data-access/base-models/base-user-owned.model';
import {IUser} from '../../../core/data-access/user/user.model';
import {IPaymentIntent} from '../../../payment-intent/data-access/payment-intent.model';

export const EcPayphoneApiBaseUrl = 'https://pay.payphonetodoesposible.com/api';

/**
 * This should come from the types that it's received in the response "IPaylogEcPayphoneConfirmRes.transactionStatus"
 */
export const IPaylogEcPayphoneConfirmResTransactionStatus = ['Approved', 'Canceled'] as const;
export type IPaylogEcPayphoneConfirmResTransactionStatus = UnionTypeFromArray<
  typeof IPaylogEcPayphoneConfirmResTransactionStatus
>;

export const IPaylogEcPayphoneConfirmResCardType = [
  'Debit', // when a debit card is used, this creates a processing fee.
  'Credit', // when a credit card is used, this creates a processing fee.
  'PayPhone', // when the balance of ecPayphone is used, this DOES NOT create a processing fee.
  'Test', // when a card in the TESTING environment is used, this does not create any real payment.
] as const;
export type IPaylogEcPayphoneConfirmResCardType = UnionTypeFromArray<typeof IPaylogEcPayphoneConfirmResCardType>;

export interface IPaylogEcPayphonePrepareReq {
  amount: number; // this value is stored format $5.01. A transformer must be used to send to Payphone API which requires USD cents as an integer (ex. 5.01 => 501)
  amountWithoutTax: number; // this is in USD cents, and it MUST be always the same as "IPaylogEcPayphonePrepareReq.amount"
  currency: Currency;

  /**
   * This is a text only needed we need to generate for the Payphone API, and it's not relevant for our app.
   * It MUST be a unique value, so we use the paymentIntent.objectId as it's unique value (of type number).
   */
  clientTransactionId: string;

  reference: string;

  // storeId: string, // This is only needed when there are multiple 'sucursales' in our payphone business account. For the moment we only use the same 'sucursal' for all the transactions in the app.
  // responseUrl: string, // Do not store this as it's stored in an enviromental variable
  // cancellationUrl: string, // Do not store this as it's stored in an enviromental variable
}

export interface IPaylogEcPayphonePrepareRes {
  /**
   * When the ...api/prepare is successful, the EcPayphone returns a paymentId
   */
  paymentId?: string;
  payWithCard?: string;
  // payWithPayPhone: string, // We don't store this as its always the same format as "https://pay.payphonetodoesposible.com/PayPhone/Index?paymentId=<paymentId>",

  /**
   * When an error occurs, here we save the error response as a stringified JSON
   */
  message?: string;
}

/**
 * These values "id" and "clientTransactionId" can only be seen when the Payphone redirection executes (on a successful payment or cancellation),
 * and MUST be get from the URL queries in the webhook IPaylogEcPayphonePrepareReq.responseUrl.
 * Example, when a payment is successful the Payphone app redirects to the response URL:
 *    https://url.com/response.php?id=1234567&clientTransactionId=ABC456
 */
export interface IPaylogEcPayphoneUrlRedirectionParams {
  id: string; // Unique identifier created by EcPayphone after the payment was processed, and returned to the 'responseUrl'. This value is generated from the Payphone API.
  clientTransactionId: string; // MUST be the same as 'IPaylogEcPayphonePrepareReq.clientTransactionId'
  msg?: string; // Message in case of API error. Ex. when the 'PrepareReq' API route is called from a non-allowed domain (localhost, ...), we get this error in the URL: "Your domain is not allowed by the application. Go to the developer console to configure correctly."
}

/**
 * This interface 'IPaylogEcPayphoneConfirmReq' is modeled from the data of 'IPaylogEcPayphoneUrlRedirectionParams',
 * and it's used to make the ecPayphone API call to check the validity of the payment that came from a URL redirection.
 */
export interface IPaylogEcPayphoneConfirmReq {
  id: string; // same as IPaylogEcPayphoneUrlRedirectionParams.id
  clientTxId: string; // same as IPaylogEcPayphoneUrlRedirectionParams.clientTransactionId
  msg?: string; // same as IPaylogEcPayphoneUrlRedirectionParams.msg
}

export interface IPaylogEcPayphoneConfirmRes {
  email?: string;
  cardType?: string; // Options from IPaylogEcPayphoneConfirmResCardType
  bin?: string;
  lastDigits?: string;
  deferredCode?: string;
  deferredMessage?: string;
  deferred?: boolean;
  cardBrandCode?: string;
  cardBrand?: string;
  amount?: number; // this is in USD cents
  clientTransactionId?: string; // MUST be the same as 'IPaylogEcPayphonePrepareReq.clientTransactionId'. To confirm the payment in our DB, we must look for an previously saved object with this value in "IPaylogEcPayphonePrepareReq.clientTransactionId" and confirm a successful payment.
  phoneNumber?: string; // Format is ALL number and includes the country code. Example: 593987654321, as an ecuadorian number (+593) 987654321
  statusCode?: number; // Options: 3 (Approved), 2 (Canceled)
  transactionStatus?: string; // Options: 'Approved', 'Canceled'. From IPaylogEcPayphoneConfirmResTransactionStatus
  authorizationCode?: string; // This is what we can double-cross reference in the EcPayphone admin webapp. The format is "W12345678", where "12345678" should be the same as the field in this object "transactionId"
  message?: string;
  messageCode?: number;
  transactionId?: string; // MUST be the same as 'IPaylogEcPayphoneConfirmReq.id'. This value is generated from the Payphone API.
  document?: string; // National ID Number (EC: Cédula Identidad)
  taxes?: string; // API returns an array, and we transform it to stringified JSON
  currency?: Currency;
  optionalParameter1?: string; // phone number
  optionalParameter2?: string; // email
  optionalParameter3?: string; // ?
  optionalParameter4?: string; // firstname lastname
  storeName?: string;
  date?: string; // This date is in UTC-5 (Ecuador time), so save it as a string
  regionIso?: string; // Options: 'EC'
  transactionType?: string; // Options: 'Classic';
  recap?: string;
  reference?: string; // MUST be the same as 'IPaylogEcPayphonePrepareReq.reference'
}

export interface IPaylogEcPayphone extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  prepareReq?: IPaylogEcPayphonePrepareReq; // this data is generated in our back-end api
  prepareRes?: IPaylogEcPayphonePrepareRes; // this data is generated from EcPayphone API, called from the front-end app

  confirmReq?: IPaylogEcPayphoneConfirmReq; // this data is generated from EcPayphone API, with the redirection URL query fields
  confirmRes?: IPaylogEcPayphoneConfirmRes; // this data is generated from EcPayphone API, called from the back-end api

  /**
   * OneToOne relation to paymentIntent
   */
  paymentIntent?: Relation<IPaymentIntent>;
}
