import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, IShipment, IUser} from '../../..';
import {NUMBER_DIMENSION_SCALE} from '../../../../utils/common';
import {IDimension} from '../../../../utils/models';

export const EC_SERVIENTREGA_TARIFFS_V1 = 'v-2023-11-07';
export const EC_SERVIENTREGA_TARIFFS_V2 = 'v-2023-12-07';

export const SERVIENTREGA_MAX_GOODS_INSURED = 20000; // in USD
export const SERVIENTREGA_MAX_GOODS_COLLECTED = 200; // in USD

/**
 * Usage example of EC_SERVIENTREGA_LABEL_TRACKING_URL, change the <%=carrierLabelUniqueId%> for the label ID.
 * Example:
 * https://www.servientrega.com.ec/tracking/?guia=250000152&tipo=GUIA
 */
export const EC_SERVIENTREGA_LABEL_TRACKING_URL = `https://www.servientrega.com.ec/tracking/?guia=<%=carrierLabelUniqueId%>&tipo=GUIA`;

/**
 *
 * @returns // Servientrega volumetric Weight based on Servientrega's fixed formula
 */
export function getEcServientregaVolumetricWeight(dimension: IDimension): number {
  const volumetricWeight = (dimension.height * dimension.width * dimension.length) / 6000;
  return parseFloat(volumetricWeight.toFixed(NUMBER_DIMENSION_SCALE));
}

export enum ServientregaLabelRequestProducto {
  /**
   * For now use always the "id_producto: 2" (MERCANCIA_PREMIER)
   */
  'DOCUMENTO UNITARIO' = 1,
  'MERCANCIA PREMIER' = 2,
  'DOCUMENTO MASIVO' = 3,
  'MERCANCIA INDUSTRIAL' = 6,
  'VALIJA EMPRESARIAL' = 8,
}

/**
 * 'IServientregaLabelRequest' is used as a backup to store the body's request of the Servientrega API endpoint 'POST /api/GuiaWebs'.
 *
 * CamelCase is not used in this interface as this format comes from Carrier API.
 */
export interface IServientregaLabelRequest {
  id_tipo_logistica: number;
  detalle_envio_1?: string;
  detalle_envio_2?: string;
  detalle_envio_3?: string;
  id_ciudad_origen: string;
  id_ciudad_destino: string;
  id_destinatario_ne_cl: string;
  razon_social_desti_ne: string;
  nombre_destinatario_ne: string;
  apellido_destinatar_ne: string;
  direccion1_destinat_ne: string;
  sector_destinat_ne?: string;
  telefono1_destinat_ne: string;
  telefono2_destinat_ne?: string;
  codigo_postal_dest_ne?: string;
  id_remitente_cl: string;
  razon_social_remite: string;
  nombre_remitente: string;
  apellido_remite: string;
  direccion1_remite: string;
  sector_remite?: string;
  telefono1_remite: string;
  telefono2_remite?: string;
  codigo_postal_remi?: string;
  id_producto: ServientregaLabelRequestProducto;
  contenido: string; // NOTE: It should never be an empty string, according to Servientrega's API
  numero_piezas: number;
  valor_mercancia: number;
  valor_asegurado: number;
  largo: number;
  ancho: number;
  alto: number;
  peso_fisico: number;
  login_creacion?: string; // NOTE: don't save in the DB, the field "login_creacion"
  password?: string; // NOTE: don't save in the DB, the field "password"
}

export enum ServientregaLabelResponseResponseMessage {
  labelGeneratedCorrectly = 'GUÍA REGISTRADA CORRECTAMENTE', // OK
  errorLabelOutOfStock = 'NO CUENTA CON NÚMERO DE GUÍAS ASIGNADAS', // NOTOK
  errorTooLongAddress = 'ERROR AL REGISTRAR GUÍA #333 727300001', // NOTOK
}

/**
 * 'IServientregaLabelResponse' is used as a backup to store the response of the Servientrega API endpoint 'POST /api/GuiaWebs'.
 *
 * CamelCase is not used in this interface as this format comes from Carrier API.
 */
export interface IServientregaLabelResponse {
  id?: number; // id_label // TODO p3 refractor it to string
  msj?: string | ServientregaLabelResponseResponseMessage;
}

/**
 * 'IServientregaLabelRegistered' is used as a backup to store the response of the Servientrega API endpoint 'GET /api/GuiaWebs/["id_label","login_creacion","password"]',
 * in order to query the registered label's data in the Servientrega's DB
 *
 * CamelCase is not used in this interface as this format comes from Carrier API.
 */
export interface IServientregaLabelRegistered {
  numero_guia?: number; // id_label
  tipo_logistica?: string;
  tipo_guia?: string;
  cliente?: string;
  sucursal?: string;
  dependencia?: string;
  detalle1?: string;
  detalle2?: string;
  detalle3?: string;
  destino?: string;
  origen?: string;
  remitente?: string;
  razon_social_remitente?: string;
  direccion_remitente?: string;
  sector_remitente?: string;
  telefono1_remitente?: string;
  telefono2_remitente?: string;
  destinatario?: string;
  razon_social_destinatario?: string;
  direccion_destinatario?: string;
  sector_destinatario?: string;
  producto?: string;
  contenido?: string;
  cantidad?: number;
  valor_mercancia?: number;
  valor_asegurado?: number;
  largo?: number;
  ancho?: number;
  alto?: number;
  peso_volumetrico?: number;
  peso_fisico?: number;
  fecha?: string; // TODO p4 should be Date
  /**
   * TODO p1 add 'estado' types from servientrega. For now, the ones known are:
   *
   * 1 INGRESADA (label created)
   * 2 GENERADA (label PDF generated)
   * 3 ANULADA
   * 4 EN PROCESAMIENTO
   * 5 REPORTADO COMO ENTREGADO
   * 6 REPORTADO COMO DEVUELTO
   * 7 PENDIENTE PROC. LOGISTICO
   * 8 EN PROCESAMIENTO (HISTORICO)
   * 9 DEVUELTO AL REMITENTE 99 GENERADO POR API
   */
  estado?: string;
  registrado_por?: string;
}

export enum ServientregaLabelPdfResponseMessage {
  pdfGeneratedCorrectly = 'LA GUÍA FUE GENERADA CORRECTAMENTE', // OK
  pdfPreviouslyGeneratedCorrectly = 'LA GUÍA YA FUE IMPRESA', // OK
  errorUnknown = "The required column 'AVISO' was not present in the results of a 'FromSql' operation. ", // NOTOK
}

/**
 * 'IServientregaLabelPdf' is used as a backup to store the response of the Servientrega API endpoint 'GET /api/GuiasWeb/["id_label","login_creacion","password","id_status_label"]',
 * in order to query the registered label' pdf in the Servientrega's DB
 *
 * CamelCase is not used in this interface as this format comes from Carrier API.
 */
export interface IServientregaLabelPdf {
  guia?: number;
  mensaje?: string | ServientregaLabelPdfResponseMessage;
  archivoEncriptado?: string; // base64 pdf content
  // archivoEncriptadoPdfUrl?: string; // pdf URL // TODO p2, add a link to the pdf URL when file storage service is implemented
}

export interface IServientregaLabelGenerate extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;
  request: IServientregaLabelRequest;
  response?: IServientregaLabelResponse;
  registered?: IServientregaLabelRegistered;
  pdf?: IServientregaLabelPdf;

  /**
   * OneToOne relation to shipment
   */
  shipment?: Relation<IShipment>;
}
