import {Relation} from 'typeorm';
import {IBaseCrudEntity, ICollectiveServiceLevel} from '../..';
import {Country} from '../../../utils/models';

/**
 * `CollectiveCarrierToken` must be unique
 *
 * Format of the CollectiveCarrierToken:
 *
 * `countryShortCode_carrierName`
 *
 * ---
 * Example 1: For the carrier "Fedex", located in the United States, token would be:
 *
 * `us_fedex`
 *
 * Example 2: For the carrier "Fedex", located in the Switzerland, token would be:
 *
 * `ch_fedex`
 *
 * Example 3: For the carrier "Shipper Turtle", located in the Ecuador, token would be:
 *
 * `ec_shipperTurtle`
 */
export enum CollectiveCarrierToken {
  carrier123 = 'carrier123', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  carrier456 = 'carrier456', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  carrier789 = 'carrier789', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  servientrega = 'servientrega', // TODO p1 change to 'ec_servientrega'
  ec_tramaco = 'ec_tramaco',
  // <NEW_CARRIER> = '<NEW_CARRIER>' // NOTE: add here a new carrier token
}

export const CollectiveTestCarrierTokens = [
  CollectiveCarrierToken.carrier123,
  CollectiveCarrierToken.carrier456,
  CollectiveCarrierToken.carrier789,
];

export interface ICollectiveCarrier extends IBaseCrudEntity {
  token: CollectiveCarrierToken;
  country: Country;
  name: string;

  /**
   * When `isActive` is false, it should be EXCLUDED in the app/api
   */
  isActive: boolean;

  /**
   * When `isTestCarrier` is true, don't use this Carrier in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES'). A TEST carrier is used only for debugging/demo purposes, and not for production/live app.
   * When `isTestCarrier` is false, use this Carrier in production as it's a real carrier, for which we have a business contract with them.
   */
  isTestCarrier: boolean;

  collectiveServiceLevels?: Relation<ICollectiveServiceLevel[]>;
}
