import {TerritoryBaseId} from '../../../address/data-access/address.model';
import {EcDelivereoTerritoryItem} from '../ec-delivereo/territory/ec-delivereo-territory-list';
import {EcLaarTerritoryItem} from '../ec-laar/territory/ec-laar-territory.list';
import {EcServientregaTerritoryItem} from '../ec-servientrega/territory/ec-servientrega-territory.list';
import {EcTramacoTerritoryItem} from '../ec-tramaco/territory/ec-tramaco-territory.list';

export interface EcBaseTerritoryItem {
  // country?: Country;
  territoryBaseId: TerritoryBaseId;
  // territory1Id?: string; // TODO: Might not be needed as `territory1Name` should be a unique text within its own country. Maybe delete this line.
  territory1Name: string; // Level 1 political territory. Ex. In Ecuador (provincia / province), in the US (state), in Switzerland (kanton / canton)
  // territory2Id?: string; // TODO: Might not be needed as `territory2Name` should be a unique text within its own country. Maybe delete this line.
  territory2Name: string; // Level 2 political territory. Ex. In Ecuador (canton / canton), in the US (city), in Switzerland (stadt / city )
  // territory3Id?: string; // TODO: Might not be needed as `territory3Name` should be a unique text within its own country. Maybe delete this line.
  territory3Name: string; // Level 3 political territory. Ex. In Ecuador (parroquia / parish), in the US (county), in Switzerland (gemeinde / municipality)
  searchableText: string; // Used for the front-end searchable input to store something like "State - City - County"
  lat?: number; // geometric center point in this territory
  lng?: number; // geometric center point in this territory
}

/**
 * Names of each of the administrative division levels in the country (in the local's country language).
 */
export enum EcBaseTerritoryLevelLabel {
  territory1Label = 'Provincia', // Level 1 political territory.
  territory2Label = 'Cantón', // Level 2 political territory.
  territory3Label = 'Parroquia', // Level 3 political territory.
}

export type EcAllTerritoryItemType =
  // | <NEW_CARRIER>TerritoryItem
  | EcBaseTerritoryItem
  | EcServientregaTerritoryItem
  | EcTramacoTerritoryItem
  | EcLaarTerritoryItem
  | EcDelivereoTerritoryItem;
