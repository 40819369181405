import {EcBaseTerritoryItem} from '../../ec-00-base/ec-base-territory.model';

export interface EcLaarTerritoryItem extends Omit<EcBaseTerritoryItem, 'searchableText'> {
  territory1Id: string;
  territory3Id: string;
}

// TODO-JC sync all
export const EcLaarTerritoryList: EcLaarTerritoryItem[] = [
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|BAÑOS_(AZ)', //d
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'BAÑOS CUENCA',
    territory3Id: '201001003035',
    territory3Name: 'BAÑOS CUENCA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHAULLABAMBA|:|CHAULLABAMBA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CHAULLABAMBA',
    territory3Id: '201001003016',
    territory3Name: 'CHAULLABAMBA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHECA_(JIDCAY)|:|CHECA_(JIDCAY)',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CHECA (JIDCAY)',
    territory3Id: '201001003036',
    territory3Name: 'CHECA (JIDCAY)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHIQUINTAD|:|CHIQUINTAD',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CHIQUINTAD',
    territory3Id: '201001003037',
    territory3Name: 'CHIQUINTAD',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHORDELEG|:|CHORDELEG',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CHORDELEG',
    territory3Id: '201001003006',
    territory3Name: 'CHORDELEG',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|BELLAVISTA_(AZ)', //d
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Id: '201001003011',
    territory3Name: 'BELLAVISTA (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CUENCA', //d
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Id: '201001003011',
    territory3Name: 'CUENCA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUMBE|:|CUMBE',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'CUMBE',
    territory3Id: '201001003017',
    territory3Name: 'CUMBE',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|FIRMA|:|FIRMA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'FIRMA',
    territory3Id: '201001003039',
    territory3Name: 'FIRMA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GIRON|:|GIRON',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'GIRON',
    territory3Id: '201001003002',
    territory3Name: 'GIRON',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GUALACEO|:|GUALACEO',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'GUALACEO',
    territory3Id: '201001003003',
    territory3Name: 'GUALACEO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|LLACAO|:|LLACAO',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'LLACAO',
    territory3Id: '201001003021',
    territory3Name: 'LLACAO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|NULTI|:|NULTI',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'NULTI',
    territory3Id: '201001003022',
    territory3Name: 'NULTI',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|OÑA|:|OÑA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'OÑA',
    territory3Id: '201001016002',
    territory3Name: 'OÑA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PACCHA|:|PACCHA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'PACCHA',
    territory3Id: '201001003023',
    territory3Name: 'PACCHA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|PAUTE',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'PAUTE',
    territory3Id: '201001003004',
    territory3Name: 'PAUTE',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CAMILO_PONCE_ENRIQUEZ|:|CAMILO_PONCE_ENRIQUEZ', //d
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'PONCE ENRIQUEZ',
    territory3Id: '201001009013',
    territory3Name: 'PONCE ENRIQUEZ',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|RICAURTE_(AZ)', //d
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'RICAURTE CUENCA',
    territory3Id: '201001003020',
    territory3Name: 'RICAURTE CUENCA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SAN_FERNANDO|:|SAN_FERNANDO',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SAN FERNANDO',
    territory3Id: '201001003024',
    territory3Name: 'SAN FERNANDO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SAN_JOAQUÍN|:|SAN_JOAQUÍN',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SAN JOAQUÍN',
    territory3Id: '201001003025',
    territory3Name: 'SAN JOAQUÍN',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SAN_JUAN_CUENCA|:|SAN_JUAN_CUENCA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SAN JUAN CUENCA',
    territory3Id: '201001003034',
    territory3Name: 'SAN JUAN CUENCA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SANTA_ISABEL|:|SANTA_ISABEL',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SANTA ISABEL',
    territory3Id: '201001003005',
    territory3Name: 'SANTA ISABEL',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SAYAUSI|:|SAYAUSI',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SAYAUSI',
    territory3Id: '201001003040',
    territory3Name: 'SAYAUSI',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIDCAY|:|SIDCAY',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SIDCAY',
    territory3Id: '201001003026',
    territory3Name: 'SIDCAY',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIG_SIG|:|SIG_SIG',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SIG SIG',
    territory3Id: '201001003007',
    territory3Name: 'SIG SIG',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIMÓN_BOLÍVAR_(CAB._EN_GAÑANZOL)|:|SIMÓN_BOLÍVAR_(CAB._EN_GAÑANZOL)',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SIMÓN BOLÍVAR (CAB. EN GAÑANZOL)',
    territory3Id: '201001003027',
    territory3Name: 'SIMÓN BOLÍVAR (CAB. EN GAÑANZOL)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SININCAY|:|SININCAY',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SININCAY',
    territory3Id: '201001003028',
    territory3Name: 'SININCAY',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SOSUDEL|:|SOSUDEL',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SOSUDEL',
    territory3Id: '201001003038',
    territory3Name: 'SOSUDEL',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SUSCAL|:|SUSCAL',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'SUSCAL',
    territory3Id: '201001005008',
    territory3Name: 'SUSCAL',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|TARQUI_CUENCA|:|TARQUI_CUENCA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'TARQUI CUENCA',
    territory3Id: '201001003033',
    territory3Name: 'TARQUI CUENCA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|TARQUI|:|TARQUI',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'TARQUI',
    territory3Id: '201001014026',
    territory3Name: 'TARQUI',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|TOMEBAMBA|:|TOMEBAMBA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'TOMEBAMBA',
    territory3Id: '201001003029',
    territory3Name: 'TOMEBAMBA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TURI', //d
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'TURI',
    territory3Id: '201001003030',
    territory3Name: 'TURI',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|VICTORIA_DEL_PORTETE_(IRQUIS)|:|VICTORIA_DEL_PORTETE_(IRQUIS)',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'VICTORIA DEL PORTETE (IRQUIS)',
    territory3Id: '201001003031',
    territory3Name: 'VICTORIA DEL PORTETE (IRQUIS)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|YUNGUILLA|:|YUNGUILLA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'YUNGUILLA',
    territory3Id: '201001003018',
    territory3Name: 'YUNGUILLA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|ZHAGLLI_(SHAGLLI)|:|ZHAGLLI_(SHAGLLI)',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'ZHAGLLI (SHAGLLI)',
    territory3Id: '201001003032',
    territory3Name: 'ZHAGLLI (SHAGLLI)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|ZONA_FRANCA|:|ZONA_FRANCA',
    territory1Id: '201001003',
    territory1Name: 'AZUAY',
    territory2Name: 'ZONA FRANCA',
    territory3Id: '201001003019',
    territory3Name: 'ZONA FRANCA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GUARANDA', //d
    territory1Id: '201001004',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Id: '201001004001',
    territory3Name: 'GUARANDA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SALINAS_DE_GUARANDA|:|SALINAS_DE_GUARANDA',
    territory1Id: '201001004',
    territory1Name: 'BOLIVAR',
    territory2Name: 'SALINAS DE GUARANDA',
    territory3Id: '201001004011',
    territory3Name: 'SALINAS DE GUARANDA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_JOSE_DE_CHIMBO|:|SAN_JOSE_DE_CHIMBO',
    territory1Id: '201001004',
    territory1Name: 'BOLIVAR',
    territory2Name: 'SAN JOSE DE CHIMBO',
    territory3Id: '201001004003',
    territory3Name: 'SAN JOSE DE CHIMBO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL_DE_BOLIVAR|:|SAN_MIGUEL_DE_BOLIVAR',
    territory1Id: '201001004',
    territory1Name: 'BOLIVAR',
    territory2Name: 'SAN MIGUEL DE BOLIVAR',
    territory3Id: '201001004006',
    territory3Name: 'SAN MIGUEL DE BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|AZOGUES', //d
    territory1Id: '201001005',
    territory1Name: 'CAÑAR',
    territory2Name: 'AZOGUES',
    territory3Id: '201001005001',
    territory3Name: 'AZOGUES',
  },
  {
    territoryBaseId: 'EC|:|CAÑAR|:|BIBLIAN|:|BIBLIAN',
    territory1Id: '201001005',
    territory1Name: 'CAÑAR',
    territory2Name: 'BIBLIAN',
    territory3Id: '201001005004',
    territory3Name: 'BIBLIAN',
  },
  {
    territoryBaseId: 'EC|:|CAÑAR|:|CAÑAR|:|CAÑAR',
    territory1Id: '201001005',
    territory1Name: 'CAÑAR',
    territory2Name: 'CAÑAR',
    territory3Id: '201001005003',
    territory3Name: 'CAÑAR',
  },
  {
    territoryBaseId: 'EC|:|CAÑAR|:|COJITAMBO|:|COJITAMBO',
    territory1Id: '201001005',
    territory1Name: 'CAÑAR',
    territory2Name: 'COJITAMBO',
    territory3Id: '201001005007',
    territory3Name: 'COJITAMBO',
  },
  {
    territoryBaseId: 'EC|:|CAÑAR|:|EL_TAMBO|:|EL_TAMBO',
    territory1Id: '201001005',
    territory1Name: 'CAÑAR',
    territory2Name: 'EL TAMBO',
    territory3Id: '201001003009',
    territory3Name: 'EL TAMBO',
  },
  {
    territoryBaseId: 'EC|:|CAÑAR|:|LA_TRONCAL|:|LA_TRONCAL',
    territory1Id: '201001005',
    territory1Name: 'CAÑAR',
    territory2Name: 'LA TRONCAL',
    territory3Id: '201001005002',
    territory3Name: 'LA TRONCAL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|BOLIVAR',
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'BOLIVAR',
    territory3Id: '201001006006',
    territory3Name: 'BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|EL_ANGEL|:|EL_ANGEL',
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'EL ANGEL',
    territory3Id: '201001006003',
    territory3Name: 'EL ANGEL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|HUACA|:|HUACA',
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'HUACA',
    territory3Id: '201001006005',
    territory3Name: 'HUACA',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|JULIO_ANDRADE|:|JULIO_ANDRADE',
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'JULIO ANDRADE',
    territory3Id: '201001002041',
    territory3Name: 'JULIO ANDRADE',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|MIRA|:|MIRA',
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'MIRA',
    territory3Id: '201001006002',
    territory3Name: 'MIRA',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL|:|SAN_GABRIEL',
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL',
    territory3Id: '201001006004',
    territory3Name: 'SAN GABRIEL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|TULCAN', //d
    territory1Id: '201001006',
    territory1Name: 'CARCHI',
    territory2Name: 'TULCAN',
    territory3Id: '201001006001',
    territory3Name: 'TULCAN',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|ALAUSI|:|ALAUSI',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'ALAUSI',
    territory3Id: '201001008002',
    territory3Name: 'ALAUSI',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CAJABAMBA|:|CAJABAMBA',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CAJABAMBA',
    territory3Id: '201001008005',
    territory3Name: 'CAJABAMBA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CHAMBO|:|CHAMBO',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CHAMBO',
    territory3Id: '201001004008',
    territory3Name: 'CHAMBO',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CHUNCHI|:|CHUNCHI',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CHUNCHI',
    territory3Id: '201001008003',
    territory3Name: 'CHUNCHI',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|COLTA|:|COLTA',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'COLTA',
    territory3Id: '201001008008',
    territory3Name: 'COLTA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CUMANDA_CHIMBORAZO|:|CUMANDA_CHIMBORAZO',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CUMANDA CHIMBORAZO',
    territory3Id: '201001008012',
    territory3Name: 'CUMANDA CHIMBORAZO',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUAMOTE|:|GUAMOTE',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUAMOTE',
    territory3Id: '201001008007',
    territory3Name: 'GUAMOTE',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|GUANO',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUANO',
    territory3Id: '201001008004',
    territory3Name: 'GUANO',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|LICAN|:|LICAN',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'LICAN',
    territory3Id: '201001008010',
    territory3Name: 'LICAN',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|RIOBAMBA', //d
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Id: '201001008001',
    territory3Name: 'RIOBAMBA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|YARUQUIES|:|YARUQUIES',
    territory1Id: '201001008',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'YARUQUIES',
    territory3Id: '201001008011',
    territory3Name: 'YARUQUIES',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|BELISARIO_QUEVEDO|:|BELISARIO_QUEVEDO',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'BELISARIO QUEVEDO',
    territory3Id: '201001007012',
    territory3Name: 'BELISARIO QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|GUAYTACAMA|:|GUAYTACAMA',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'GUAYTACAMA',
    territory3Id: '201001007009',
    territory3Name: 'GUAYTACAMA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LASSO|:|LASSO',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LASSO',
    territory3Id: '201001007007',
    territory3Name: 'LASSO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LATACUNGA', //d
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Id: '201001007001',
    territory3Name: 'LATACUNGA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|MULALAO|:|MULALAO',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'MULALAO',
    territory3Id: '201001007015',
    territory3Name: 'MULALAO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|MULALO|:|MULALO',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'MULALO',
    territory3Id: '201001007010',
    territory3Name: 'MULALO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PASTOCALLE|:|PASTOCALLE',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PASTOCALLE',
    territory3Id: '201001007008',
    territory3Name: 'PASTOCALLE',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PUJILI|:|PUJILI', //d
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PUJILI',
    territory3Id: '201001007004',
    territory3Name: 'PUJILI',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SALCEDO', //d
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Id: '201001007002',
    territory3Name: 'SALCEDO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SAN_BUENAVENTURA_BELLAVISTA.|:|SAN_BUENAVENTURA_BELLAVISTA.',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SAN BUENAVENTURA BELLAVISTA.',
    territory3Id: '201001007013',
    territory3Name: 'SAN BUENAVENTURA BELLAVISTA.',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SAQUISILI|:|SAQUISILI',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SAQUISILI',
    territory3Id: '201001007003',
    territory3Name: 'SAQUISILI',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|TANICUCHCHI|:|TANICUCHCHI',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'TANICUCHCHI',
    territory3Id: '201001007014',
    territory3Name: 'TANICUCHCHI',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|TANICUCHI|:|TANICUCHI',
    territory1Id: '201001007',
    territory1Name: 'COTOPAXI',
    territory2Name: 'TANICUCHI',
    territory3Id: '201001007011',
    territory3Name: 'TANICUCHI',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ARENILLAS|:|ARENILLAS',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'ARENILLAS',
    territory3Id: '201001009006',
    territory3Name: 'ARENILLAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_CAMBIO|:|EL_CAMBIO',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'EL CAMBIO',
    territory3Id: '201001009011',
    territory3Name: 'EL CAMBIO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|EL_GUABO',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'EL GUABO',
    territory3Id: '201001009008',
    territory3Name: 'EL GUABO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|HUAQUILLAS', //d
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '201001009007',
    territory3Name: 'HUAQUILLAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|LA_AVANZADA|:|LA_AVANZADA',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'LA AVANZADA',
    territory3Id: '201001009019',
    territory3Name: 'LA AVANZADA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|LA_PEAÑA|:|LA_PEAÑA',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'LA PEAÑA',
    territory3Id: '201001009017',
    territory3Name: 'LA PEAÑA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|LOMA_DE_FRANCO|:|LOMA_DE_FRANCO',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'LOMA DE FRANCO',
    territory3Id: '201001009018',
    territory3Name: 'LOMA DE FRANCO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|MACHALA', //d
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Id: '201001009001',
    territory3Name: 'MACHALA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|PASAJE', //d
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Id: '201001009002',
    territory3Name: 'PASAJE',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|PIÑAS', //d
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'PIÑAS',
    territory3Id: '201001009004',
    territory3Name: 'PIÑAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PORTOVELO|:|PORTOVELO',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'PORTOVELO',
    territory3Id: '201001009003',
    territory3Name: 'PORTOVELO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PUERTO_BOLIVAR|:|PUERTO_BOLIVAR',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'PUERTO BOLIVAR',
    territory3Id: '201001009010',
    territory3Name: 'PUERTO BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|SANTA_ROSA',
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Id: '201001009009',
    territory3Name: 'SANTA ROSA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ZARUMA|:|ZARUMA', //d
    territory1Id: '201001009',
    territory1Name: 'EL ORO',
    territory2Name: 'ZARUMA',
    territory3Id: '201001009005',
    territory3Name: 'ZARUMA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|ATACAMES', //d
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Id: '201001010003',
    territory3Name: 'ATACAMES',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|ESMERALDAS', //d
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Id: '201001010001',
    territory3Name: 'ESMERALDAS',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|LA_UNION|:|LA_UNION',
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'LA UNION',
    territory3Id: '201001010011',
    territory3Name: 'LA UNION',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|QUININDE', //d
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'QUININDE',
    territory3Id: '201001010008',
    territory3Name: 'QUININDE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|TACHINA|:|TACHINA',
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'TACHINA',
    territory3Id: '201001010016',
    territory3Name: 'TACHINA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|TONSUPA|:|TONSUPA',
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'TONSUPA',
    territory3Id: '201001010004',
    territory3Name: 'TONSUPA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|VICHE|:|VICHE',
    territory1Id: '201001010',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'VICHE',
    territory3Id: '201001010013',
    territory3Name: 'VICHE',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|PUERTO_BAQUERIZO_MORENO|:|PUERTO_BAQUERIZO_MORENO',
    territory1Id: '201001020',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'PUERTO BAQUERIZO MORENO',
    territory3Id: '201001020001',
    territory3Name: 'PUERTO BAQUERIZO MORENO',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SAN_CRISTOBAL|:|SAN_CRISTOBAL',
    territory1Id: '201001020',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'SAN CRISTOBAL',
    territory3Id: '201001020003',
    territory3Name: 'SAN CRISTOBAL',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SANTA_CRUZ|:|SANTA_CRUZ',
    territory1Id: '201001020',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'SANTA CRUZ',
    territory3Id: '201001020002',
    territory3Name: 'SANTA CRUZ',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALAO|:|BALAO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'BALAO',
    territory3Id: '201001002014',
    territory3Name: 'BALAO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALZAR|:|BALZAR',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'BALZAR',
    territory3Id: '201001002016',
    territory3Name: 'BALZAR',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BASE_TAURA|:|BASE_TAURA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'BASE TAURA',
    territory3Id: '201001002048',
    territory3Name: 'BASE TAURA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BOCA_DE_CAÑA|:|BOCA_DE_CAÑA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'BOCA DE CAÑA',
    territory3Id: '201001002065',
    territory3Name: 'BOCA DE CAÑA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BOLICHE|:|BOLICHE',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'BOLICHE',
    territory3Id: '201001002067',
    territory3Name: 'BOLICHE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BUCAY|:|BUCAY',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'BUCAY',
    territory3Id: '201001002037',
    territory3Name: 'BUCAY',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CERECITA', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'CERECITA',
    territory3Id: '201001002027',
    territory3Name: 'CERECITA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CHIVERIA|:|CHIVERIA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'CHIVERIA',
    territory3Id: '201001002054',
    territory3Name: 'CHIVERIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CHONGON', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'CHONGON',
    territory3Id: '201001002032',
    territory3Name: 'CHONGON',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|COLIMES|:|COLIMES',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'COLIMES',
    territory3Id: '201001002029',
    territory3Name: 'COLIMES',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|COLORADAL|:|COLORADAL',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'COLORADAL',
    territory3Id: '201001002055',
    territory3Name: 'COLORADAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CUMANDA_MILAGRO|:|CUMANDA_MILAGRO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'CUMANDA MILAGRO',
    territory3Id: '201001002078',
    territory3Name: 'CUMANDA MILAGRO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CUMANDA|:|CUMANDA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'CUMANDA',
    territory3Id: '201001010010',
    territory3Name: 'CUMANDA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DATA_DE_PLAYAS|:|DATA_DE_PLAYAS',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'DATA DE PLAYAS',
    territory3Id: '201001002077',
    territory3Name: 'DATA DE PLAYAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|DAULE', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Id: '201001002009',
    territory3Name: 'DAULE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|DURAN_(GY)', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'DURAN',
    territory3Id: '201001002002',
    territory3Name: 'DURAN',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_DESEO|:|EL_DESEO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL DESEO',
    territory3Id: '201001002049',
    territory3Name: 'EL DESEO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_TRIUNFO|:|EL_TRIUNFO', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL TRIUNFO',
    territory3Id: '201001002007',
    territory3Name: 'EL TRIUNFO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ELOY_ALFARO_DURÁN|:|ELOY_ALFARO_DURÁN',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'ELOY ALFARO DURÁN',
    territory3Id: '201001002076',
    territory3Name: 'ELOY ALFARO DURÁN',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GENERAL_VERNAZA|:|GENERAL_VERNAZA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'GENERAL VERNAZA',
    territory3Id: '201001002056',
    territory3Name: 'GENERAL VERNAZA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GENERAL_VILLAMIL_(PLAYAS)|:|GENERAL_VILLAMIL_(PLAYAS)',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'GENERAL VILLAMIL (PLAYAS)',
    territory3Id: '201001002075',
    territory3Name: 'GENERAL VILLAMIL (PLAYAS)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GUAYAQUIL', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '201001002001',
    territory3Name: 'GUAYAQUIL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|INGENIO_SAN_CARLOS|:|INGENIO_SAN_CARLOS',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'INGENIO SAN CARLOS',
    territory3Id: '201001002074',
    territory3Name: 'INGENIO SAN CARLOS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ISIDRO_AYORA|:|ISIDRO_AYORA_(GY)', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'ISIDRO AYORA',
    territory3Id: '201001002025',
    territory3Name: 'ISIDRO AYORA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|JUJAN|:|JUJAN',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'JUJAN',
    territory3Id: '201001002018',
    territory3Name: 'JUJAN',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LA_MARAVILLA|:|LA_MARAVILLA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LA MARAVILLA',
    territory3Id: '201001002057',
    territory3Name: 'LA MARAVILLA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LA_PUNTILLA|:|LA_PUNTILLA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LA PUNTILLA',
    territory3Id: '201001002072',
    territory3Name: 'LA PUNTILLA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LAS_ANIMAS|:|LAS_ANIMAS',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LAS ANIMAS',
    territory3Id: '201001002058',
    territory3Name: 'LAS ANIMAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LAUREL|:|LAUREL',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LAUREL',
    territory3Id: '201001002030',
    territory3Name: 'LAUREL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LIMONAL|:|LIMONAL',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LIMONAL',
    territory3Id: '201001002071',
    territory3Name: 'LIMONAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LOMAS_DE_SARGENTILLO|:|LOMAS_DE_SARGENTILLO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LOMAS DE SARGENTILLO',
    territory3Id: '201001002024',
    territory3Name: 'LOMAS DE SARGENTILLO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LORENZO_DE_GARAICOA|:|LORENZO_DE_GARAICOA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LORENZO DE GARAICOA',
    territory3Id: '201001002050',
    territory3Name: 'LORENZO DE GARAICOA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LOS_TINTOS|:|LOS_TINTOS',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'LOS TINTOS',
    territory3Id: '201001002059',
    territory3Name: 'LOS TINTOS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MANUEL_J._CALLE|:|MANUEL_J._CALLE',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'MANUEL J. CALLE',
    territory3Id: '201001002053',
    territory3Name: 'MANUEL J. CALLE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MARCELINO_MARIDUEÑA|:|MARCELINO_MARIDUEÑA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'MARCELINO MARIDUEÑA',
    territory3Id: '201001001030',
    territory3Name: 'MARCELINO MARIDUEÑA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SUCRE_(GY)', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'MARISCAL SUCRE',
    territory3Id: '201001002051',
    territory3Name: 'MARISCAL SUCRE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MATILDE_ESTHER|:|MATILDE_ESTHER',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'MATILDE ESTHER',
    territory3Id: '201001002064',
    territory3Name: 'MATILDE ESTHER',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|MILAGRO', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Id: '201001002003',
    territory3Name: 'MILAGRO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|NARANJAL',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Id: '201001002015',
    territory3Name: 'NARANJAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJITO|:|NARANJITO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJITO',
    territory3Id: '201001002033',
    territory3Name: 'NARANJITO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NOBOL|:|NOBOL',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'NOBOL',
    territory3Id: '201001002021',
    territory3Name: 'NOBOL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PALESTINA|:|PALESTINA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PALESTINA',
    territory3Id: '201001002022',
    territory3Name: 'PALESTINA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|PASCUALES_(HASTA_KM_16)', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PASCUALES',
    territory3Id: '201001002020',
    territory3Name: 'PASCUALES',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PEDRO_CARBO|:|PEDRO_CARBO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PEDRO CARBO',
    territory3Id: '201001002040',
    territory3Name: 'PEDRO CARBO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PETRILLO|:|PETRILLO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PETRILLO',
    territory3Id: '201001002060',
    territory3Name: 'PETRILLO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|PLAYAS', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PLAYAS',
    territory3Id: '201001002005',
    territory3Name: 'PLAYAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|POSORJA', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'POSORJA',
    territory3Id: '201001002035',
    territory3Name: 'POSORJA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PROGRESO|:|PROGRESO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PROGRESO',
    territory3Id: '201001002042',
    territory3Name: 'PROGRESO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PUENTE_LUCIA|:|PUENTE_LUCIA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PUENTE LUCIA',
    territory3Id: '201001002061',
    territory3Name: 'PUENTE LUCIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PUERTO_INCA|:|PUERTO_INCA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'PUERTO INCA',
    territory3Id: '201001002028',
    territory3Name: 'PUERTO INCA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ROBERTO_ASTUDILLO|:|ROBERTO_ASTUDILLO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'ROBERTO ASTUDILLO',
    territory3Id: '201001002052',
    territory3Name: 'ROBERTO ASTUDILLO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SABANILLA|:|SABANILLA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SABANILLA',
    territory3Id: '201001002062',
    territory3Name: 'SABANILLA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE|:|SALITRE',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SALITRE',
    territory3Id: '201001002031',
    territory3Name: 'SALITRE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON_(PUEBLO)|:|SAMBORONDON_(PUEBLO)',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAMBORONDON (PUEBLO)',
    territory3Id: '201001002066',
    territory3Name: 'SAMBORONDON (PUEBLO)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|SAMBORONDON', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAMBORONDON',
    territory3Id: '201001002019',
    territory3Name: 'SAMBORONDON',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_ANTONIO_(PLAYAS)|:|SAN_ANTONIO_(PLAYAS)',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN ANTONIO (PLAYAS)',
    territory3Id: '201001002070',
    territory3Name: 'SAN ANTONIO (PLAYAS)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO|:|SAN_JACINTO',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN JACINTO',
    territory3Id: '201001014025',
    territory3Name: 'SAN JACINTO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SANTA_LUCIA|:|SANTA_LUCIA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SANTA LUCIA',
    territory3Id: '201001002023',
    territory3Name: 'SANTA LUCIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SIMON_BOLIVAR|:|SIMON_BOLIVAR',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'SIMON BOLIVAR',
    territory3Id: '201001002038',
    territory3Name: 'SIMON BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|TARIFA|:|TARIFA',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'TARIFA',
    territory3Id: '201001002063',
    territory3Name: 'TARIFA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|TENGUEL|:|TENGUEL',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'TENGUEL',
    territory3Id: '201001002017',
    territory3Name: 'TENGUEL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|TRES_POSTES|:|TRES_POSTES',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'TRES POSTES',
    territory3Id: '201001013020',
    territory3Name: 'TRES POSTES',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|EL_EMPALME', //d
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'VELASCO IBARRA (EL EMPALME)',
    territory3Id: '201001002069',
    territory3Name: 'VELASCO IBARRA (EL EMPALME)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|YAGUACHI|:|YAGUACHI',
    territory1Id: '201001002',
    territory1Name: 'GUAYAS',
    territory2Name: 'YAGUACHI',
    territory3Id: '201001002006',
    territory3Name: 'YAGUACHI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANDRADE_MARIN|:|ANDRADE_MARIN',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANDRADE MARIN',
    territory3Id: '201001011021',
    territory3Name: 'ANDRADE MARIN',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|ANTONIO_ANTE',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '201001011022',
    territory3Name: 'ANTONIO ANTE',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ATUNTAQUI|:|ATUNTAQUI',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'ATUNTAQUI',
    territory3Id: '201001011001',
    territory3Name: 'ATUNTAQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|CARANQUI|:|CARANQUI',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'CARANQUI',
    territory3Id: '201001011018',
    territory3Name: 'CARANQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|CHALTURA|:|CHALTURA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'CHALTURA',
    territory3Id: '201001011023',
    territory3Name: 'CHALTURA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|CHORLAVI|:|CHORLAVI',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'CHORLAVI',
    territory3Id: '201001011020',
    territory3Name: 'CHORLAVI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|COTACACHI', //d
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'COTACACHI',
    territory3Id: '201001011006',
    territory3Name: 'COTACACHI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|GONZALEZ_SUAREZ|:|GONZALEZ_SUAREZ',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'GONZALEZ SUAREZ',
    territory3Id: '201001011012',
    territory3Name: 'GONZALEZ SUAREZ',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_MIGUEL_DE_IBARRA', //d
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Id: '201001011005',
    territory3Name: 'IBARRA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|NATABUELA|:|NATABUELA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'NATABUELA',
    territory3Id: '201001011025',
    territory3Name: 'NATABUELA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|OTAVALO', //d
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Id: '201001011002',
    territory3Name: 'OTAVALO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|PEGUCHE|:|PEGUCHE',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'PEGUCHE',
    territory3Id: '201001011013',
    territory3Name: 'PEGUCHE',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|PERUGACHI|:|PERUGACHI',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'PERUGACHI',
    territory3Id: '201001011030',
    territory3Name: 'PERUGACHI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|PIMAMPIRO|:|PIMAMPIRO',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'PIMAMPIRO',
    territory3Id: '201001011003',
    territory3Name: 'PIMAMPIRO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|QUIROGA_OTAVALO|:|QUIROGA_OTAVALO',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'QUIROGA OTAVALO',
    territory3Id: '201001011032',
    territory3Name: 'QUIROGA OTAVALO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|QUIROGA|:|QUIROGA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'QUIROGA',
    territory3Id: '201001011014',
    territory3Name: 'QUIROGA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_ANTONIO_IBARRA|:|SAN_ANTONIO_IBARRA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN ANTONIO IBARRA',
    territory3Id: '201001001027',
    territory3Name: 'SAN ANTONIO IBARRA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_JOSE|:|SAN_JOSE',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN JOSE',
    territory3Id: '201001011026',
    territory3Name: 'SAN JOSE',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_LUIS|:|SAN_LUIS',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN LUIS',
    territory3Id: '201001011027',
    territory3Name: 'SAN LUIS',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_IBARRA|:|SAN_MIGUEL_DE_IBARRA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN MIGUEL DE IBARRA',
    territory3Id: '201001011031',
    territory3Name: 'SAN MIGUEL DE IBARRA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_PABLO_IMBABURA|:|SAN_PABLO_IMBABURA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN PABLO IMBABURA',
    territory3Id: '201001011010',
    territory3Name: 'SAN PABLO IMBABURA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_ROQUE|:|SAN_ROQUE',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN ROQUE',
    territory3Id: '201001011011',
    territory3Name: 'SAN ROQUE',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SANTA_BERTHA|:|SANTA_BERTHA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'SANTA BERTHA',
    territory3Id: '201001011028',
    territory3Name: 'SANTA BERTHA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|TIERRA_BLANCA|:|TIERRA_BLANCA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'TIERRA BLANCA',
    territory3Id: '201001011029',
    territory3Name: 'TIERRA BLANCA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|URCUQUI|:|URCUQUI',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'URCUQUI',
    territory3Id: '201001011004',
    territory3Name: 'URCUQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|YAGUARCOCHA|:|YAGUARCOCHA',
    territory1Id: '201001011',
    territory1Name: 'IMBABURA',
    territory2Name: 'YAGUARCOCHA',
    territory3Id: '201001011019',
    territory3Name: 'YAGUARCOCHA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CALVAS|:|CALVAS',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'CALVAS',
    territory3Id: '201001012017',
    territory3Name: 'CALVAS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CALVAS|:|CARIAMANGA', //d
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'CARIAMANGA',
    territory3Id: '201001012004',
    territory3Name: 'CARIAMANGA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CATACOCHA|:|CATACOCHA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'CATACOCHA',
    territory3Id: '201001012005',
    territory3Name: 'CATACOCHA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CATAMAYO|:|CATAMAYO',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'CATAMAYO',
    territory3Id: '201001012002',
    territory3Name: 'CATAMAYO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|CELICA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Id: '201001012011',
    territory3Name: 'CELICA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|GONZANAMA|:|GONZANAMA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'GONZANAMA',
    territory3Id: '201001012010',
    territory3Name: 'GONZANAMA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|LOJA', //d
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Id: '201001012001',
    territory3Name: 'LOJA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|MACARA|:|MACARA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'MACARA',
    territory3Id: '201001019003',
    territory3Name: 'MACARA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|MALACATOS|:|MALACATOS',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'MALACATOS',
    territory3Id: '201001012006',
    territory3Name: 'MALACATOS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|PALTAS',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'PALTAS',
    territory3Id: '201001012016',
    territory3Name: 'PALTAS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SAN_LUCAS|:|SAN_LUCAS',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'SAN LUCAS',
    territory3Id: '201001012015',
    territory3Name: 'SAN LUCAS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SAN_PEDRO_DE_LA_BENDITA|:|SAN_PEDRO_DE_LA_BENDITA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'SAN PEDRO DE LA BENDITA',
    territory3Id: '201001012014',
    territory3Name: 'SAN PEDRO DE LA BENDITA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SAN_PEDRO_DE_VILCABAMBA|:|SAN_PEDRO_DE_VILCABAMBA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'SAN PEDRO DE VILCABAMBA',
    territory3Id: '201001012013',
    territory3Name: 'SAN PEDRO DE VILCABAMBA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SARAGURO|:|SARAGURO',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'SARAGURO',
    territory3Id: '201001012009',
    territory3Name: 'SARAGURO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|VILCABAMBA|:|VILCABAMBA',
    territory1Id: '201001012',
    territory1Name: 'LOJA',
    territory2Name: 'VILCABAMBA',
    territory3Id: '201001012007',
    territory3Name: 'VILCABAMBA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|BABA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABA',
    territory3Id: '201001013013',
    territory3Name: 'BABA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BABAHOYO', //d
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Id: '201001013001',
    territory3Name: 'BABAHOYO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|BUENA_FE',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BUENA FE',
    territory3Id: '201001013005',
    territory3Name: 'BUENA FE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|CALUMA|:|CALUMA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'CALUMA',
    territory3Id: '201001004007',
    territory3Name: 'CALUMA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|CATARAMA|:|CATARAMA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'CATARAMA',
    territory3Id: '201001012003',
    territory3Name: 'CATARAMA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|ECHEANDIA|:|ECHEANDIA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'ECHEANDIA',
    territory3Id: '201001004002',
    territory3Name: 'ECHEANDIA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|EL_EMPALME|:|EL_EMPALME',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'EL EMPALME',
    territory3Id: '201001002011',
    territory3Name: 'EL EMPALME',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|GUARE|:|GUARE',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'GUARE',
    territory3Id: '201001013027',
    territory3Name: 'GUARE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|ISLA_BEJUCAL|:|ISLA_BEJUCAL',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'ISLA BEJUCAL',
    territory3Id: '201001011009',
    territory3Name: 'ISLA BEJUCAL',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|ISLA_DE_BEJUCAL|:|ISLA_DE_BEJUCAL',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'ISLA DE BEJUCAL',
    territory3Id: '201001013021',
    territory3Name: 'ISLA DE BEJUCAL',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|LA_ESPERANZA|:|LA_ESPERANZA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'LA ESPERANZA',
    territory3Id: '201001013026',
    territory3Name: 'LA ESPERANZA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|LA_JULIA|:|LA_JULIA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'LA JULIA',
    territory3Id: '201001013025',
    territory3Name: 'LA JULIA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|LA_MANA|:|LA_MANA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'LA MANA',
    territory3Id: '201001007005',
    territory3Name: 'LA MANA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|LA_UNIÓN_(VALENCIA)|:|LA_UNIÓN_(VALENCIA)',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'LA UNIÓN (VALENCIA)',
    territory3Id: '201001013024',
    territory3Name: 'LA UNIÓN (VALENCIA)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MOCACHE|:|MOCACHE',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'MOCACHE',
    territory3Id: '201001013007',
    territory3Name: 'MOCACHE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MONTALVO|:|MONTALVO',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'MONTALVO',
    territory3Id: '201001013009',
    territory3Name: 'MONTALVO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PALENQUE|:|PALENQUE',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PALENQUE',
    territory3Id: '201001002036',
    territory3Name: 'PALENQUE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PATRICIA_PILAR|:|PATRICIA_PILAR',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PATRICIA PILAR',
    territory3Id: '201001013010',
    territory3Name: 'PATRICIA PILAR',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLO_VIEJO|:|PUEBLO_VIEJO',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PUEBLO VIEJO',
    territory3Id: '201001013012',
    territory3Name: 'PUEBLO VIEJO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|QUEVEDO', //d
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Id: '201001013002',
    territory3Name: 'QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUINSALOMA|:|QUINSALOMA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUINSALOMA',
    territory3Id: '201001013019',
    territory3Name: 'QUINSALOMA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|RICAURTE_LOS_RIOS|:|RICAURTE_LOS_RIOS',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'RICAURTE LOS RIOS',
    territory3Id: '201001013016',
    territory3Name: 'RICAURTE LOS RIOS',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_CAMILO|:|SAN_CAMILO',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'SAN CAMILO',
    territory3Id: '201001013014',
    territory3Name: 'SAN CAMILO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_CARLOS|:|SAN_CARLOS',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'SAN CARLOS',
    territory3Id: '201001013015',
    territory3Name: 'SAN CARLOS',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_JACINTO_DE_BUENA_FE|:|SAN_JACINTO_DE_BUENA_FE',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'SAN JACINTO DE BUENA FE',
    territory3Id: '201001013023',
    territory3Name: 'SAN JACINTO DE BUENA FE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_JUAN_BABAHOYO|:|SAN_JUAN_BABAHOYO',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'SAN JUAN BABAHOYO',
    territory3Id: '201001013022',
    territory3Name: 'SAN JUAN BABAHOYO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_JUAN|:|SAN_JUAN',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'SAN JUAN',
    territory3Id: '201001013008',
    territory3Name: 'SAN JUAN',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|VALENCIA',
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VALENCIA',
    territory3Id: '201001013004',
    territory3Name: 'VALENCIA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|VENTANAS', //d
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VENTANAS',
    territory3Id: '201001013003',
    territory3Name: 'VENTANAS',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VINCES|:|VINCES', //d
    territory1Id: '201001013',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VINCES',
    territory3Id: '201001013006',
    territory3Name: 'VINCES',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|24_DE_MAYO|:|24_DE_MAYO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: '24 DE MAYO',
    territory3Id: '201001001032',
    territory3Name: '24 DE MAYO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ALAHUELA|:|ALAHUELA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'ALAHUELA',
    territory3Id: '201001014036',
    territory3Name: 'ALAHUELA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|BAHIA_DE_CARAQUEZ', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ',
    territory3Id: '201001014003',
    territory3Name: 'BAHIA DE CARAQUEZ',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CALCETA_(BOLIVAR)|:|CALCETA', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'CALCETA',
    territory3Id: '201001014016',
    territory3Name: 'CALCETA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CALDERON_(MA)',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'CALDERON DE PORTOVIEJO',
    territory3Id: '201001014031',
    territory3Name: 'CALDERON DE PORTOVIEJO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CANUTO|:|CANUTO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'CANUTO',
    territory3Id: '201001014032',
    territory3Name: 'CANUTO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHARAPOTO|:|CHARAPOTO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'CHARAPOTO',
    territory3Id: '201001014015',
    territory3Name: 'CHARAPOTO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|CHONE', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Id: '201001014004',
    territory3Name: 'CHONE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|COLON|:|COLON',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'COLON',
    territory3Id: '201001014011',
    territory3Name: 'COLON',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|COLORADO|:|COLORADO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'COLORADO',
    territory3Id: '201001014044',
    territory3Name: 'COLORADO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CRUCITA|:|CRUCITA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'CRUCITA',
    territory3Id: '201001014010',
    territory3Name: 'CRUCITA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|EL_CARMEN|:|EL_CARMEN', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'EL CARMEN',
    territory3Id: '201001014005',
    territory3Name: 'EL CARMEN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ESTANCILLA|:|ESTANCILLA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'ESTANCILLA',
    territory3Id: '201001014033',
    territory3Name: 'ESTANCILLA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|FLAVIO_ALFARO|:|FLAVIO_ALFARO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'FLAVIO ALFARO',
    territory3Id: '201001014021',
    territory3Name: 'FLAVIO ALFARO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|JAMA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'JAMA',
    territory3Id: '201001014024',
    territory3Name: 'JAMA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JARAMIJO|:|JARAMIJO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'JARAMIJO',
    territory3Id: '201001014013',
    territory3Name: 'JARAMIJO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|JIPIJAPA', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Id: '201001014006',
    territory3Name: 'JIPIJAPA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JUNIN|:|JUNIN',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'JUNIN',
    territory3Id: '201001014009',
    territory3Name: 'JUNIN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|LA_DELICIA_KM._29|:|LA_DELICIA_KM._29',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'LA DELICIA KM. 29',
    territory3Id: '201001014035',
    territory3Name: 'LA DELICIA KM. 29',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|LEONIDAS_PLAZA|:|LEONIDAS_PLAZA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'LEONIDAS PLAZA',
    territory3Id: '201001014027',
    territory3Name: 'LEONIDAS PLAZA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|MANTA', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'MANTA',
    territory3Id: '201001014001',
    territory3Name: 'MANTA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|MONTECRISTI', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Id: '201001014020',
    territory3Name: 'MONTECRISTI',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|NUEVO_ISRAEL|:|NUEVO_ISRAEL',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'NUEVO ISRAEL',
    territory3Id: '201001017009',
    territory3Name: 'NUEVO ISRAEL',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|OLMEDO|:|OLMEDO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'OLMEDO',
    territory3Id: '201001014019',
    territory3Name: 'OLMEDO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PAJAN|:|PAJAN',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PAJAN',
    territory3Id: '201001014014',
    territory3Name: 'PAJAN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|PEDERNALES', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Id: '201001010009',
    territory3Name: 'PEDERNALES',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PICHINCHA_MANABI|:|PICHINCHA_MANABI',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PICHINCHA MANABI',
    territory3Id: '201001014007',
    territory3Name: 'PICHINCHA MANABI',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PICOAZA_(DENTRO_DEL_PERIMETRO_URBANO)|:|PICOAZA_(DENTRO_DEL_PERIMETRO_URBANO)',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PICOAZA (DENTRO DEL PERIMETRO URBANO)',
    territory3Id: '201001014037',
    territory3Name: 'PICOAZA (DENTRO DEL PERIMETRO URBANO)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PORTOVIEJO', //d
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '201001014002',
    territory3Name: 'PORTOVIEJO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PUERTO_CAYO|:|PUERTO_CAYO',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PUERTO CAYO',
    territory3Id: '201001014029',
    territory3Name: 'PUERTO CAYO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PUERTO_LOPEZ|:|PUERTO_LOPEZ',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'PUERTO LOPEZ',
    territory3Id: '201001014022',
    territory3Name: 'PUERTO LOPEZ',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|QUIROGA_CHONE|:|QUIROGA_CHONE',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'QUIROGA CHONE',
    territory3Id: '201001014043',
    territory3Name: 'QUIROGA CHONE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|RICAURTE_MANABI|:|RICAURTE_MANABI',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'RICAURTE MANABI',
    territory3Id: '201001014040',
    territory3Name: 'RICAURTE MANABI',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|RIO_CHICO_(DENTRO_DEL_PERIMETRO_URBANO)|:|RIO_CHICO_(DENTRO_DEL_PERIMETRO_URBANO)',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'RIO CHICO (DENTRO DEL PERIMETRO URBANO)',
    territory3Id: '201001014039',
    territory3Name: 'RIO CHICO (DENTRO DEL PERIMETRO URBANO)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|ROCAFUERTE',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'ROCAFUERTE',
    territory3Id: '201001014012',
    territory3Name: 'ROCAFUERTE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_ANTONIO_DE_CHONE|:|SAN_ANTONIO_DE_CHONE',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'SAN ANTONIO DE CHONE',
    territory3Id: '201001014034',
    territory3Name: 'SAN ANTONIO DE CHONE',
  },
  {
    territoryBaseId:
      'EC|:|MANABI|:|SAN_PLACIDO_(DENTRO_DEL_PERIMETRO_URBANO)|:|SAN_PLACIDO_(DENTRO_DEL_PERIMETRO_URBANO)',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'SAN PLACIDO (DENTRO DEL PERIMETRO URBANO)',
    territory3Id: '201001014038',
    territory3Name: 'SAN PLACIDO (DENTRO DEL PERIMETRO URBANO)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|SAN_VICENTE',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'SAN VICENTE',
    territory3Id: '201001014018',
    territory3Name: 'SAN VICENTE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SANTA_ANA|:|SANTA_ANA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'SANTA ANA',
    territory3Id: '201001014008',
    territory3Name: 'SANTA ANA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SUCRE|:|SUCRE',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'SUCRE',
    territory3Id: '201001014042',
    territory3Name: 'SUCRE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TARQUI_MANABI|:|TARQUI_MANABI',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'TARQUI MANABI',
    territory3Id: '201001014041',
    territory3Name: 'TARQUI MANABI',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|TOSAGUA',
    territory1Id: '201001014',
    territory1Name: 'MANABI',
    territory2Name: 'TOSAGUA',
    territory3Id: '201001014017',
    territory3Name: 'TOSAGUA',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|GUALAQUIZA|:|GUALAQUIZA', //d
    territory1Id: '201001015',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'GUALAQUIZA',
    territory3Id: '201001015002',
    territory3Name: 'GUALAQUIZA',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|MACAS', //d
    territory1Id: '201001015',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'MACAS',
    territory3Id: '201001015001',
    territory3Name: 'MACAS',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SUCUA|:|SUCUA', //d
    territory1Id: '201001015',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'SUCUA',
    territory3Id: '201001017007',
    territory3Name: 'SUCUA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|ARCHIDONA',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'ARCHIDONA',
    territory3Id: '201001016003',
    territory3Name: 'ARCHIDONA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|AROSEMENA_TOLA|:|AROSEMENA_TOLA',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'AROSEMENA TOLA',
    territory3Id: '201001016006',
    territory3Name: 'AROSEMENA TOLA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|CARLOS_JULIO_AROSEMENA_TOLA|:|CARLOS_JULIO_AROSEMENA_TOLA',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'CARLOS JULIO AROSEMENA TOLA',
    territory3Id: '201001016008',
    territory3Name: 'CARLOS JULIO AROSEMENA TOLA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|CARLOS_JULIO_AROSEMENA|:|CARLOS_JULIO_AROSEMENA',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'CARLOS JULIO AROSEMENA',
    territory3Id: '201001016009',
    territory3Name: 'CARLOS JULIO AROSEMENA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_CHACO|:|EL_CHACO',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'EL CHACO',
    territory3Id: '201001017012',
    territory3Name: 'EL CHACO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|MISAHUALLI|:|MISAHUALLI',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'MISAHUALLI',
    territory3Id: '201001016005',
    territory3Name: 'MISAHUALLI',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|PTO._NAPO|:|PTO._NAPO',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'PTO. NAPO',
    territory3Id: '201001016004',
    territory3Name: 'PTO. NAPO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|PUERTO_NAPO|:|PUERTO_NAPO',
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'PUERTO NAPO',
    territory3Id: '201001016007',
    territory3Name: 'PUERTO NAPO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|EL_TENA', //d
    territory1Id: '201001016',
    territory1Name: 'NAPO',
    territory2Name: 'TENA',
    territory3Id: '201001016001',
    territory3Name: 'TENA',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|EL_COCA', //d
    territory1Id: '201001017',
    territory1Name: 'ORELLANA',
    territory2Name: 'EL COCA',
    territory3Id: '201001017002',
    territory3Name: 'EL COCA',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|FRANCISCO_DE_ORELLANA|:|FRANCISCO_DE_ORELLANA',
    territory1Id: '201001017',
    territory1Name: 'ORELLANA',
    territory2Name: 'FRANCISCO DE ORELLANA',
    territory3Id: '201001017001',
    territory3Name: 'FRANCISCO DE ORELLANA',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LA_JOYA_DE_LOS_SACHAS|:|LA_JOYA_DE_LOS_SACHAS',
    territory1Id: '201001017',
    territory1Name: 'ORELLANA',
    territory2Name: 'LA JOYA DE LOS SACHAS',
    territory3Id: '201001017006',
    territory3Name: 'LA JOYA DE LOS SACHAS',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LORETO|:|LORETO',
    territory1Id: '201001017',
    territory1Name: 'ORELLANA',
    territory2Name: 'LORETO',
    territory3Id: '201001017016',
    territory3Name: 'LORETO',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|EL_PUYO', //d
    territory1Id: '201001018',
    territory1Name: 'PASTAZA',
    territory2Name: 'PUYO',
    territory3Id: '201001018001',
    territory3Name: 'PUYO',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|SHELL|:|SHELL',
    territory1Id: '201001018',
    territory1Name: 'PASTAZA',
    territory2Name: 'SHELL',
    territory3Id: '201001017004',
    territory3Name: 'SHELL',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ALANGASI', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ALANGASI',
    territory3Id: '201001001031',
    territory3Name: 'ALANGASI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|ALOAG|:|ALOAG',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ALOAG',
    territory3Id: '201001001019',
    territory3Name: 'ALOAG',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|ALOASI|:|ALOASI',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ALOASI',
    territory3Id: '201001001046',
    territory3Name: 'ALOASI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|AMAGUAÑA', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'AMAGUAÑA',
    territory3Id: '201001001018',
    territory3Name: 'AMAGUAÑA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|ASCAZUBI|:|ASCAZUBI',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ASCAZUBI',
    territory3Id: '201001001062',
    territory3Name: 'ASCAZUBI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|AYORA|:|AYORA',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'AYORA',
    territory3Id: '201001001042',
    territory3Name: 'AYORA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|AZCASUBI_VIA_AL_QUINCHE|:|AZCASUBI_VIA_AL_QUINCHE',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'AZCASUBI VIA AL QUINCHE',
    territory3Id: '201001001043',
    territory3Name: 'AZCASUBI VIA AL QUINCHE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CALACALI|:|CALACALI',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CALACALI',
    territory3Id: '201001001022',
    territory3Name: 'CALACALI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CALDERON_(PI)', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CALDERON',
    territory3Id: '201001001024',
    territory3Name: 'CALDERON',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|CAYAMBE', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Id: '201001001002',
    territory3Name: 'CAYAMBE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHECA_(CHILPA)',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CHECA',
    territory3Id: '201001001063',
    territory3Name: 'CHECA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CONOCOTO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CONOCOTO',
    territory3Id: '201001001033',
    territory3Name: 'CONOCOTO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CUMBAYA', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CUMBAYA',
    territory3Id: '201001001009',
    territory3Name: 'CUMBAYA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CUZUBAMBA|:|CUZUBAMBA',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CUZUBAMBA',
    territory3Id: '201001001061',
    territory3Name: 'CUZUBAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|EL_CHAUPI|:|EL_CHAUPI',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'EL CHAUPI',
    territory3Id: '201001001071',
    territory3Name: 'EL CHAUPI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|EL_QUINCHE', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'EL QUINCHE',
    territory3Id: '201001001007',
    territory3Name: 'EL QUINCHE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|GUANGOPOLO|:|GUANGOPOLO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'GUANGOPOLO',
    territory3Id: '201001001070',
    territory3Name: 'GUANGOPOLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUAYLLABAMBA', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'GUAYLLABAMBA',
    territory3Id: '201001001014',
    territory3Name: 'GUAYLLABAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|JUAN_MONTALVO|:|JUAN_MONTALVO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'JUAN MONTALVO',
    territory3Id: '201001001045',
    territory3Name: 'JUAN MONTALVO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ARMENIA', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'LA ARMENIA',
    territory3Id: '201001001047',
    territory3Name: 'LA ARMENIA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LLANO_CHICO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'LLANO CHICO',
    territory3Id: '201001001048',
    territory3Name: 'LLANO CHICO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LLANO_GRANDE', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'LLANO GRANDE',
    territory3Id: '201001001049',
    territory3Name: 'LLANO GRANDE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|MACHACHI', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MACHACHI',
    territory3Id: '201001001008',
    territory3Name: 'MACHACHI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJÍA|:|MEJÍA',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJÍA',
    territory3Id: '201001001069',
    territory3Name: 'MEJÍA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MIRAVALLE', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MIRAVALLE',
    territory3Id: '201001001051',
    territory3Name: 'MIRAVALLE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MITAD_DEL_MUNDO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MITAD DEL MUNDO',
    territory3Id: '201001001021',
    territory3Name: 'MITAD DEL MUNDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MONJAS', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MONJAS',
    territory3Id: '201001002043',
    territory3Name: 'MONJAS',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|NANEGALITO|:|NANEGALITO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'NANEGALITO',
    territory3Id: '201001001029',
    territory3Name: 'NANEGALITO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|NONO|:|NONO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'NONO',
    territory3Id: '201001001068',
    territory3Name: 'NONO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|ORQUIDEAS|:|ORQUIDEAS',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ORQUIDEAS',
    territory3Id: '201001001052',
    territory3Name: 'ORQUIDEAS',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|OTÓN|:|OTÓN',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'OTÓN',
    territory3Id: '201001001067',
    territory3Name: 'OTÓN',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_MONCAYO|:|PEDRO_MONCAYO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PEDRO MONCAYO',
    territory3Id: '201001001035',
    territory3Name: 'PEDRO MONCAYO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_V._MALDONADO|:|PEDRO_V._MALDONADO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PEDRO V. MALDONADO',
    territory3Id: '201001001012',
    territory3Name: 'PEDRO V. MALDONADO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PIFO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PIFO',
    territory3Id: '201001001025',
    territory3Name: 'PIFO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PINTAG', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PINTAG',
    territory3Id: '201001001036',
    territory3Name: 'PINTAG',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|POMASQUI', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'POMASQUI',
    territory3Id: '201001001028',
    territory3Name: 'POMASQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUEMBO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PUEMBO',
    territory3Id: '201001001026',
    territory3Name: 'PUEMBO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PUERTO_QUITO|:|PUERTO_QUITO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PUERTO QUITO',
    territory3Id: '201001001013',
    territory3Name: 'PUERTO QUITO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUSUQUI', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PUSUQUI',
    territory3Id: '201001001041',
    territory3Name: 'PUSUQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Id: '201001001001',
    territory3Name: 'QUITO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMIÑAHUI|:|RUMIÑAHUI',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMIÑAHUI',
    territory3Id: '201001001066',
    territory3Name: 'RUMIÑAHUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMIPAMBA|:|RUMIPAMBA',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMIPAMBA',
    territory3Id: '201001001065',
    territory3Name: 'RUMIPAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_ANTONIO_(PI)', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SAN ANTONIO DE PICHINCHA',
    territory3Id: '201001001053',
    territory3Name: 'SAN ANTONIO DE PICHINCHA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|SAN_JOSE_DE_MORAN|:|SAN_JOSE_DE_MORAN',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SAN JOSE DE MORAN',
    territory3Id: '201001001054',
    territory3Name: 'SAN JOSE DE MORAN',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|SAN_MIGUEL_DE_LOS_BANCOS|:|SAN_MIGUEL_DE_LOS_BANCOS',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SAN MIGUEL DE LOS BANCOS',
    territory3Id: '201001001010',
    territory3Name: 'SAN MIGUEL DE LOS BANCOS',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SAN_RAFAEL_(PI)', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SAN RAFAEL',
    territory3Id: '201001001006',
    territory3Name: 'SAN RAFAEL',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SANGOLQUI', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SANGOLQUI',
    territory3Id: '201001001005',
    territory3Name: 'SANGOLQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TABABELA', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'TABABELA',
    territory3Id: '201001001056',
    territory3Name: 'TABABELA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_MONCAYO_(TABACUNDO)|:|TABACUNDO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'TABACUNDO',
    territory3Id: '201001001003',
    territory3Name: 'TABACUNDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|TAMBILLO|:|TAMBILLO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'TAMBILLO',
    territory3Id: '201001021003',
    territory3Name: 'TAMBILLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TUMBACO', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'TUMBACO',
    territory3Id: '201001001020',
    territory3Name: 'TUMBACO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|UYUMBICHO|:|UYUMBICHO',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'UYUMBICHO',
    territory3Id: '201001001057',
    territory3Name: 'UYUMBICHO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|VALLE_DE_LOS_CHILLOS|:|VALLE_DE_LOS_CHILLOS',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'VALLE DE LOS CHILLOS',
    territory3Id: '201001001039',
    territory3Name: 'VALLE DE LOS CHILLOS',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|YARUQUI', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'YARUQUI',
    territory3Id: '201001001037',
    territory3Name: 'YARUQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|ZAMBISA|:|ZAMBISA',
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ZAMBISA',
    territory3Id: '201001001058',
    territory3Name: 'ZAMBISA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ZAMBIZA', //d
    territory1Id: '201001001',
    territory1Name: 'PICHINCHA',
    territory2Name: 'ZAMBIZA',
    territory3Id: '201001001064',
    territory3Name: 'ZAMBIZA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|ANCON|:|ANCON',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'ANCON',
    territory3Id: '201001002034',
    territory3Name: 'ANCON',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|ANCONCITO|:|ANCONCITO',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'ANCONCITO',
    territory3Id: '201001002046',
    territory3Name: 'ANCONCITO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|AYANGUE|:|AYANGUE',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'AYANGUE',
    territory3Id: '201001023018',
    territory3Name: 'AYANGUE',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|BALLENITA|:|BALLENITA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'BALLENITA',
    territory3Id: '201001014023',
    territory3Name: 'BALLENITA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|CADEATE|:|CADEATE',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'CADEATE',
    territory3Id: '201001023008',
    territory3Name: 'CADEATE',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|CAPAES|:|CAPAES',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'CAPAES',
    territory3Id: '201001023009',
    territory3Name: 'CAPAES',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|EL_TAMBO_SANTA_ELENA|:|EL_TAMBO_SANTA_ELENA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'EL TAMBO SANTA ELENA',
    territory3Id: '201001023020',
    territory3Name: 'EL TAMBO SANTA ELENA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|LA_LIBERTAD|:|LA_LIBERTAD', //d
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'LA LIBERTAD',
    territory3Id: '201001002013',
    territory3Name: 'LA LIBERTAD',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|LIBERTADOR_BOLIVAR|:|LIBERTADOR_BOLIVAR',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'LIBERTADOR BOLIVAR',
    territory3Id: '201001023010',
    territory3Name: 'LIBERTADOR BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|MANGLARALTO|:|MANGLARALTO',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'MANGLARALTO',
    territory3Id: '201001023011',
    territory3Name: 'MANGLARALTO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|MAR_BRAVO|:|MAR_BRAVO',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'MAR BRAVO',
    territory3Id: '201001023004',
    territory3Name: 'MAR BRAVO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|MONTAÑITA|:|MONTAÑITA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'MONTAÑITA',
    territory3Id: '201001023003',
    territory3Name: 'MONTAÑITA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|MONTEVERDE|:|MONTEVERDE',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'MONTEVERDE',
    territory3Id: '201001023012',
    territory3Name: 'MONTEVERDE',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|MUEY|:|MUEY',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'MUEY',
    territory3Id: '201001023005',
    territory3Name: 'MUEY',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|OLON|:|OLON',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'OLON',
    territory3Id: '201001023006',
    territory3Name: 'OLON',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PALMAR|:|PALMAR',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PALMAR',
    territory3Id: '201001023013',
    territory3Name: 'PALMAR',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PROSPERIDAD|:|PROSPERIDAD',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PROSPERIDAD',
    territory3Id: '201001023014',
    territory3Name: 'PROSPERIDAD',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PUERTO_SANTA_ROSA|:|PUERTO_SANTA_ROSA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PUERTO SANTA ROSA',
    territory3Id: '201001023007',
    territory3Name: 'PUERTO SANTA ROSA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PUNTA_BARANDUA|:|PUNTA_BARANDUA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PUNTA BARANDUA',
    territory3Id: '201001023015',
    territory3Name: 'PUNTA BARANDUA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PUNTA_BLANCA|:|PUNTA_BLANCA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PUNTA BLANCA',
    territory3Id: '201001023002',
    territory3Name: 'PUNTA BLANCA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PUNTA_CARNERO|:|PUNTA_CARNERO',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PUNTA CARNERO',
    territory3Id: '201001023001',
    territory3Name: 'PUNTA CARNERO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|PUNTA_CENTINELA|:|PUNTA_CENTINELA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'PUNTA CENTINELA',
    territory3Id: '201001023019',
    territory3Name: 'PUNTA CENTINELA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|SALINAS_(SE)', //d
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Id: '201001002004',
    territory3Name: 'SALINAS',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SAN_PABLO_SANTA_ELENA|:|SAN_PABLO_SANTA_ELENA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SAN PABLO SANTA ELENA',
    territory3Id: '201001023017',
    territory3Name: 'SAN PABLO SANTA ELENA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SAN_PEDRO|:|SAN_PEDRO',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SAN PEDRO',
    territory3Id: '201001011007',
    territory3Name: 'SAN PEDRO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SANTA_ELENA', //d
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Id: '201001002010',
    territory3Name: 'SANTA ELENA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ROSA_SANTA_ELENA|:|SANTA_ROSA_SANTA_ELENA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ROSA SANTA ELENA',
    territory3Id: '201001023021',
    territory3Name: 'SANTA ROSA SANTA ELENA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|VALDIVIA|:|VALDIVIA',
    territory1Id: '201001023',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'VALDIVIA',
    territory3Id: '201001023016',
    territory3Name: 'VALDIVIA',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|KM_14_QUEVEDO|:|KM_14_QUEVEDO',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'KM 14 QUEVEDO',
    territory3Id: '201001024004',
    territory3Name: 'KM 14 QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|KM_24_QUEVEDO|:|KM_24_QUEVEDO',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'KM 24 QUEVEDO',
    territory3Id: '201001024005',
    territory3Name: 'KM 24 QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|KM_38.5_QUEVEDO|:|KM_38.5_QUEVEDO',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'KM 38.5 QUEVEDO',
    territory3Id: '201001024006',
    territory3Name: 'KM 38.5 QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|KM_41_QUEVEDO|:|KM_41_QUEVEDO',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'KM 41 QUEVEDO',
    territory3Id: '201001024007',
    territory3Name: 'KM 41 QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LA_CONCORDIA|:|LA_CONCORDIA',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'LA CONCORDIA',
    territory3Id: '201001001011',
    territory3Name: 'LA CONCORDIA',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LAS_VILLEGAS|:|LAS_VILLEGAS',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'LAS VILLEGAS',
    territory3Id: '201001001060',
    territory3Name: 'LAS VILLEGAS',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LUZ_DE_AMERICA|:|LUZ_DE_AMERICA',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'LUZ DE AMERICA',
    territory3Id: '201001024008',
    territory3Name: 'LUZ DE AMERICA',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|MONTERREY|:|MONTERREY',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'MONTERREY',
    territory3Id: '201001001059',
    territory3Name: 'MONTERREY',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|SANTO_DOMINGO_DE_LOS_COLORADOS', //d
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '201001001004',
    territory3Name: 'SANTO DOMINGO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|VALLE_HERMOSO|:|VALLE_HERMOSO',
    territory1Id: '201001024',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'VALLE HERMOSO',
    territory3Id: '201001001040',
    territory3Name: 'VALLE HERMOSO',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|LAGO_AGRIO',
    territory1Id: '201001022',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LAGO AGRIO',
    territory3Id: '201001017003',
    territory3Name: 'LAGO AGRIO',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|NUEVA_LOJA_(LAGO_AGRIO)', //d
    territory1Id: '201001022',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'NUEVA LOJA',
    territory3Id: '201001022001',
    territory3Name: 'NUEVA LOJA',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SHUSHUFINDI',
    territory1Id: '201001022',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'SHUSHUFINDI',
    territory3Id: '201001022002',
    territory3Name: 'SHUSHUFINDI',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AMBATO', //d
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Id: '20100101901',
    territory3Name: 'AMBATO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|BAÑOS_DE_AGUA_SANTA_(TU)', //d
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BAÑOS DE AGUA SANTA',
    territory3Id: '20100101902',
    territory3Name: 'BAÑOS DE AGUA SANTA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BETÍNEZ_(PACHANLICA)|:|BETÍNEZ_(PACHANLICA)',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BETÍNEZ (PACHANLICA)',
    territory3Id: '201001019014',
    territory3Name: 'BETÍNEZ (PACHANLICA)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|CEVALLOS|:|CEVALLOS',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'CEVALLOS',
    territory3Id: '201001019005',
    territory3Name: 'CEVALLOS',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|HUAMBALO|:|HUAMBALO',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'HUAMBALO',
    territory3Id: '201001019011',
    territory3Name: 'HUAMBALO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|MOCHA|:|MOCHA',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'MOCHA',
    territory3Id: '201001019007',
    territory3Name: 'MOCHA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|PATATE|:|PATATE',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'PATATE',
    territory3Id: '201001003008',
    territory3Name: 'PATATE',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|PELILEO_(TU)', //d
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'PELILEO',
    territory3Id: '201001019004',
    territory3Name: 'PELILEO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|PILLARO', //d
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'PILLARO',
    territory3Id: '201001019006',
    territory3Name: 'PILLARO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|QUERO|:|QUERO',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'QUERO',
    territory3Id: '201001019008',
    territory3Name: 'QUERO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|SAN_PEDRO_DE_PELILEO',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '201001019013',
    territory3Name: 'SAN PEDRO DE PELILEO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|TISALEO|:|TISALEO',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'TISALEO',
    territory3Id: '201001019009',
    territory3Name: 'TISALEO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|ULBA|:|ULBA',
    territory1Id: '201001019',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'ULBA',
    territory3Id: '201001019012',
    territory3Name: 'ULBA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|CENTINELA_DEL_CONDOR|:|CENTINELA_DEL_CONDOR',
    territory1Id: '201001021',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'CENTINELA DEL CONDOR',
    territory3Id: '201001021006',
    territory3Name: 'CENTINELA DEL CONDOR',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|PANGUI|:|PANGUI',
    territory1Id: '201001021',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'PANGUI',
    territory3Id: '201001021005',
    territory3Name: 'PANGUI',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YANTZAZA|:|YANTZAZA',
    territory1Id: '201001021',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'YANTZAZA',
    territory3Id: '201001015003',
    territory3Name: 'YANTZAZA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|ZAMORA', //d
    territory1Id: '201001021',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Id: '201001021001',
    territory3Name: 'ZAMORA',
  },
];
