import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, IOrder, IUser} from '../..';
import {Country} from '../../../utils/models';

export enum AddressType {
  sender = 'sender',
  recipient = 'recipient',
  return = 'return',
}

export enum AddressStatus {
  active = 'active',
  inactive = 'inactive',
  pendingApproval = 'pendingApproval',
}

export interface AddressGroup {
  groupId: number;
  templateAddress: IAddress;
  addresses: IAddress[];
}

export type TerritoryBaseId = string; // TODO p2 add some validations to this type instead of a generic string

export interface IAddress extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  firstName: string;

  lastName: string;

  /**
   * @deprecated Don't use it anymore in the future.
   * TODO delete it when possible (last usage aroun may 2024)
   * `coName` (aka. `c/o`) should be used to add the name of a second person who is marked in the mail box.
   */
  coName?: string;

  company?: string;

  streetLine1: string;

  /**
   * House or building number. Ex. "N45-34", "13".
   * In some cases the doorNumber will be included inside the field `streetLine1`.
   * Should be used to merge/chain/group addresses.
   */
  doorNumber: string;

  /**
   * Interior number as apartment number, ofice number, etc. Ex. "Apt. 802", "Office 13".
   * In some cases the doorNumber will be included inside the field `streetLine1`.
   * Should be used to merge/chain/group addresses.
   */
  interiorNumber?: string;

  /**
   * `reference` should be a free text, to help locate the address. Example the immediate perpendicular street closest to the streetLine1 (ex. "next to 5th Av."), or a known building nearby (ex. "1 house to the right of the ABC supermarket")
   * In some cases the doorNumber will be included inside the field `streetLine1`
   * Should NOT be used to merge/chain/group addresses
   */
  reference?: string;

  /**
   * Ecuador ONLY: Código único de Distrito (según table INEI)
   * TODO only applicable for carrier "Ecuador - Urbano"
   * Should NOT be used to merge/chain/group addresses
   */
  ubigeo?: string;

  zip?: string;

  /**
   * @deprecated Use instead `territoryBaseId`
   * * TODO p1 delete
   */
  cityName?: string;

  /**
   * @deprecated Use instead `territoryBaseId`
   * * TODO p1 delete
   * ---
   * `stateName`is country-specific equivalent. Example => EC = Province, CO = Department, CH = Canton
   */
  stateName?: string;

  /**
   * This is the main UNIQUE identifier for territories to be used among different Carrier APIs.
   * This ID serves as a base identifier of the app, in order to find an ID of a Carrier's specific territory ID
   * For example. in the app we have a base territory is 'ECUADOR|QUITO', and a (fake) carrier #1 ECUADOR_CARRIER_ABC uses the value '123' in its API to link labels in this territory (QUITO), then we must map the UNIQUE ID 'ECUADOR|QUITO' to the carrier territory list to find its ID of '123'.
   * When another (fake) carrier #2 ECUADOR_CARRIER_XYZ uses the value '987' in its API to link labels in this territory (QUITO), then we must map the UNIQUE ID 'ECUADOR|QUITO' to the carrier territory list to find its ID of '987'.
   */
  territoryBaseId?: TerritoryBaseId;

  country?: Country;

  phone?: string;

  email?: string;

  /**
   * `zone` should be country-specific equivalent. Example => EC = sector/barrio, CH = Kreis
   *  It should be a free text, and should not be used to merge/chain/group addresses
   */
  zone?: string;

  addressType: AddressType;

  isTemplate?: boolean;

  status: AddressStatus;

  lat?: number;
  lng?: number;

  /**
   * OneToOne relation to the order's addressSender
   */
  addressSender?: Relation<IOrder>;

  /**
   * OneToOne relation to the order's addressRecipient
   */
  addressRecipient?: Relation<IOrder>;

  /**
   * OneToOne relation to the order's addressReturn
   */
  addressReturn?: Relation<IOrder>;
}
