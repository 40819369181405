import {LoadChildrenCallback, Routes} from '@angular/router';
import {AuthJwtUserLoggedInGuardService, AuthJwtUserNotLoggedInGuardService} from '@basuiz/web-app-common';
import * as portal from '@basuiz/web-app-portal';
import {DefaultDashboardId, generateDashboardPortalRoute, generatePortalRoute} from '@basuiz/web-app-portal';

const notificationsRoutes: Routes = [
  generatePortalRoute(
    portal.NotificationsPage,
    () =>
      import('@basuiz/web-app-portal-pages-notifications/notifications-page').then((m) => m.NotificationsPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.NotificationsSubscriptionFormPage,
    () =>
      import('@basuiz/web-app-portal-pages-notifications/notifications-subscription-form-page').then(
        (m) => m.NotificationsSubscriptionFormPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const collaborationRoutes: Routes = [
  generatePortalRoute(
    portal.CollaborationOverviewPage,
    () =>
      import('@basuiz/web-app-portal-pages-collaboration/collaboration-overview-page').then(
        (m) => m.CollaborationOverviewPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.CollaborationIssueCreationPage,
    () =>
      import('@basuiz/web-app-portal-pages-collaboration/collaboration-issue-creation-page').then(
        (m) => m.CollaborationIssueCreationPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.CollaborationDetailPage,
    () =>
      import('@basuiz/web-app-portal-pages-collaboration/collaboration-detail-page').then(
        (m) => m.CollaborationDetailPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const investmentProposalsRoutes: Routes = [
  generatePortalRoute(
    portal.InvestmentProposalOverviewPage,
    () =>
      import('@basuiz/web-app-portal-pages-investment-proposals/investment-proposal-overview-page').then(
        (m) => m.InvestmentProposalOverviewPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.InvestmentProposalDetailPage,
    () =>
      import('@basuiz/web-app-portal-pages-investment-proposals/investment-proposal-detail-page').then(
        (m) => m.InvestmentProposalDetailPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.InvestmentProposalRequestChangesPage,
    () =>
      import('@basuiz/web-app-portal-pages-investment-proposals/investment-proposal-request-changes-page').then(
        (m) => m.InvestmentProposalRequestChangesPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const userSettingsRoutes: Routes = [
  generatePortalRoute(
    portal.UserSettingsPage,
    () => import('@basuiz/web-app-portal-pages-user-settings/user-settings-page').then((m) => m.UserSettingsPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.UserSettingsGeneralPage,
    () =>
      import('@basuiz/web-app-portal-pages-user-settings/user-settings-general-page').then(
        (m) => m.UserSettingsGeneralPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.CollectionBankAccountUpdatePage,
    () =>
      import('@basuiz/web-app-portal-pages-user-settings/collection-bank-account-update-page').then(
        (m) => m.CollectionBankAccountUpdatePageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.ContactDetailsUpdatePage,
    () =>
      import('@basuiz/web-app-portal-pages-user-settings/contact-details-update-page').then(
        (m) => m.ContactDetailsUpdatePageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
  generatePortalRoute(
    portal.PostalAddressUpdatePage,
    () =>
      import('@basuiz/web-app-portal-pages-user-settings/postal-address-update-page').then(
        (m) => m.PostalAddressUpdatePageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const selfServiceRoutes: Routes = [
  generatePortalRoute(
    portal.AddressChangePage,
    () => import('@basuiz/web-app-portal-pages/address-change-page').then((m) => m.AddressChangePageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const thirdPartyRoutes: Routes = [
  generatePortalRoute(
    portal.PaymentCardsPage,
    () => import('@basuiz/web-app-portal-pages/payment-cards-page').then((m) => m.PaymentCardsPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const paymentMethodRoutes: Routes = [
  generatePortalRoute(
    portal.PaymentMethodWalletOverviewPage,
    () =>
      import('@basuiz/web-app-portal-pages-payment-method/payment-method-wallet-overview-page').then(
        (m) => m.PaymentMethodWalletOverviewPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.PaylogEcpayphoneConfirmationPage,
    () =>
      import('@basuiz/web-app-portal-pages-payment-method/paylog-ecpayphone-confirmation-page').then(
        (m) => m.PaylogEcpayphoneConfirmationPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),
];

const shipmentRoutes: Routes = [
  generatePortalRoute(
    portal.ShipmentOverviewPage,
    () =>
      import('@basuiz/web-app-portal-pages-shipment/shipment-overview-page').then((m) => m.ShipmentOverviewPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.ShipmentEntryPage,
    () => import('@basuiz/web-app-portal-pages-shipment/shipment-entry-page').then((m) => m.ShipmentEntryPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.ShipmentAddressOverviewPage,
    () =>
      import('@basuiz/web-app-portal-pages-shipment/shipment-address-overview-page').then(
        (m) => m.ShipmentAddressOverviewPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.ShipmentAddressEntryPage,
    () =>
      import('@basuiz/web-app-portal-pages-shipment/shipment-address-entry-page').then(
        (m) => m.ShipmentAddressEntryPageModule
      ),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.ManifestOverviewPage,
    () =>
      import('@basuiz/web-app-portal-pages-shipment/manifest-overview-page').then((m) => m.ManifestOverviewPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.ManifestEntryPage,
    () => import('@basuiz/web-app-portal-pages-shipment/manifest-entry-page').then((m) => m.ManifestEntryPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  ),

  generatePortalRoute(
    portal.PriceCalculatorPage,
    () =>
      import('@basuiz/web-app-portal-pages-shipment/price-calculator-page').then((m) => m.PriceCalculatorPageModule),
    {canActivate: [AuthJwtUserLoggedInGuardService]}
    // {canActivate: [AuthJwtUserPublicGuardService]} // TODO: make it public
  ),
];

const authRoutes: Routes = [
  portal.generatePortalRoute(
    portal.AuthSigninPage,
    () => import('@basuiz/web-app-portal-pages/auth-signin-page').then((m) => m.AuthSigninPageModule),
    {canActivate: [AuthJwtUserNotLoggedInGuardService]}
  ),

  portal.generatePortalRoute(
    portal.AuthSignupPage,
    () => import('@basuiz/web-app-portal-pages/auth-signup-page').then((m) => m.AuthSignupPageModule),
    {canActivate: [AuthJwtUserNotLoggedInGuardService]}
  ),

  portal.generatePortalRoute(
    portal.AuthEmailVerifyPage,
    () => import('@basuiz/web-app-portal-pages/auth-email-verify-page').then((m) => m.AuthEmailVerifyPageModule),
    {canActivate: [AuthJwtUserNotLoggedInGuardService]}
  ),

  portal.generatePortalRoute(
    portal.AuthResetPasswordRequestPage,
    () =>
      import('@basuiz/web-app-portal-pages/auth-reset-password-request-page').then(
        (m) => m.AuthResetPasswordRequestPageModule
      ),
    {canActivate: [AuthJwtUserNotLoggedInGuardService]}
  ),

  portal.generatePortalRoute(
    portal.AuthResetPasswordValidatePage,
    () =>
      import('@basuiz/web-app-portal-pages/auth-reset-password-validate-page').then(
        (m) => m.AuthResetPasswordValidatePageModule
      ),
    {canActivate: [AuthJwtUserNotLoggedInGuardService]}
  ),
];

/** Loaders of the default dashboard pages delivered with the portal. The 'CORPORATE' dashboard is pending. */
export const dashboardLoaders: Record<DefaultDashboardId, LoadChildrenCallback> = {
  RETAIL: () => import('@basuiz/web-app-portal-pages/dashboard-retail-page').then((m) => m.DashboardRetailPageModule),
  CORPORATE: () =>
    import('@basuiz/web-app-portal-pages/dashboard-corporate-page').then((m) => m.DashboardCorporatePageModule),
};

export const bszPortalRoutes: Routes = [
  generateDashboardPortalRoute(dashboardLoaders),
  ...authRoutes,
  ...notificationsRoutes,
  ...collaborationRoutes,
  ...investmentProposalsRoutes,
  ...userSettingsRoutes,
  ...selfServiceRoutes,
  ...thirdPartyRoutes,
  ...shipmentRoutes,
  ...paymentMethodRoutes,
  // Please move new routes to one of the existing groups
];
