import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity} from '../../../core/data-access/base-models/base-user-owned.model';
import {IUser} from '../../../core/data-access/user/user.model';
import {ProductCategory} from '../../../product/data-access/product.model';
import {IShipment} from '../../../shipment/data-access/shipment.model';
import {EcDelivereoLabelStatus} from './ec-delivereo-label-status';
import {EcDelivereoCities} from './territory/ec-delivereo-territory-list';

export const EC_DELIVEREO_TARIFFS_V1 = 'v-2024-12-11';

export const EC_DELIVEREO_MAX_GOODS_COLLECTED = 300;
export const EC_DELIVEREO_MAX_GOODS_COLLECTED_REAL = 200;
export const EC_DELIVEREO_MAX_GOODS_INSURED = 500;

export const EC_DELIVEREO_MIN_WEIGHT = 0; // in kg
export const EC_DELIVEREO_MAX_WEIGHT = 10; // in kg
export const EC_DELIVEREO_MAX_DIMENSION_HEIGHT = 50; // in cm
export const EC_DELIVEREO_MAX_DIMENSION_WIDTH = 50; // in cm
export const EC_DELIVEREO_MAX_DIMENSION_LENGTH = 60; // in cm

export const EC_DELIVEREO_AVERAGE_DELIVERY_TIME_HOURS = 2; // in hours

// TODO-EE comprobar que la hora de generación y cotización sea antes de este horario y generar un error
export const EC_DELIVEREO_MAX_DELIVERY_TIME = 20; // in hours format

export enum EcDelivereoLabelApiMessage {
  Fallo = 'Fallo',
  Éxito = 'Éxito',
}

export enum EcDelivereoCancelReason {
  BETTER_OPTION = 'BETTER_OPTION',
  NOT_NEEDED_ANYMORE = 'NOT_NEEDED_ANYMORE',
  OTHER = 'OTHER',
  TOO_MUCH_DELAY = 'TOO_MUCH_DELAY',
}

export enum EcDelivereoBookingPointType {
  'PICK_UP' = 'PICK_UP',
  'DROP_OFF' = 'DROP_OFF',
}

export enum EcDelivereoPaymentMode {
  'AGREEMENT' = 'AGREEMENT', // Not used
  'CASH' = 'CASH', // use in COD
  'CHECK' = 'CHECK', // Not used
  'CREDIT_CARD' = 'CREDIT_CARD', // Not used
  'MIXED' = 'MIXED', // Not used
  'NONE' = 'NONE', // Not used
  'POST_PAYMENT' = 'POST_PAYMENT', // Not used
  'WALLET' = 'WALLET', // Not used
  'WEB_PAYMENT_CREDIT_CARD' = 'WEB_PAYMENT_CREDIT_CARD', // regular shipment
}

export enum EcDelivereoCategoryType {
  'SMALL' = 'SMALL',
  'MEDIUM' = 'MEDIUM',
  'LARGE' = 'LARGE',
}

export enum EcDelivereoLang {
  'es' = 'es',
  'en' = 'en',
}

export interface IEcDelivereoLoginReq {
  apiKey: string;
  email: string;
  lang: EcDelivereoLang;
  ruc: string;
}

export interface IEcDelivereoLoginRes {
  title: string;
  message: string;
  status: boolean;
  id: number;
  code: number;
  ruc: string;
  businessName: string;
  email: string;
  countryCode: string;
  mobileNumber: string;
  businessImage: string;
  walletBalance: number;
  jwtToken: string;
}

export interface IEcDelivereoAddressPoint {
  address: string; // full address
  bookingPointType: EcDelivereoBookingPointType;
  phone: string;
  pointLatitude: number;
  pointLongitude: number;
  pointOrder: number; // Address order: '1' is the sender, '2' is the recipient
  reference: string; // Reference to sender or recipient
  senderRecipientName: string; // Name of sender or recipient
}

export interface IEcDelivereoOrderItem {
  orderItemDescription: string; // order items to send default 1
  orderItemGuid?: string;
  orderItemName: string; // item, product type
  orderItemPriceTotal: number;
  quantity: number; // default 1
  unitIva: number;
  unitSubTotal: number;
  unitTotal: number; // full price
}

export interface IEcDelivereoLabelReqApi {
  bookingBusinessUserEmail: string; // label business user email
  categoryType: EcDelivereoCategoryType;
  cityType: EcDelivereoCities;
  description: string | ProductCategory; // product to deliver
  extraInstructions?: string; // extra instructions (optional), its no necessary to create a label
  itemsPrice: number; // item full price
  lang: EcDelivereoLang;
  maxSuggestedTime?: string; // optional its no necessary to create a label
  order: {
    orderItems: IEcDelivereoOrderItem[]; // list of order items Required
    orderGuid: string; // here SHID identifier
    orderIva?: number;
    orderSubTotal?: number;
    orderTotal?: number; // When it's COD, that amount should be the collection value.
    paymentMode: EcDelivereoPaymentMode; // For COD, set to 'cash'
  };
  points: IEcDelivereoAddressPoint[]; // list of points for the route: at least 2 points (1st is sender, 2nd is recipient)
  // scheduledDate: 'yyyy-MM-dd kk:mm:ss'; // TODO p1 when it's a schedule shipment
}

export interface IEcDelivereoCalculateAddressPoint {
  pointLatitude: number;
  pointLongitude: number;
  pointOrder: number; // Address order: '1' is the sender, '2' is the recipient
}
export interface IEcDelivereoLabelReqDb {
  bookingBusinessUserEmail: string;
  categoryType: EcDelivereoCategoryType;
  cityType: EcDelivereoCities;
  description: string;
  extraInstructions?: string;
  itemsPrice: number;
  lang: EcDelivereoLang;
  //order: {
  //  orderItems:[{
  orderItemDescription: string;
  orderItemGuid: string;
  orderItemName: string; // item, product type
  orderItemPriceTotal: number;
  quantity: number; // default 1
  unitIva: number;
  unitSubTotal: number;
  unitTotal: number; // full price
  //     } ]
  orderGuid: string; // here shipment identifier ex: SENDIFICO|:|SHID=1234|:|SHOC=2024-01-31
  orderIva?: number;
  orderSubTotal?: number;
  orderTotal?: number; // When it's COD, that amount should be the collection value.
  paymentMode: EcDelivereoPaymentMode; // For COD, set to 'cash'
  // }
  // points: [
  // {
  senderAddress: string; // full address
  senderbookingPointType: EcDelivereoBookingPointType;
  senderPhone: string;
  senderPointLatitude: number;
  senderPointLongitude: number;
  senderPointOrder: number;
  senderReference: string;
  senderSenderRecipientName: string;
  //  },
  //  {
  recipientAddress: string; // full address
  recipientBookingPointType: EcDelivereoBookingPointType;
  recipientPhone: string;
  recipientPointLatitude: number;
  recipientPointLongitude: number;
  recipientPointOrder: number;
  recipientReference: string;
  recipientSenderRecipientName: string;
  //  },
  //  in case of COD we need to add the extra address, this address is a replica of sender address
  //  {
  returnAddress?: string; // full address
  returnBookingPointType?: EcDelivereoBookingPointType;
  returnPhone?: string;
  returnPointLatitude?: number;
  returnPointLongitude?: number;
  returnPointOrder?: number;
  returnReference?: string;
  returnSenderRecipientName?: string;
  //   }
  // ];
}

export interface IEcDelivereoLabelRes {
  title: string;
  message: string;
  status: boolean;
  id: number;
  code: number;
  bookingId: string;
}

export interface IEcDelivereoCalculateReq {
  cityType: EcDelivereoCities;
  lang: EcDelivereoLang;
  order: {
    paymentMode: EcDelivereoPaymentMode;
  };
  points: IEcDelivereoCalculateAddressPoint[]; // list of points for the route: at least 2 points (1st is sender, 2nd is recipient)
}

export interface IEcDelivereoLabelCalculateRes {
  title: EcDelivereoLabelApiMessage;
  message: string;
  status: boolean;
  id: number;
  code: number;
  farePrice: number;
  itemsPrice: number;
  ivaPercentage: number;
  iva: number;
  totalAmount: number;
  estimatedTime: string;
  totalDistance: number;
}

export interface IEcDelivereoLabelRegisteredReq {
  bookingId: string;
  lang: EcDelivereoLang;
}
export interface IEcDelivereoLabelRegisteredRes {
  title: string;
  message: string;
  status: boolean;
  id: number;
  code: number;
  bookingId: string;
  userId: number;
  generalUserId: number;
  driverId: number;
  driverFirstName: string;
  driverLastName: string;
  driverEmail: string;
  driverMobileNumber: string;
  driverRegistrationNumber: string;
  driverTransport: string;
  driverTransportModeName: string;
  firstPointSender: string;
  firstPointSenderCode: string;
  firstPointAddress: string;
  firstPointInitialTime: string;
  firstPointArrivalTime: string;
  lastPointRecipient: string;
  lastPointRecipientCode: string;
  lastPointAddress: string;
  lastPointInitialTime: string;
  lastPointArrivalTime: string;
  fare: number;
  description: string;
  receiptStatus: boolean;
  bookingStatus: EcDelivereoLabelStatus;
  bookingStatusName: string;
  itemsPrice: number;
  insuredAmount: number;
  iva: number;
  totalAmount: number;
  otherImage: string;
  totalDuration: string;
  extraInstructions: string;
  currentExtraPointId: number;
  publicGuid: string;
  publicUrl: string;
  packageHasBeenDelivered: boolean;
  extraPoints: any;
  orderReference: any[];
  surveyCompleted: boolean;
  // error?: string; // when JWT expired
}

export interface IEcDelivereoGeneratedLabel {
  labelId: string;
  trackingUrl: string;
}

export interface IEcDelivereoCancelLabelReq {
  bookingId: string;
  cancelReason: EcDelivereoCancelReason;
  lang: EcDelivereoLang;
}

export interface IEcDelivereoCancelLabelRes {
  title: EcDelivereoLabelApiMessage;
  message: string;
  status: boolean;
  id: number;
  code: number;
}

export interface IEcDelivereoLabel extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;
  labelReq?: IEcDelivereoLabelReqDb;
  labelRes?: IEcDelivereoLabelRes;
  registered?: IEcDelivereoLabelRegisteredRes;
  /**
   * OneToOne relation to shipment
   */
  shipment?: Relation<IShipment>;
}
