import {TerritoryBaseId} from '../../../address/data-access/address.model';
import {CollectiveCarrierToken} from '../../../collective-carrier/data-access/collective-carrier.model';
import {COMMON_MAX_GOODS_COLLECTION} from '../../../shipment/data-access/shipment.model';
import {EC_DELIVEREO_MAX_GOODS_COLLECTED} from '../ec-delivereo/ec-delivereo-label.model';
import {EC_LAAR_MAX_GOODS_COLLECTED, EC_LAAR_MAX_GOODS_INSURED} from '../ec-laar/ec-laar-label.model';
import {EcLaarTerritoryList} from '../ec-laar/territory/ec-laar-territory.list';
import {
  SERVIENTREGA_MAX_GOODS_COLLECTED,
  SERVIENTREGA_MAX_GOODS_INSURED,
} from '../ec-servientrega/servientrega-label-generate.model';
import {EcServientregaTerritoryList} from '../ec-servientrega/territory/ec-servientrega-territory.list';
import {EC_TRAMACO_MAX_GOODS_COLLECTED, EC_TRAMACO_MAX_GOODS_INSURED} from '../ec-tramaco/ec-tramaco-label.model';
import {EcTramacoTerritoryList} from '../ec-tramaco/territory/ec-tramaco-territory.list';
import {EcBaseTerritoryList} from './ec-base-territory.list';
import {EcAllTerritoryItemType, EcBaseTerritoryItem} from './ec-base-territory.model';

export function findTerritoryBaseItem(territoryBaseIdToSearch: string): EcBaseTerritoryItem | null {
  const baseTerritory: EcBaseTerritoryItem | undefined = EcBaseTerritoryList.find(
    ({territoryBaseId}) => territoryBaseId === territoryBaseIdToSearch
  );

  if (!baseTerritory || !baseTerritory?.territoryBaseId) {
    return null;
  }

  return baseTerritory;
}

export function findTerritoryCarrierItem(
  collectiveCarrierToken: CollectiveCarrierToken,
  territoryBaseId: TerritoryBaseId
): EcAllTerritoryItemType | null {
  let carrierTerritoryList: EcAllTerritoryItemType[] = [];

  switch (collectiveCarrierToken) {
    case CollectiveCarrierToken.servientrega:
      carrierTerritoryList = EcServientregaTerritoryList;
      break;
    case CollectiveCarrierToken.ec_tramaco:
      carrierTerritoryList = EcTramacoTerritoryList;
      break;
    case CollectiveCarrierToken.ec_laar:
      carrierTerritoryList = EcLaarTerritoryList;
      break;
    case CollectiveCarrierToken.ec_delivereo:
      carrierTerritoryList = EcBaseTerritoryList;
      break;
    // // Add here a new carrier territory list to be used for the search
    // case CollectiveCarrierToken.<NEW_CARRIER>:
    //   carrierTerritoryList = ...;
    //   break;
    default:
      return null;
  }

  if (!carrierTerritoryList.length) {
    return null;
  }

  const addressTerritoryItem: EcAllTerritoryItemType | undefined = carrierTerritoryList.find(
    (carrierTerritoryItem) => carrierTerritoryItem.territoryBaseId === territoryBaseId
  );

  if (!addressTerritoryItem || !addressTerritoryItem?.territoryBaseId) {
    return null;
  }

  return addressTerritoryItem;
}

export function getDefaultCarrierMaxGoodsInsured(): number {
  // TODO p2, a higher value of insurance from one courier could be allowed in the FE. This function limits the selection of the lowest possible value for goodsInsured, when a courier could allow a higher value, and we should allow to insert in the FE this higher value
  return Math.min(
    SERVIENTREGA_MAX_GOODS_INSURED,
    EC_TRAMACO_MAX_GOODS_INSURED,
    EC_LAAR_MAX_GOODS_INSURED
    // EC_DELIVEREO_MAX_GOODS_INSURED // TODO p2 in case Delivereo we only block in FE the selection of this carrier, we must implement this also in the BE side too
    // <NEW_CARRIER>_MAX_GOODS_INSURED,
  );
}

export function getDefaultCarrierMaxGoodsCollection(): number {
  // NOTE: This function allows the selection of the highest possible value for goodsCollection in the FE. When a courier does NOT allow the highest value, but only a lowe value than that, it should show an error message in the FE.
  return Math.max(
    COMMON_MAX_GOODS_COLLECTION,
    SERVIENTREGA_MAX_GOODS_COLLECTED,
    EC_TRAMACO_MAX_GOODS_COLLECTED,
    EC_LAAR_MAX_GOODS_COLLECTED,
    EC_DELIVEREO_MAX_GOODS_COLLECTED
    // <NEW_CARRIER>_MAX_GOODS_COLLECTED,
  );
}
