import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NotificationService} from '../../ui-notification';
import {jwtTokenKey} from '../models/auth.definitions';
import {AuthJwtService} from '../services/auth-jwt.service';

/**
 * Source, More information there:
 - https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

@Injectable()
export class AuthJwtHttpInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private authJwtService: AuthJwtService) {}

  // intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwtToken = localStorage.getItem(jwtTokenKey);

    if (jwtToken && request.url.includes(process.env['API_BASE_URL']!)) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + jwtToken),
      });
    }

    // console.log('----request----');
    // console.log(request);
    // console.log('--- end of request---');

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // console.log('all looks good');
            // // http response status code
            // console.log(event.status);
          }
        },
        (error) => {
          // // http response status code
          // console.log('----response----');
          // console.error(error);
          // console.error('status code:');
          // console.error(error.status);
          // console.error(error.message);
          // console.log('--- end of response---');

          if (error?.error?.message === 'Unauthorized') {
            // TODO p3, this is used for when the JWT has expired, and this forces a signout reload. Find a better way to handle this as the user will loose its state on this forced page reload (now it will only happen on the time that the JWT is set to expire = several days/months)
            this.notificationService.error(asTranslationKey('web-app-common.auth-jwt.signin.session-expired'));
            this.authJwtService.signout();
          } else if (error.status === 500) {
            // TODO p3 - later: used when backend is up but returns a 500 status code, find a better way to handle this error
            // For example in the `authJwtService.emailVerify`, the backend returns a 500 code when a emailVerifyToken is not valid UUID
            this.notificationService.error(asTranslationKey('web-app-common.auth-jwt.backend-down-error'));
          }
        }
      )
    );
  }
}
