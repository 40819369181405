export const regexNumber = /^\d+$/;

/**
 * Scale used in the Database to store a maximum of X decimal digits
 */
export const NUMBER_DIMENSION_SCALE = 6;

export function toFixedAndRound(num: number, precision: number): number {
  return parseFloat((+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision));
}
