import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity} from '../../../core/data-access/base-models/base-user-owned.model';
import {IUser} from '../../../core/data-access/user/user.model';
import {IShipment} from '../../../shipment/data-access/shipment.model';

export const EC_LAAR_TARIFFS_V1 = 'v-2024-11-17';

/**
 * Usage example of EC_LAAR_LABEL_TRACKING_URL, change the <%=carrierLabelUniqueId%> for the carrier's unique label ID.
 * Example:
 * - https://fenixoper.laarcourier.com/Tracking/GuiaCompleta.aspx?guia=100000000000002
 *
 * - Guía real: https://fenixoper.laarcourier.com/Tracking/GuiaCompleta.aspx?guia=SISLC49380353
 */
export const EC_LAAR_LABEL_TRACKING_URL = `https://fenixoper.laarcourier.com/Tracking/GuiaCompleta.aspx?guia=<%=carrierLabelUniqueId%>`;

export const EC_LAAR_API_MAX_WEIGHT = 50;

export const EC_LAAR_MAX_GOODS_INSURED = 10000; // in USD // TODO-JC check value
export const EC_LAAR_MAX_GOODS_COLLECTED = 300;
export const EC_LAAR_MAX_GOODS_COLLECTED_REAL = 300; // in USD // TODO p1 delete this when fix the TODO of 'EC_TRAMACO_MAX_GOODS_COLLECTED'. Now this is used to block the selection of this carrier rate ONLY in the FE.

export enum EcLaarCodigoServicio {
  'DELIVERY' = 2012020020091,
  'DOCUMENTO' = 201202002001002,
  'CARGA' = 201202002002013,
  'VALIJA' = 201202002004001,
  'VISITAS' = 201202002005018,
}

export enum EcLaarTrayecto {
  'LOCAL' = 'LOCAL',
  'PRINCIPAL' = 'PRINCIPAL',
  'SECUNDARIO' = 'SECUNDARIO',
  'ESPECIAL' = 'ESPECIAL',
  'ORIENTE' = 'ORIENTE',
  'GALAPAGOS' = 'GALAPAGOS',
}

export interface IEcLaarLoginReq {
  username: string;
  password: string;
}

export interface IEcLaarLoginRes {
  token: string;
  nombre: string;
  ruc: string;
  codigoUsuario: number;
  codigoSucursal: number;
  Message?: string;
}

export interface IEcLaarTrackingRes {
  guia: string;
  idEstado: number;
  estado: string;
  fecha: string;
  coordenadas?: {
    latitud: string;
    longitud: string;
  };
}

export interface IEcLaarQuoteReq {
  codigoServicio: EcLaarCodigoServicio;
  codigoCiudadOrigen: number;
  codigoCiudadDestino: number;
  piezas: number;
  peso: number;
}

export interface IEcLaarQuoteRes {
  Flete?: number;
  DescValor?: number;
  DescPorce?: number;
  Seguro?: number;
  TipoSeguro?: string;
  Trayecto?: EcLaarTrayecto;
}

export interface ILaarExtras {
  Campo1?: string;
  Campo2?: string;
  Campo3?: string;
}

export interface IEcLaarAddressBase {
  direccion?: string;
  referencia?: string; // string referencial like '5th Av.' is optional
  numeroCasa?: string;
  postal?: string;
  telefono?: string; // API requires at least 1 character in ILaarDestino
  celular?: string; // API requires at least 1 character in ILaarDestino
}

export interface ILaarOrigen extends IEcLaarAddressBase {
  identificacionO?: string; // DNI is optional for ex '12234567898'
  ciudadO?: string;
  nombreO?: string;
}

export interface ILaarDestino extends IEcLaarAddressBase {
  identificacionD?: string; // DNI is optional for ex '12234567898'
  ciudadD?: string;
  nombreD?: string;
}

export interface IEcLaarLabelReq {
  origen: ILaarOrigen;
  destino: ILaarDestino;
  // numeroGuia?: string; // need it to add a new parcel within the same label, currently we only allow 1 single parcel per label
  tipoServicio: EcLaarCodigoServicio;
  noPiezas: number;
  peso: number;
  valorDeclarado?: number; // optional, value of the parcel
  contiene?: string;
  tamanio?: string; // optional for ex '40x40x40'
  cod?: boolean; // is used to enable the costoFlete and costoProducto field.
  costoflete?: number; // if cod is true, it is required
  costoproducto?: number; // if cod is true, it is required
  tipocobro?: number; // optional
  comentario?: string; // optional, used to add SHID
  extras?: ILaarExtras;
  // fechaPedido: '01/01/2020'; "string", (opcional), "fecha de pedido futuro"
  // retiroOficina: {
  //   isRetiro: true;
  //   idOficina: 0;
  // };
}

export interface IEcLaarLabelRes {
  guia?: string;
  url?: string;
  Message?: string;
  // zpl?: string; // not stored in DB
}

export interface IEcLaarLabelRegistered {
  noGuia?: string;
  nombreCliente?: string;
  sucursal?: string;
  ciudadOrigen?: string;
  ciudadDestino?: string;
  productoFacturado?: string;
  producto?: string;
  de?: string;
  direccionOrigen?: string;
  telefonoOrigen1?: string;
  telefonoOrigen2?: string;
  para?: string;
  direccionDestino?: string;
  telefonoDestino?: string;
  telefonoDestino2?: string;
  numeroEnvios?: number;
  pesoKilos?: number;
  remitente?: string;
  remitenteFecha?: string;
  remitenteFecha1?: string;
  destinatario?: string;
  destinatarioFecha?: string;
  destinatarioFecha1?: string;
  observaciones?: string;
  estadoActual?: string;
  estadoActualCodigo?: number;
  entregadoSMS?: string;
  fechaEnvioSMS?: string;
  entregadoEmail?: string;
  fechaEnvioEmail?: string;
  acuseRecibido?: string;
  confirmacionLectura?: string;
  cc?: string;
  Message?: string;
  // tracking?: string; // not stored in DB // TODO p2 add enum from "api/estados", to have the types of the LaarShipmentStatus, and make a map to our ShipmentStatus so we can add the tracking // TODO p1
  // novedades?: string; // not stored in DB
  // imagenes?: string; // not stored in DB
  // novedadesRecoleccion?: string; // not stored in DB
  // imagenesRecoleccion?: string; // not stored in DB
  // coordenadasRecoleccion?: string; // not stored in DB
  // coordenadasEntrega?: string; // not stored in DB
  // novedadesAduana?: string; // not stored in DB
}

export interface IEcLaarLabelPdf {
  responseLaar?: IEcLaarLabelRes;
  pdfBase64?: string; // Base64 PDF content este si es de sendifico
  pdfUrl?: string; // URL of the PDF stored in a cloud storage service // TODO p2, add a link to the pdf URL when cloud file storage service is implemented
}

export interface IEcLaarLabel extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  quoteReq?: IEcLaarQuoteReq;
  quoteRes?: IEcLaarQuoteRes;

  labelReq?: IEcLaarLabelReq;
  labelRes?: IEcLaarLabelRes;

  registered?: IEcLaarLabelRegistered;

  pdf?: IEcLaarLabelPdf;

  /**
   * OneToOne relation to shipment
   */
  shipment?: Relation<IShipment>;
}
