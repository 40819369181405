<div class="container bsz-padding-t12 bsz-padding-l12">
  <img src="./assets/img/app-logo-white.png" width="300" />
  <div class="bsz-padding-t12">
    <div class="bsz-title-4 bsz-text-bold"> Facilitamos tu logística</div>
    <div
      class="bsz-padding-t12 benefit bsz-display-flex bsz-align-items-center bsz-display-flex bsz-align-items-center"
    >
      <img class="bsz-margin-r5" src="./assets/img/auth/marketing/check.svg" height="30" width="30" />
      <span> Compara y elige el mejor courier de Ecuador según tu conveniencia </span>
    </div>
    <div class="bsz-margin-t6 bsz-margin-l12">
      <img class="bsz-margin-r5 bsz-margin-b2" src="./assets/img/carrier/ec-servientrega-logo-long.png" height="40" />
      <img class="bsz-margin-r5 bsz-margin-b2" src="./assets/img/carrier/ec-tramaco-logo-long.png" height="35" />
      <!-- <img class="bsz-margin-r5 bsz-margin-b2" src="./assets/img/carrier/<NEW_CARRIER>-logo-long.png" height="35" /> -->
    </div>
    <div class="bsz-padding-t4 benefit bsz-display-flex bsz-align-items-center">
      <img class="bsz-margin-r5" src="./assets/img/auth/marketing/check.svg" height="30" width="30" />
      <span> Envíos dentro de tu ciudad por solo $1.99</span>
    </div>
    <div class="bsz-padding-t6 benefit bsz-display-flex bsz-align-items-center"
      ><img class="bsz-margin-r5" src="./assets/img/auth/marketing/check.svg" height="30" width="30" /><span
        >Envíos a otras ciudades por $3.99, por los primeros 5 kilos</span
      ></div
    >
    <div class="bsz-padding-t6 benefit bsz-display-flex bsz-align-items-center"
      ><img class="bsz-margin-r5" src="./assets/img/auth/marketing/check.svg" height="30" width="30" /><span
        >Programa recolecciones de tus paquetes desde tu local o domicilio</span
      ></div
    >
    <div class="bsz-padding-t6 benefit bsz-display-flex bsz-align-items-center"
      ><img class="bsz-margin-r5" src="./assets/img/auth/marketing/check.svg" height="30" width="30" /><span
        >Rastreo de tus paquetes en tiempo real</span
      ></div
    >
    <div class="bsz-padding-t6 benefit bsz-display-flex bsz-align-items-center"
      ><img class="bsz-margin-r5" src="./assets/img/auth/marketing/check.svg" height="30" width="30" /><span
        >Notificaciones automáticas a tus clientes del estado de tus envíos</span
      ></div
    >
    <div class="bsz-padding-t6 bsz-padding-b12 bsz-text-italic bsz-title-4" style="max-width: 500px">
      Ahorra hasta el 60% en los costos de tus envíos.
    </div>
  </div>
</div>
