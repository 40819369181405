/**
 * If:
 * - Default alphabet: include all number digits and all letter (uppercase and lowercase) only
 * - ID length characters: 16
 * - Speed: 100 IDs per hour/second
 * ---
 * Then:
 *  ~10 thousand years or 30T IDs needed, in order to have a 1% probability of at least one collision.
 */

export const NANOID_DEFAULT_ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const NANOID_DEFAULT_SIZE = 16;
