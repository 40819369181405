import {Relation} from 'typeorm';
import {
  CarrierPickup,
  IBaseUserOwnedEntity,
  IEcDelivereoLabel,
  IEcLaarLabel,
  IEcTramacoLabel,
  IManifest,
  IOrder,
  IParcel,
  IPaymentIntent,
  IRate,
  IServientregaLabelGenerate,
  IUser,
  ProductCategory,
} from '../..';
import {Currency} from '../../../utils/models';

export const shipmentIdLabelToken = 'SHID';
export const shipmentObjectCreatedLabelToken = 'SHOC';

/**
 * This is a text template to replace the unique carrier's label ID, usually called tracking number
 */
export const CARRIER_LABEL_UNIQUE_ID_PLACEHOLDER = '<%=carrierLabelUniqueId%>';

/**
 * This should be the max amount of money to be collected by a delivery person across ALL carriers.
 * Some carriers could allow smaller amount as a maximum, but we should check that none carrier
 * surpass this common max limit due to our T&C.
 */
export const COMMON_MAX_GOODS_COLLECTION = 300;
export const COMMON_MIN_GOODS_COLLECTION = 10; // These value should be at least the minimum COD profit fee charge by the chepeast carrier (currently it's 2 USD), plus a certain amount to be transferred to the user (at least 5 USD) so it makes sense to use COD

export interface ShipmentSettings {
  carrierPickups?: CarrierPickup[];
  objectType: string;
}

export enum ShipmentStatus {
  /**
   * unknown: status when the shipment delivery is not defined or unknown
   */
  unknown = 'unknown',

  /**
   * (default) created: shipment created
   */
  created = 'created',

  /**
   * quoted:  Shipment rates has been successfully created
   */
  quoted = 'quoted',

  /**
   * labelCreated: The shipment object MUST have 'isPaid=true' before changing to this status. Shipment's label has been successfully created (with the corresponding carrier).
   * Should populate columns 'trackingNumber', 'trackingUrl', 'label'.
   */
  labelCreated = 'labelCreated',

  /**
   * pickupRequested: refers when the carrier has been successfully notified to schedule a pickup (in the future), and it's processing the pickup (ex. looking for a driver, or scheduling the route for a following day)
   */
  pickupRequested = 'pickupRequested',

  /**
   * pickupNotFound: refers when the carrier has notified us that a pickup is not possible due to some reason (out of coverage area, out-of-office schedule, etc.).
   * Usually our user should request some manual intervention (refund/annulment) from our customer service.
   */
  pickupNotFound = 'pickupNotFound',

  /**
   * TODO p2 CRON, should be a CRON job checking the carrier's API to update to this status
   * pickingUp: Carrier's driver is currently on the way to pickup the package (usually at sender address).
   */
  pickingUp = 'pickingUp',

  /**
   * TODO p2 CRON, should be set via CRON job one day after the scheduled manifest.shipmentDate
   * pickedUp: Carrier has successfully picked-up this Shipment, and will soon be in transit to its final destination (now the package is stored in the carrier's premises).
   */
  pickedUp = 'pickedUp',

  /**
   * TODO p2 CRON, should be a CRON job checking the carrier's API to update to this status
   * inTransit: Carrier has successfully picked-up this Shipment, and is now in transit to its final destination.
   */
  inTransit = 'inTransit',

  /**
   * TODO p2 CRON, should be a CRON job checking the carrier's API to update to this status
   * goodsCollectionInTransit: Carrier is in the process to take the shipment.goodsCollection to the sender (return to origin point).
   */
  goodsCollectionInTransit = 'goodsCollectionInTransit',

  /**
   * TODO p2 CRON, should be a CRON job checking the carrier's API to update to this status
   * delivered: Carrier has successfully delivered this Shipment to its final destination.
   */
  delivered = 'delivered',

  /**
   * TODO p2 CRON, should be a CRON job checking the carrier's API to update to this status
   * returnToSender: Carrier is in the process to return the shipment to its origin for an unknown reason (ex. recipient address was not valid, recipient person didn't want to receive the parcel).
   */
  returnToSender = 'returnToSender',

  /**
   * annulled: The shipment object MUST have 'isPaid=true' before changing to this status. Shipment's label could have successfully created (with the corresponding carrier) or NOT.
   * This status refers when a user/admin requested the annullment of a already paid shipment for any reason.
   * TODO p1: A shipment can only be annulled before it's picked up by carrier, so check the .
   * TODO p1: Should call carrier API to annul this shipment label. Currently this is done manually on the carrier admin app.
   * TODO p1: Should call carrier API to check if this label has NOT been picked up by the carrier. Currently this is done manually on the carrier admin app.
   * TODO p1: Should regenerate a new app's manifest PDF, where we remove the annulled shipment. Currently is not done, and the PDF manifest could include annulled shipments (when they were annulled after the manifest PDF was created).
   */
  annulled = 'annulled',

  /**
   * refunded: The shipment object MUST have 'isPaid=true' before changing to this status. Shipment's label payment has been successfully refunded.
   */
  refunded = 'refunded',
}

export enum ShipmentTracking {
  /**
   * unknown: status when the tracking is not defined or unknown
   */
  unknown = 'unknown',

  /**
   * pickupProcessing: refers that the carrier is processing the pickup (ex. looking for a driver, schedule a future pickup, etc)
   */
  pickupProcessing = 'pickupProcessing',

  /**
   * pickedUp: refers that the carrier picked up the parcel and is currently awaiting somewhere (ex. in their warehouse) to start the route to the recipient in the future
   */
  pickedUp = 'pickedUp',

  /**
   * inTransit: refers that the carrier driver started the route in direction to the recipient
   */
  inTransit = 'inTransit',

  /**
   * delivered: refers that the carrier delivered the parcel
   */
  delivered = 'delivered',
}

/**
 * contactCustomerService: refers when a (human / manual) action is needed to process this shipment, which it's delivery is currently paused and waiting for an intervention.
 */
// contactCustomerService = 'contactCustomerService',

export const ShipmentStatusAllowedToAnnul = [
  ShipmentStatus.created,
  ShipmentStatus.quoted,
  ShipmentStatus.labelCreated,
];

export const ShipmentStatusNotAllowedToUpdateTracking = [
  ShipmentStatus.quoted, // there's no label yet, so it cannot be tracked
  ShipmentStatus.delivered,
  ShipmentStatus.annulled,
  ShipmentStatus.refunded,
  ShipmentStatus.pickupNotFound,
];

export const ShipmentStatusShipmentTrackingMap: {
  [key in ShipmentStatus]: ShipmentTracking;
} = {
  // ShipmentStatus not part of the flow of tracking
  unknown: ShipmentTracking.unknown,
  created: ShipmentTracking.unknown,
  quoted: ShipmentTracking.unknown,
  goodsCollectionInTransit: ShipmentTracking.unknown,
  annulled: ShipmentTracking.unknown,
  refunded: ShipmentTracking.unknown,

  // 1. pickupProcessing
  labelCreated: ShipmentTracking.pickupProcessing,
  pickupRequested: ShipmentTracking.pickupProcessing,
  pickupNotFound: ShipmentTracking.pickupProcessing,
  pickingUp: ShipmentTracking.pickupProcessing,

  // 2. pickedUp
  pickedUp: ShipmentTracking.pickedUp,

  // 3. inTransit
  inTransit: ShipmentTracking.inTransit,

  // 4. delivered
  delivered: ShipmentTracking.delivered,
  returnToSender: ShipmentTracking.delivered,
};

/**
 * This is used in the FE to show the tracking step in the shipment tracking page
 */
export const ShipmentTrackingSteps: {[key in ShipmentTracking]: number} = {
  [ShipmentTracking.pickupProcessing]: 0,
  [ShipmentTracking.pickedUp]: 1,
  [ShipmentTracking.inTransit]: 2,
  [ShipmentTracking.delivered]: 3,
  [ShipmentTracking.unknown]: -1,
};

export interface ShipmentGeneratedLabel extends Required<Pick<IShipment, 'objectId' | 'trackingNumber'>> {}

export interface ShipmentTrackingStatusRes {
  trackedShipment: Required<Pick<IShipment, 'status' | 'isDeliveryOnHold'>>;
  carrierDetails?: Object; // used for carrier specific data needed to be updated in the carrierLabel database table
}

export interface IShipment extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  status?: ShipmentStatus;

  /**
   * `statusPickedUpEmailSent` is set when the user created a label (via the manifest page)
   * and this label generated a trackingCarrierUrl, which is sent via email to the shipment recipient.
   * This email should only be sent once, so we use this field to check that this email is only sent once,
   * as the manifest could be created multiple times (and the email with the tracking could potentially be sent multiple times).
   */
  statusPickedUpEmailSent?: boolean;

  isPaid?: boolean;

  isDeliveryOnHold?: boolean;

  /**
   * Currency to be used for the fields 'goodsValue' and 'goodsInsured' and 'goodsCollection'
   */
  goodsCurrency?: Currency;

  /**
   * Declared amount from the customer, as the value of the goods in this shipment. This should be used to calculate the insurance of the goods by the carrier.
   */
  goodsValue?: number;

  /**
   * @deprecated: If value in field 'goodsInsured' is defined (a number greater than 0), we can assume that this shipment is meant to have the goods insurance policy
   * // TODO p2 delete
   * If true, this shipment must add the corresponding carrier's good insurance to the total price of the rate.
   */
  isInsured?: boolean;

  /**
   * Declared amount from the customer, as the value of the insured goods in this shipment.
   */
  goodsInsured?: number;

  /**
   * Declared amount from the shipment's sender, as the money to be collected (by the delivery person) as payment (from the recipient person) of the goods in this shipment.
   */
  goodsCollection?: number;

  /**
   * The property 'contents' within the a shipment object, should be ONLY used when this shipment is NOT linked to any 'lineItem or product', in order to inform the carrier of the contents of the shipment (required by some carriers to create a label).
   * When a shipment object is indeed linked to some 'lineItem or product', then the contents of the shipment should be derived from those linked products, i.e., aggregate all the items from 'shipment.parcels[].lineItems[].product.category'
   * WARNING: Be aware to NOT duplicate the info, by filling this property 'contents', when there are some 'products' objects linked to this shipment object.
   */
  contents?: ProductCategory[];

  labelUrl?: string; // TODO p2 now the label is only under field "servientregaLabelGenerate", change this to be a generic label URL/PDF from multiple carrier to be store in this field. URL of label's image or PDF to be downloaded
  trackingNumber?: string; // When this field in NOT null and contains a value, we assume that the label was generated at some point in time (the PDF or tracking URL might not work for some reason but when this field is filled we could assume there is a label created). This means this field acts similar to a "isLabelCreated: true/false".
  trackingCarrierUrl?: string; // TODO p3 change to URL or add decorator
  trackingStatus?: string; // TODO p3 change to enum or to an own Entity (status value, status updated date, status details, status history)

  internalNote?: string; // extra comments internal to app account owner // TODO p3 implement
  packingSlipComment?: string; // extra comments to add to the shipment label // TODO p3 implement
  // printFormat: PrintFormat, // ex. "PDF", TODO p3
  // printSize: PrintSize, // ex. "STOCK_4X6", TODO p3

  /**
   * true, if this shipment packages have been returned
   */
  isReturn?: boolean; // TODO p3 implement

  /**
   * OneToOne relation to the preferred rate that user selected to use and be charged
   */
  preferredRateObjectId?: number;
  preferredRate?: Relation<IRate>;

  /**
   * OneToOne relation to the order linked to this shipment
   */
  orderObjectId: number;
  order?: Relation<IOrder>;

  /**
   * OneToMany relation, List of all parcels to be shipped
   */
  parcels?: Relation<IParcel[]>;

  /**
   * OneToMany relation, List of all generated rates for this shipment attributes (parcels, addresses, etc.)
   */
  rates?: Relation<IRate[]>;

  /**
   * OneToOne relation to the paymentIntent that was executed to pay the preferredRateObjectId
   */
  shipmentPurchasePaymentIntentObjectId?: number;
  shipmentPurchasePaymentIntent?: Relation<IPaymentIntent>;

  /**
   * OneToOne relation to the paymentIntent that was executed to refund the amount spent in 'preferredRate.amount', per request to cancel and refund the shipment
   */
  shipmentRefundPaymentIntentObjectId?: number;
  shipmentRefundPaymentIntent?: Relation<IPaymentIntent>;

  /**
   * OneToOne relation to the paymentIntent that was executed to collect the goodsCollection amount
   */
  shipmentCollectionPaymentIntentObjectId?: number;
  shipmentCollectionPaymentIntent?: Relation<IPaymentIntent>;

  /**
   * ManyToOne relation to the parent manifest from this shipment
   */
  manifestObjectId?: number;
  manifest?: Relation<IManifest>;

  /**
   * OneToOne relation to the servientregaLabelGenerate that was executed by calling its API
   */
  servientregaLabelGenerateObjectId?: number;
  servientregaLabelGenerate?: Relation<IServientregaLabelGenerate>;

  /**
   * OneToOne relation to the ecTramacoLabel that was executed by calling its API
   */
  ecTramacoLabelObjectId?: number;
  ecTramacoLabel?: Relation<IEcTramacoLabel>;

  /**
   * OneToOne relation to the ecLaarLabel that was executed by calling the its API
   */
  ecLaarLabelObjectId?: number;
  ecLaarLabel?: Relation<IEcLaarLabel>;

  /**
   * OneToOne relation to the ecDelivereoLabel that was executed by calling its API
   */
  ecDelivereoLabelObjectId?: number;
  ecDelivereoLabel?: Relation<IEcDelivereoLabel>;

  // /**
  //  * OneToOne relation to the carrier <NEW_CARRIER>Label that was executed by calling the its API
  //  */
  // <NEW_CARRIER>LabelObjectId?: number;
  // <NEW_CARRIER>Label?: Relation<...>;
}
