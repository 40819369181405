import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {ShipmentOverviewPage} from './shipment-overview.page';

export interface ShipmentTrackingOverviewPagePayload {
  shipmentObjectId?: number;
}

export class ShipmentTrackingOverviewPage extends PortalPage {
  constructor(public payload: ShipmentTrackingOverviewPagePayload = {}) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new ShipmentOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.shipment-tracking-overview.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.shipment-tracking-overview.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.shipment-tracking-overview.window-title');
  }

  static readonly routerConfigPath = 'shipments/tracking';
  get route(): Array<string> {
    return [`/${ShipmentTrackingOverviewPage.routerConfigPath}`];
  }
}
