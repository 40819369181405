import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity} from '../../../core/data-access/base-models/base-user-owned.model';
import {IUser} from '../../../core/data-access/user/user.model';
import {IShipment} from '../../../shipment/data-access/shipment.model';

export const EC_YOBEL_TARIFFS_V1 = 'v-2024-11-17'; // TODO-JC

export interface IEcYobelLabel extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  /**
   * OneToOne relation to shipment
   */
  shipment?: Relation<IShipment>;
}
