import {ApiHttpErrorCodes} from '../../../api-http-error-codes';
import {NUMBER_CURRENCY_SCALE} from '../../../utils/common';
import {CollectiveCarrierToken} from '../../collective-carrier/data-access/collective-carrier.model';
import {IRate} from '../../rate/data-access/rate.model';

/**
 * @return based on the preferred rate, the amount to be paid now (this is at time of the execution of the transaction shipment-purchase)
 */
export function getShipmentPurchaseTotalToPay(rate: IRate): number | null {
  if (!rate) {
    return null;
  }

  const collectiveCarrierToken: CollectiveCarrierToken =
    rate?.individualServiceLevel?.collectiveServiceLevel?.collectiveCarrier?.token!;

  if (!collectiveCarrierToken) {
    throw new Error(ApiHttpErrorCodes.shipmentPaymentIntentError);
  }

  let totalToPayNow: number;
  if (collectiveCarrierToken === CollectiveCarrierToken.ec_delivereo) {
    totalToPayNow = rate.profitTotal ?? 0;
  } else {
    totalToPayNow = (rate.profitTotal ?? 0) - getShipmentCollectionTotalToPay(rate)!;
  }

  return parseFloat(totalToPayNow.toFixed(NUMBER_CURRENCY_SCALE));
}

/**
 * @return based on the preferred rate, the amount to be paid later (this is after the execution of the transaction shipmentPurchasePaymentIntent)
 * Currently only the collectionFee is set to be paid later in time (it should be charged on the shipmentCollectionPaymentIntent)
 */
export function getShipmentCollectionTotalToPay(rate: IRate): number | null {
  if (!rate) {
    return null;
  }

  const collectiveCarrierToken: CollectiveCarrierToken =
    rate?.individualServiceLevel?.collectiveServiceLevel?.collectiveCarrier?.token!;

  if (!collectiveCarrierToken) {
    throw new Error(ApiHttpErrorCodes.shipmentPaymentIntentError);
  }

  let totalToPayLater: number;
  if (collectiveCarrierToken === CollectiveCarrierToken.ec_delivereo) {
    totalToPayLater = 0;
  } else {
    totalToPayLater = (rate.profitCollection ?? 0) + (rate.profitCollectionVat ?? 0);
  }

  return parseFloat(totalToPayLater.toFixed(NUMBER_CURRENCY_SCALE));
}
