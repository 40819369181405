import {EcDelivereoLabelStatus} from './ec-delivereo-label-status';
import {
  EcDelivereoBookingPointType,
  EcDelivereoCategoryType,
  EcDelivereoLabelApiMessage,
  EcDelivereoLang,
  EcDelivereoPaymentMode,
  IEcDelivereoAddressPoint,
  IEcDelivereoCalculateReq,
  IEcDelivereoCancelLabelRes,
  IEcDelivereoLabel,
  IEcDelivereoLabelCalculateRes,
  IEcDelivereoLabelRegisteredReq,
  IEcDelivereoLabelRegisteredRes,
  IEcDelivereoLabelReqApi,
  IEcDelivereoLabelReqDb,
  IEcDelivereoLabelRes,
  IEcDelivereoOrderItem,
} from './ec-delivereo-label.model';
import {EcDelivereoCities} from './territory/ec-delivereo-territory-list';

export const EC_DELIVEREO_SENDER: IEcDelivereoAddressPoint = {
  address: 'Av. Naciones Unidas E42-13',
  bookingPointType: EcDelivereoBookingPointType.PICK_UP,
  phone: '+593 123456789',
  pointLatitude: -2.887447,
  pointLongitude: -79.009883,
  pointOrder: 1,
  reference: '',
  senderRecipientName: 'John Smith',
};

export const EC_DELIVEREO_RECIPIENT: IEcDelivereoAddressPoint = {
  address: 'Av. Occidental 123',
  bookingPointType: EcDelivereoBookingPointType.DROP_OFF,
  phone: '+593 123456789',
  pointLatitude: -2.887448,
  pointLongitude: -79.009884,
  pointOrder: 2,
  reference: '',
  senderRecipientName: 'Valentina Enriquez',
};

export const EC_DELIVEREO_ORDER_ITEMS: IEcDelivereoOrderItem[] = [
  {
    orderItemDescription: '1',
    orderItemName: 'producto de prueba',
    orderItemPriceTotal: 0,
    quantity: 1,
    unitIva: 0,
    unitSubTotal: 0,
    unitTotal: 100,
  },
];

export const EC_DELIVEREO_LABEL_REQ_API_MOCK: IEcDelivereoLabelReqApi = {
  bookingBusinessUserEmail: 'user@email.com',
  categoryType: EcDelivereoCategoryType.SMALL,
  cityType: EcDelivereoCities.QUITO,
  description: '',
  extraInstructions: '',
  itemsPrice: 25,
  lang: EcDelivereoLang.es,
  order: {
    orderItems: [
      {
        orderItemDescription: '1',
        orderItemName: 'Test Product',
        orderItemPriceTotal: 0,
        quantity: 1,
        unitIva: 0,
        unitSubTotal: 0,
        unitTotal: 100,
      },
    ],
    orderGuid: 'SENDIFICO|:|SHID=1234|:|SHOC=2024-01-31',
    orderIva: 0,
    orderSubTotal: 0,
    orderTotal: 0,
    paymentMode: EcDelivereoPaymentMode.WEB_PAYMENT_CREDIT_CARD,
  },
  points: [
    // Sender Address
    {
      address: 'Av. Calle 123',
      bookingPointType: EcDelivereoBookingPointType.PICK_UP,
      phone: '+593-123456789',
      pointLatitude: -0.223252,
      pointLongitude: -78.514106,
      pointOrder: 1,
      reference: '',
      senderRecipientName: 'John Smith',
    },
    // Recipient Address
    {
      address: 'Av. Calle 456',
      bookingPointType: EcDelivereoBookingPointType.DROP_OFF,
      phone: '+593-123456789',
      pointLatitude: -0.223253,
      pointLongitude: -78.514107,
      pointOrder: 2,
      reference: '',
      senderRecipientName: 'Valentina Enriquez',
    },
  ],
};

export const EC_DELIVEREO_LABEL_REQ_DB_MOCK: IEcDelivereoLabelReqDb = {
  bookingBusinessUserEmail: 'user@email.com',
  categoryType: EcDelivereoCategoryType.SMALL,
  cityType: EcDelivereoCities.QUITO,
  description: 'Test Product',
  extraInstructions: '',
  itemsPrice: 25,
  lang: EcDelivereoLang.es,
  orderItemDescription: '1',
  orderItemGuid: '',
  orderItemName: 'producto de prueba',
  orderItemPriceTotal: 0,
  quantity: 1,
  orderGuid: 'SENDIFICO|:|SHID=1234|:|SHOC=2024-01-31',
  unitIva: 0,
  unitSubTotal: 0,
  unitTotal: 100,
  orderIva: 0,
  orderSubTotal: 0,
  orderTotal: 0,
  paymentMode: EcDelivereoPaymentMode.WEB_PAYMENT_CREDIT_CARD,
  senderAddress: 'Av. Calle 123',
  senderbookingPointType: EcDelivereoBookingPointType.PICK_UP,
  senderPhone: '+593-123456789',
  senderPointLatitude: -0.223252,
  senderPointLongitude: -78.514106,
  senderPointOrder: 1,
  senderReference: '',
  senderSenderRecipientName: 'John Smith',
  recipientAddress: 'Av. Calle 456',
  recipientBookingPointType: EcDelivereoBookingPointType.DROP_OFF,
  recipientPhone: '+593-123456789',
  recipientPointLatitude: -0.223253,
  recipientPointLongitude: -78.514107,
  recipientPointOrder: 2,
  recipientReference: '',
  recipientSenderRecipientName: 'Valentina Enriquez',
  ///////////////
  // COD - START
  returnAddress: 'Av. Calle 123',
  returnBookingPointType: EcDelivereoBookingPointType.PICK_UP,
  returnPhone: '+593-123456789',
  returnPointLatitude: -0.223252,
  returnPointLongitude: -78.514106,
  returnPointOrder: 3,
  returnReference: '',
  // COD - END
  ///////////////
};

export const EC_DELIVEREO_LABEL_RES_MOCK: IEcDelivereoLabelRes = {
  title: 'Éxito',
  message: 'Petición exitosa',
  status: true,
  id: 0,
  code: 200,
  bookingId: '532061',
};

export const EC_DELIVEREO_LABEL_REGISTERED_REQ_MOCK: IEcDelivereoLabelRegisteredReq = {
  bookingId: '532000',
  lang: EcDelivereoLang.es,
};

export const EC_DELIVEREO_LABEL_REGISTERED_RES_MOCK: IEcDelivereoLabelRegisteredRes = {
  title: 'Éxito',
  message: 'Petición exitosa',
  status: true,
  id: 0,
  code: 200,
  bookingId: '531933',
  userId: 5571,
  generalUserId: 37031,
  driverId: 18403,
  driverFirstName: 'STAGING',
  driverLastName: 'DEL',
  driverEmail: 'pruesta@gmail.com',
  driverMobileNumber: '+593-998970699',
  driverRegistrationNumber: '1715141337',
  driverTransport: 'MOTORCYCLE',
  driverTransportModeName: 'Motocicleta',
  firstPointSender: 'Direccion 1',
  firstPointSenderCode: 'DIR1',
  firstPointAddress: 'Calle 123',
  firstPointInitialTime: '2024-10-14 15:11:21',
  firstPointArrivalTime: '2024-10-14 15:18:06',
  lastPointRecipient: 'Direccion 1',
  lastPointRecipientCode: 'DIR1',
  lastPointAddress: 'Calle 123',
  lastPointInitialTime: '2024-10-14 15:40:30',
  lastPointArrivalTime: '2024-10-14 15:41:18',
  fare: 3,
  description: 'Domicilios de la empresa: Sendifico',
  receiptStatus: false,
  bookingStatus: EcDelivereoLabelStatus.FINISHED,
  bookingStatusName: 'Finalizado',
  itemsPrice: 0,
  insuredAmount: 0,
  iva: 15,
  totalAmount: 3.45,
  otherImage:
    'https://imagesdelivereo-latin-america.s3.sa-east-1.amazonaws.com/bookings/531933_165cf6f5-df57-402c-9a3b-c5ac8565ac32.jpg',
  totalDuration: '00:35:54',
  extraInstructions: null,
  currentExtraPointId: null,
  publicGuid: 'c5c57319-ba4b-411b-ab97-7ccea91b6e9b',
  publicUrl: 'https://delivereo-test.com/booking-public/c5c57319-ba4b-411b-ab97-7ccea91b6e9b',
  packageHasBeenDelivered: true,
  extraPoints: [
    {
      pointId: 1359430,
      pointOrder: 2,
      senderRecipientName: 'Juan Perez',
      senderRecipientCode: '-',
      address: 'Calle Destino 456',
      reference: 'Dept 101',
      pointInitialTime: '2024-10-14 15:18:29',
      pointArrivalTime: '2024-10-14 15:40:25',
    },
  ],
  orderReference: ['FACTURA_001'],
  surveyCompleted: false,
};

export const EC_DELIVEREO_LABEL_CALCULATE_REQ_MOCK: IEcDelivereoCalculateReq = {
  cityType: EcDelivereoCities.QUITO,
  lang: EcDelivereoLang.es,
  order: {
    paymentMode: EcDelivereoPaymentMode.WEB_PAYMENT_CREDIT_CARD,
  },
  points: [
    {
      pointLatitude: -0.223252,
      pointLongitude: -78.514106,
      pointOrder: 1,
    },
    {
      pointLatitude: -0.223253,
      pointLongitude: -78.514107,
      pointOrder: 2,
    },
  ],
};

export const EC_DELIVEREO_LABEL_CALCULATE_RES_MOCK_OK: IEcDelivereoLabelCalculateRes = {
  title: EcDelivereoLabelApiMessage.Éxito,
  message: 'Petición exitosa',
  status: true,
  id: 0,
  code: 200,
  farePrice: 3.5,
  itemsPrice: 0,
  ivaPercentage: 15.0,
  iva: 0.53,
  totalAmount: 4.03,
  estimatedTime: '00:20:00',
  totalDistance: 0.0,
};

export const EC_DELIVEREO_LABEL_CALCULATE_RES_MOCK_NOK: IEcDelivereoLabelCalculateRes = {
  title: EcDelivereoLabelApiMessage.Fallo,
  message: 'Punto 1 de la carrera no se encuentra en una zona válida.',
  status: false,
  id: 0,
  code: 405,
  farePrice: 3.5,
  itemsPrice: null,
  ivaPercentage: null,
  iva: null,
  totalAmount: null,
  estimatedTime: null,
  totalDistance: null,
};

export const EC_DELIVEREO_LABEL_CANCEL_RES_MOCK: IEcDelivereoCancelLabelRes = {
  title: EcDelivereoLabelApiMessage.Éxito,
  message: 'Petición exitosa',
  status: true,
  id: 0,
  code: 200,
};

export const EC_DELIVEREO_LABEL_MOCK: IEcDelivereoLabel = {
  objectOwnerId: 1,
  labelReq: EC_DELIVEREO_LABEL_REQ_DB_MOCK,
  labelRes: EC_DELIVEREO_LABEL_RES_MOCK,
  registered: EC_DELIVEREO_LABEL_REGISTERED_RES_MOCK,
};
