import {AddressGroup, IAddress, findTerritoryBaseItem} from '../..';

/**
 * This number of characters originally comes from the API of CollectiveCarrierToken."ec_servientrega", that only supports a maximum of characters.
 * Also we must always check that SINGLE_LINE_ADDRESS_STREET_MAX_LENGTH is always greater OR equal
 * to the SUM of:
 *  + SINGLE_LINE_ADDRESS_EXTRA_CHARS_MAX_LENGTH
 *  + ADDRESS_STREET_LINE1_MAX_LENGTH
 *  + ADDRESS_DOOR_NUMBER_MAX_LENGTH
 *  + ADDRESS_INTERIOR_NUMBER_MAX_LENGTH
 *  + ADDRESS_REFERENCE_MAX_LENGTH
 */
export const SINGLE_LINE_ADDRESS_STREET_MAX_LENGTH = 180;

/**
 * This constant represent the number of extra characters that the function 'getSingleLineAddressStreet'
 * adds to the input parameter dto IAddress such as spaces, commas, points, parenthesis, etc.
 */
export const SINGLE_LINE_ADDRESS_EXTRA_CHARS_MAX_LENGTH = 6;

// CONST used in the front-end app in the form's max-length validators - START
export const ADDRESS_STREET_LINE1_MAX_LENGTH = 60;
export const ADDRESS_DOOR_NUMBER_MAX_LENGTH = 10;
export const ADDRESS_INTERIOR_NUMBER_MAX_LENGTH = 30;
export const ADDRESS_REFERENCE_MAX_LENGTH = 70;
//
export const ADDRESS_FIRSTNAME_MAX_LENGTH = 50;
export const ADDRESS_LASTNAME_MAX_LENGTH = 50;
export const ADDRESS_COMPANY_MAX_LENGTH = 100;
export const ADDRESS_PHONE_MAX_LENGTH = 30;
export const ADDRESS_ZIP_MAX_LENGTH = 20;
// CONST used in the front-end app in the form's max-length validators - END

/**
 *  Some carriers (Tramaco, Laar) doesn't have a field 'Company' in its address API body request (IEcTramacoAddress), we insert the company text inside the "lastname" field using this text separator.
 */
export const AddressLastnameCompanySeparator = ' // ';

export function getSingleLineAddressPersonName(address: IAddress, showCompany = true): string | null {
  if (!address) {
    return null;
  }
  if (address.firstName || address.lastName) {
    // used for natural person
    return `${address.firstName ?? ''}${address.lastName ? ' ' + address.lastName : ''}${
      address.company && showCompany ? `${AddressLastnameCompanySeparator}${address.company}` : ''
    }`;
  } else if (address.company) {
    // used for company (no person name)
    return `${address.company && showCompany ? address.company : ''}`;
  } else {
    return null;
  }
}

export function getSingleLineAddressStreet(address: IAddress): string | null {
  if (!address) {
    return null;
  }
  // IMPORTANT: When you modify this variable, you MUST recalculate the const 'SINGLE_LINE_ADDRESS_EXTRA_CHARS_MAX_LENGTH' if you added/removed extra characters (spaces, commas, etc.)
  const singleLineAddressStreet = `${address.streetLine1}${address.doorNumber ? ', ' + address.doorNumber : ''}${
    address.interiorNumber ? ', ' + address.interiorNumber : ''
  }${address.reference ? ', ' + address.reference : ''}`;

  return singleLineAddressStreet;
}

export function checkSingleLineAddressStreetMaxLength(singleLineAddressStreet: string): 'valid' | 'error' {
  if (singleLineAddressStreet.length && singleLineAddressStreet.length > SINGLE_LINE_ADDRESS_STREET_MAX_LENGTH) {
    return 'error';
  }
  return 'valid';
}

export function getSingleLineAddressCity(address: IAddress, showZip = true, showCountry = true): string | null {
  if (!address) {
    return null;
  }

  const territoryBase = findTerritoryBaseItem(address.territoryBaseId!);

  return `${territoryBase ? territoryBase.searchableText : ''}${address.zip && showZip ? ', ' + address.zip : ''}${
    address.country && showCountry ? ', ' + address.country : ''
  }`;
}

/**
 * Helper function to group a set of addresses using its AddressUniqueKey
 */
export function groupByAddress(addresses: IAddress[]): AddressGroup[] {
  // TODO p1 maybe create a new endpoint so it can be consumed via API from other clients (not only from the frontend)

  // map-reduce all the addresses with different objectID, but same of everything else (streetLine1, city...)

  const rawGroups = addresses.reduce((acc, address) => {
    const key = getAddressUniqueKey(address);
    const curGroup = acc[key] ?? [];
    return {...acc, [key]: [...curGroup, address]};
  }, {} as any);

  const parsedGroups: AddressGroup[] = [];

  Object.keys(rawGroups).forEach((key, index) => {
    parsedGroups.push({
      groupId: index,
      templateAddress: rawGroups[key][0],
      addresses: rawGroups[key],
    });
  });

  return parsedGroups;
}

/**
 * Helper function used to compare 2 different addresses and check if they are equal or not.
 * This function returns a string where it concatenates the 'relevant' fields from an address so that it can be comparable to other addresses.
 * All the fields that are not inside the AddressUniqueKey are not considered 'relevant' to check equalness to another address (for example, the 'phone' in an address is NOT relevant to check if 2 addresses are equal)
 * For example: an AddressUniqueKey could be form as this 'firstName_lastName_country_territoryBaseId_streetLine1'
 */
// TODO p4: this function might return the same UniqueKey for different addresses. This is a very unlikely edge case, but we could consider improving this AddressUniqueKey logic.
export function getAddressUniqueKey(address: IAddress): string {
  if (!address) {
    throw Error('No address to parse as AddressUniqueKey');
  }
  const addressUniqueKey: string =
    (address.firstName.toLowerCase().trim() ?? '') +
    (address.lastName.toLowerCase().trim() ?? '') +
    (address.company?.toLowerCase().trim() ?? '') +
    (address.country?.toLowerCase().trim() ?? '') +
    (address.zip?.toLowerCase().trim() ?? '') +
    (address.territoryBaseId?.toLowerCase().trim() ?? '') +
    (address.streetLine1?.toLowerCase().trim() ?? '') +
    (address.doorNumber?.toLowerCase().trim() ?? '') +
    (address.interiorNumber?.toLowerCase().trim() ?? '');
  return addressUniqueKey;
}
