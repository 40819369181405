import {toFixedAndRound} from '../../../../utils/common';
import {IEcDelivereoLabelReqApi, IEcDelivereoLabelReqDb} from './ec-delivereo-label.model';

/**
 * This function transforms the ecDelivereoReqApi object to the ecDelivereoRequestDb object
 * which is the object that is saved in the database.
 * It filters the points by pointOrder = 1 and 2, 1 for sender 2 for recipient
 * and extracts the necessary information from the order items and the points to build the ecDelivereoRequestDb object.
 * @param ecDelivereoReqApi the object that is send to the ecDelivereo api
 * @returns the ecDelivereoRequestDb object that is saved in the database
 */
export function transformDelivereoLabelReqApiToDb(ecDelivereoReqApi: IEcDelivereoLabelReqApi): IEcDelivereoLabelReqDb {
  // filter the points by pointOrder = 1 2 and 3, 1 for sender 2 for recipient 3 for COD
  const senderPoint = ecDelivereoReqApi.points.find((point) => point.pointOrder === 1);
  const recipientPoint = ecDelivereoReqApi.points.find((point) => point.pointOrder === 2);
  const returnPoint = ecDelivereoReqApi.points.find((point) => point.pointOrder === 3);

  const ecDelivereoRequestDb: IEcDelivereoLabelReqDb = {
    bookingBusinessUserEmail: ecDelivereoReqApi.bookingBusinessUserEmail,
    categoryType: ecDelivereoReqApi.categoryType,
    cityType: ecDelivereoReqApi.cityType,
    description: ecDelivereoReqApi.description,
    extraInstructions: ecDelivereoReqApi.extraInstructions,
    itemsPrice: ecDelivereoReqApi.itemsPrice,
    lang: ecDelivereoReqApi.lang,
    // convert order items to db
    orderItemDescription: ecDelivereoReqApi.order.orderItems[0].orderItemDescription!,
    orderItemGuid: ecDelivereoReqApi.order.orderItems[0].orderItemGuid!,
    orderItemName: ecDelivereoReqApi.order.orderItems[0].orderItemName!,
    orderItemPriceTotal: ecDelivereoReqApi.order.orderItems[0].orderItemPriceTotal!,
    quantity: ecDelivereoReqApi.order.orderItems[0].quantity!,
    unitIva: ecDelivereoReqApi.order.orderItems[0].unitIva,
    unitSubTotal: ecDelivereoReqApi.order.orderItems[0].unitSubTotal,
    unitTotal: ecDelivereoReqApi.order.orderItems[0].unitTotal,

    orderGuid: ecDelivereoReqApi.order.orderGuid,
    orderIva: ecDelivereoReqApi.order.orderIva,
    orderSubTotal: ecDelivereoReqApi.order.orderSubTotal,
    orderTotal: ecDelivereoReqApi.order.orderTotal,
    paymentMode: ecDelivereoReqApi.order.paymentMode,

    // sender info
    senderAddress: senderPoint?.address!,
    senderbookingPointType: senderPoint?.bookingPointType!,
    senderPhone: senderPoint?.phone!,
    senderPointLatitude: senderPoint?.pointLatitude!,
    senderPointLongitude: senderPoint?.pointLongitude!,
    senderPointOrder: senderPoint?.pointOrder!,
    senderReference: senderPoint?.reference ?? '',
    senderSenderRecipientName: senderPoint?.senderRecipientName!,

    // recipient info
    recipientAddress: recipientPoint?.address!,
    recipientBookingPointType: recipientPoint?.bookingPointType!,
    recipientPhone: recipientPoint?.phone!,
    recipientPointLatitude: recipientPoint?.pointLatitude!,
    recipientPointLongitude: recipientPoint?.pointLongitude!,
    recipientPointOrder: recipientPoint?.pointOrder!,
    recipientReference: recipientPoint?.reference ?? '',
    recipientSenderRecipientName: recipientPoint?.senderRecipientName!,

    //  in case of COD we need to add the extra address, this address is a replica of sender address
    returnAddress: returnPoint?.address,
    returnBookingPointType: returnPoint?.bookingPointType,
    returnPhone: returnPoint?.phone,
    returnPointLatitude: returnPoint?.pointLatitude,
    returnPointLongitude: returnPoint?.pointLongitude,
    returnPointOrder: returnPoint?.pointOrder,
    returnReference: returnPoint?.reference,
    returnSenderRecipientName: returnPoint?.senderRecipientName!,
  };

  return ecDelivereoRequestDb;
}

/**
 * Increments the last decimal digit of a number by 1.
 * If the number has no decimals, adds .1
 * Examples:
 * - 1 -> 1.1
 * - 1.1 -> 1.2
 * - 1.12 -> 1.13
 * @param value The number to increment
 * @returns The incremented number
 */
export function incrementLastDecimal(value: number): number {
  const valueStr = value.toString();
  const decimalIndex = valueStr.indexOf('.');

  if (decimalIndex === -1) {
    // No decimal point, add .1
    return value + 0.1;
  }

  const power = valueStr.length - decimalIndex - 1;
  return toFixedAndRound(value + Math.pow(0.1, power), 6);
}
