import {EcBaseTerritoryItem} from '../../ec-00-base/ec-base-territory.model';

export interface EcTramacoTerritoryItem extends Omit<EcBaseTerritoryItem, 'searchableText'> {
  territory1Id: string;
  territory2Id: string;
  territory3Id: string;
}

/**
 * Tramaco API's require to send the `territory3Id` as the ID of the sender/recipient's address city.
 */
export const EcTramacoTerritoryList: EcTramacoTerritoryItem[] = [
  {
    territoryBaseId: 'EC|:|AZUAY|:|CAMILO_PONCE_ENRIQUEZ|:|CAMILO_PONCE_ENRIQUEZ',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '41',
    territory2Name: 'CAMILO PONCE ENRIQUEZ',
    territory3Id: '400',
    territory3Name: 'CAMILO PONCE ENRIQUEZ',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHORDELEG|:|CHORDELEG',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '37',
    territory2Name: 'CHORDELEG',
    territory3Id: '389',
    territory3Name: 'CHORDELEG',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHORDELEG|:|LA_UNION_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '37',
    territory2Name: 'CHORDELEG',
    territory3Id: '389',
    territory3Name: 'LA UNION (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|BAÑOS_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '263',
    territory3Name: 'BAÑOS (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|BELLAVISTA_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '245',
    territory3Name: 'BELLAVISTA (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CAÑARIBAMBA',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '246',
    territory3Name: 'CAÑARIBAMBA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CAPULISPAMBA',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '1192',
    territory3Name: 'CAPULISPAMBA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CUENCA',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '284',
    territory3Name: 'CUENCA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CUMBE',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '267',
    territory3Name: 'CUMBE',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_BATAN',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '248',
    territory3Name: 'EL BATAN',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_DESCANSO_(CHAULLA)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '1096',
    territory3Name: 'EL DESCANSO (CHAULLA)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_SAGRARIO_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '249',
    territory3Name: 'EL SAGRARIO (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_VECINO',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '251',
    territory3Name: 'EL VECINO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|GIL_RAMIREZ_DAVALOS',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '252',
    territory3Name: 'GIL RAMIREZ DAVALOS',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|HERMANO_MIGUEL',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '262',
    territory3Name: 'HERMANO MIGUEL',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|HUAYNACAPAC',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '253',
    territory3Name: 'HUAYNACAPAC',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|MACHANGARA',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '255',
    territory3Name: 'MACHANGARA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|MONAY',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '256',
    territory3Name: 'MONAY',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|RACAR',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '279',
    territory3Name: 'RACAR',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|RICAURTE_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '264',
    territory3Name: 'RICAURTE (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAN_BLAS_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '257',
    territory3Name: 'SAN BLAS (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAN_JOAQUIN',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '265',
    territory3Name: 'SAN JOAQUIN',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAN_SEBASTIAN_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '258',
    territory3Name: 'SAN SEBASTIAN (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SANTA_ANA_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '277',
    territory3Name: 'SANTA ANA (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAYAUSI',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '266',
    territory3Name: 'SAYAUSI',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SUCRE_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '259',
    territory3Name: 'SUCRE (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TARQUI_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '280',
    territory3Name: 'TARQUI (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TOTORACOCHA',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '260',
    territory3Name: 'TOTORACOCHA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TURI',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '281',
    territory3Name: 'TURI',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|VALLE_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '282',
    territory3Name: 'VALLE (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|VICTORIA_DEL_PORTETE_(IRQUIS)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '1787',
    territory3Name: 'VICTORIA DEL PORTETE (IRQUIS)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|YANUNCAY',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '27',
    territory2Name: 'CUENCA',
    territory3Id: '261',
    territory3Name: 'YANUNCAY',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|EL_PAN|:|EL_PAN_(AZ)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '38',
    territory2Name: 'EL PAN',
    territory3Id: '394',
    territory3Name: 'EL PAN (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GIRON|:|GIRON',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '28',
    territory2Name: 'GIRON',
    territory3Id: '286',
    territory3Name: 'GIRON',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GUACHAPALA|:|GUACHAPALA',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '40',
    territory2Name: 'GUACHAPALA',
    territory3Id: '399',
    territory3Name: 'GUACHAPALA',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GUALACEO|:|GUALACEO',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '29',
    territory2Name: 'GUALACEO',
    territory3Id: '288',
    territory3Name: 'GUALACEO',
  },
  // TODO p1: temporal suspension of cuidad without parroquia, that generates error on the PDF generation (but success in label generation)
  // {
  //   territoryBaseId: 'EC|:|AZUAY|:|NABON|:|NABON',
  //   territory1Id: '3',
  //   territory1Name: 'AZUAY',
  //   territory2Id: '30',
  //   territory2Name: 'NABON',
  //   territory3Id: '30',
  //   territory3Name: 'NABON',
  // },
  // {
  //   territoryBaseId: 'EC|:|AZUAY|:|ONA|:|ONA',
  //   territory1Id: '3',
  //   territory1Name: 'AZUAY',
  //   territory2Id: '36',
  //   territory2Name: 'ONA',
  //   territory3Id: '36',
  //   territory3Name: 'ONA',
  // },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|BULAN_(JOSE_VICTOR_IZQUIERDO)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '31',
    territory2Name: 'PAUTE',
    territory3Id: '1802',
    territory3Name: 'BULAN (JOSE VICTOR IZQUIERDO)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|CHICAN_(GUILLERMO_ORTEGA)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '31',
    territory2Name: 'PAUTE',
    territory3Id: '1803',
    territory3Name: 'CHICAN (GUILLERMO ORTEGA)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|EL_CABO',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '31',
    territory2Name: 'PAUTE',
    territory3Id: '332',
    territory3Name: 'EL CABO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|PAUTE',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '31',
    territory2Name: 'PAUTE',
    territory3Id: '324',
    territory3Name: 'PAUTE',
  },
  // {
  //   territoryBaseId: 'EC|:|AZUAY|:|PUCARA|:|PUCARA',
  //   territory1Id: '3',
  //   territory1Name: 'AZUAY',
  //   territory2Id: '32',
  //   territory2Name: 'PUCARA',
  //   territory3Id: '32',
  //   territory3Name: 'PUCARA',
  // },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SAN_FERNANDO|:|SAN_FERNANDO',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '33',
    territory2Name: 'SAN FERNANDO',
    territory3Id: '349',
    territory3Name: 'SAN FERNANDO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SANTA_ISABEL|:|SANTA_ISABEL_(CHAGUARURCO)',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '34',
    territory2Name: 'SANTA ISABEL',
    territory3Id: '353',
    territory3Name: 'SANTA ISABEL (CHAGUARURCO)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SEVILLA_DE_ORO|:|SEVILLA_DE_ORO',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '39',
    territory2Name: 'SEVILLA DE ORO',
    territory3Id: '396',
    territory3Name: 'SEVILLA DE ORO',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIG_SIG|:|SAN_BARTOLOME',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '35',
    territory2Name: 'SIG SIG',
    territory3Id: '1819',
    territory3Name: 'SAN BARTOLOME',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIG_SIG|:|SIGSIG',
    territory1Id: '3',
    territory1Name: 'AZUAY',
    territory2Id: '35',
    territory2Name: 'SIG SIG',
    territory3Id: '362',
    territory3Name: 'SIGSIG',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CALUMA|:|CALUMA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '47',
    territory2Name: 'CALUMA',
    territory3Id: '433',
    territory3Name: 'CALUMA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHILLANES|:|CHILLANES',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '43',
    territory2Name: 'CHILLANES',
    territory3Id: '1082',
    territory3Name: 'CHILLANES',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHILLANES|:|PISAGUA_ALTO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '43',
    territory2Name: 'CHILLANES',
    territory3Id: '1082',
    territory3Name: 'PISAGUA ALTO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHILLANES|:|PISAGUA_BAJO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '43',
    territory2Name: 'CHILLANES',
    territory3Id: '1082',
    territory3Name: 'PISAGUA BAJO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|ASUNCION_(ASANCOTO)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '44',
    territory2Name: 'CHIMBO',
    territory3Id: '1830',
    territory3Name: 'ASUNCION (ASANCOTO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|CHIMBO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '44',
    territory2Name: 'CHIMBO',
    territory3Id: '418',
    territory3Name: 'CHIMBO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|MAGDALENA_(CHAPACOTO)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '44',
    territory2Name: 'CHIMBO',
    territory3Id: '1831',
    territory3Name: 'MAGDALENA (CHAPACOTO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|SAN_JOSE_DE_CHIMBO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '44',
    territory2Name: 'CHIMBO',
    territory3Id: '418',
    territory3Name: 'SAN JOSE DE CHIMBO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|ECHEANDIA|:|ECHEANDIA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '45',
    territory2Name: 'ECHEANDIA',
    territory3Id: '423',
    territory3Name: 'ECHEANDIA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|1ERO_DE_MAYO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '404',
    territory3Name: '1ERO DE MAYO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|4_ESQUINAS',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '404',
    territory3Name: '4 ESQUINAS',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|ANGEL_POLIBIO_CHAVES',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '409',
    territory3Name: 'ANGEL POLIBIO CHAVES',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GABRIEL_IGNACIO_VEINTIMILLA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '410',
    territory3Name: 'GABRIEL IGNACIO VEINTIMILLA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GUANUJO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '405',
    territory3Name: 'GUANUJO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GUARANDA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '404',
    territory3Name: 'GUARANDA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|JULIO_E._MORENO_(CATANAHUAN_GRANDE)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '412',
    territory3Name: 'JULIO E. MORENO (CATANAHUAN GRANDE)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SALINAS_(BO)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '406',
    territory3Name: 'SALINAS (BO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SAN_LORENZO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '413',
    territory3Name: 'SAN LORENZO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SAN_SIMON_(YACOTO)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '407',
    territory3Name: 'SAN SIMON (YACOTO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SANTA_FE_(BO)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '408',
    territory3Name: 'SANTA FE (BO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|VINCHOA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '42',
    territory2Name: 'GUARANDA',
    territory3Id: '404',
    territory3Name: 'VINCHOA',
  },
  // {
  //   territoryBaseId: 'EC|:|BOLIVAR|:|LAS_NAVES|:|LAS_NAVES',
  //   territory1Id: '4',
  //   territory1Name: 'BOLIVAR',
  //   territory2Id: '48',
  //   territory2Name: 'LAS NAVES',
  //   territory3Id: '48',
  //   territory3Name: 'LAS NAVES',
  // },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|RECINTO_24_DE_MAYO|:|RECINTO_24_DE_MAYO',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '',
    territory2Name: 'RECINTO 24 DE MAYO',
    territory3Id: '404',
    territory3Name: 'RECINTO 24 DE MAYO',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|RECINTO_EL_PALMAR|:|RECINTO_EL_PALMAR',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '',
    territory2Name: 'RECINTO EL PALMAR',
    territory3Id: '404',
    territory3Name: 'RECINTO EL PALMAR',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|RECINTO_LA_MARITZA|:|RECINTO_LA_MARITZA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '',
    territory2Name: 'RECINTO LA MARITZA',
    territory3Id: '404',
    territory3Name: 'RECINTO LA MARITZA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL|:|BALZAPAMBA',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '46',
    territory2Name: 'SAN MIGUEL',
    territory3Id: '424',
    territory3Name: 'BALZAPAMBA',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL|:|SAN_MIGUEL_(BO)',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '46',
    territory2Name: 'SAN MIGUEL',
    territory3Id: '424',
    territory3Name: 'SAN MIGUEL (BO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL|:|SAN_PABLO_DE_ATENAS',
    territory1Id: '4',
    territory1Name: 'BOLIVAR',
    territory2Id: '46',
    territory2Name: 'SAN MIGUEL',
    territory3Id: '424',
    territory3Name: 'SAN PABLO DE ATENAS',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|AURELIO_BAYAS',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '441',
    territory3Name: 'AURELIO BAYAS',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|AZOGUES',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '430',
    territory3Name: 'AZOGUES',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|BORRERO',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '432',
    territory3Name: 'BORRERO',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|COJITAMBO',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '446',
    territory3Name: 'COJITAMBO',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|GUAPAN',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '434',
    territory3Name: 'GUAPAN',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|JAVIER_LOYOLA_(CHUQUIPATA)',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '437',
    territory3Name: 'JAVIER LOYOLA (CHUQUIPATA)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|LUIS_CORDERO',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '438',
    territory3Name: 'LUIS CORDERO',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|SAN_FRANCISCO_(CÑ)',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '49',
    territory2Name: 'AZOGUES',
    territory3Id: '444',
    territory3Name: 'SAN FRANCISCO (CÑ)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|BIBLIAN|:|BIBLIAN',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '50',
    territory2Name: 'BIBLIAN',
    territory3Id: '457',
    territory3Name: 'BIBLIAN',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|BIBLIAN|:|NAZON_(CAB._EN_PAMPA_DE_DOMINGUEZ)',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '50',
    territory2Name: 'BIBLIAN',
    territory3Id: '1847',
    territory3Name: 'NAZON (CAB. EN PAMPA DE DOMINGUEZ)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|CANAR',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '51',
    territory2Name: 'CANAR',
    territory3Id: '458',
    territory3Name: 'CANAR',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|DUCUR',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '51',
    territory2Name: 'CANAR',
    territory3Id: '956',
    territory3Name: 'DUCUR',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|INGAPIRCA',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '51',
    territory2Name: 'CANAR',
    territory3Id: '1856',
    territory3Name: 'INGAPIRCA',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|ZHUD',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '51',
    territory2Name: 'CANAR',
    territory3Id: '1858',
    territory3Name: 'ZHUD',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|DELEG|:|DELEG',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '54',
    territory2Name: 'DELEG',
    territory3Id: '472',
    territory3Name: 'DELEG',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|EL_TAMBO|:|EL_TAMBO_(AZ)',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '53',
    territory2Name: 'EL TAMBO',
    territory3Id: '468',
    territory3Name: 'EL TAMBO (AZ)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|COCHANCAY',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '52',
    territory2Name: 'LA TRONCAL',
    territory3Id: '460',
    territory3Name: 'COCHANCAY',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|LA_PUNTILLA',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '52',
    territory2Name: 'LA TRONCAL',
    territory3Id: '460',
    territory3Name: 'LA PUNTILLA',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|LA_TRONCAL',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '52',
    territory2Name: 'LA TRONCAL',
    territory3Id: '460',
    territory3Name: 'LA TRONCAL',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|MANUEL_J._CALLE',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '52',
    territory2Name: 'LA TRONCAL',
    territory3Id: '462',
    territory3Name: 'MANUEL J. CALLE',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|VOLUNTAD_DE_DIOS',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '52',
    territory2Name: 'LA TRONCAL',
    territory3Id: '460',
    territory3Name: 'VOLUNTAD DE DIOS',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|SUSCAL|:|SUSCAL',
    territory1Id: '5',
    territory1Name: 'CANAR',
    territory2Id: '55',
    territory2Name: 'SUSCAL',
    territory3Id: '1175',
    territory3Name: 'SUSCAL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|BOLIVAR_(CA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '57',
    territory2Name: 'BOLIVAR',
    territory3Id: '461',
    territory3Name: 'BOLIVAR (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|CUESACA',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '57',
    territory2Name: 'BOLIVAR',
    territory3Id: '461',
    territory3Name: 'CUESACA',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|GARCIA_MORENO',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '57',
    territory2Name: 'BOLIVAR',
    territory3Id: '1868',
    territory3Name: 'GARCIA MORENO',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|LOS_ANDES',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '57',
    territory2Name: 'BOLIVAR',
    territory3Id: '1869',
    territory3Name: 'LOS ANDES',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|27_DE_SEPTIEMBRE',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '58',
    territory2Name: 'ESPEJO',
    territory3Id: '466',
    territory3Name: '27 DE SEPTIEMBRE',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|EL_ANGEL',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '58',
    territory2Name: 'ESPEJO',
    territory3Id: '465',
    territory3Name: 'EL ANGEL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|LA_LIBERTAD_(CA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '58',
    territory2Name: 'ESPEJO',
    territory3Id: '467',
    territory3Name: 'LA LIBERTAD (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|SAN_ISIDRO',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '58',
    territory2Name: 'ESPEJO',
    territory3Id: '470',
    territory3Name: 'SAN ISIDRO',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|MIRA|:|MIRA',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '59',
    territory2Name: 'MIRA',
    territory3Id: '473',
    territory3Name: 'MIRA',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|CHITAN_DE_NAVARRETE',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '493',
    territory3Name: 'CHITAN DE NAVARRETE',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|CRISTOBAL_COLON_(CA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '486',
    territory3Name: 'CRISTOBAL COLON (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|GONZALEZ_SUAREZ_(CA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '490',
    territory3Name: 'GONZALEZ SUAREZ (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|LA_PAZ',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '489',
    territory3Name: 'LA PAZ',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|PIARTAL',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '1876',
    territory3Name: 'PIARTAL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|SAN_GABRIEL',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '481',
    territory3Name: 'SAN GABRIEL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|SAN_JOSE',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '492',
    territory3Name: 'SAN JOSE',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|SANDIAL',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '60',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Id: '481',
    territory3Name: 'SANDIAL',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_PEDRO_DE_HUACA|:|HUACA',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '61',
    territory2Name: 'SAN PEDRO DE HUACA',
    territory3Id: '503',
    territory3Name: 'HUACA',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_PEDRO_DE_HUACA|:|MARISCAL_SUCRE_(CA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '61',
    territory2Name: 'SAN PEDRO DE HUACA',
    territory3Id: '504',
    territory3Name: 'MARISCAL SUCRE (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|GONZALEZ_SUAREZ_(CA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '56',
    territory2Name: 'TULCAN',
    territory3Id: '442',
    territory3Name: 'GONZALEZ SUAREZ (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|JULIO_ANDRADE_(OREJUELA)',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '56',
    territory2Name: 'TULCAN',
    territory3Id: '440',
    territory3Name: 'JULIO ANDRADE (OREJUELA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|PIOTER',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '56',
    territory2Name: 'TULCAN',
    territory3Id: '439',
    territory3Name: 'PIOTER',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|SANTA_MARTHA_DE_CUBA',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '56',
    territory2Name: 'TULCAN',
    territory3Id: '456',
    territory3Name: 'SANTA MARTHA DE CUBA',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|TULCAN',
    territory1Id: '6',
    territory1Name: 'CARCHI',
    territory2Id: '56',
    territory2Name: 'TULCAN',
    territory3Id: '439',
    territory3Name: 'TULCAN',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|ALAUSI|:|ALAUSI',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '70',
    territory2Name: 'ALAUSI',
    territory3Id: '533',
    territory3Name: 'ALAUSI',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CHAMBO|:|CHAMBO',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '72',
    territory2Name: 'CHAMBO',
    territory3Id: '552',
    territory3Name: 'CHAMBO',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CHUNCHI|:|CHUNCHI',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '73',
    territory2Name: 'CHUNCHI',
    territory3Id: '554',
    territory3Name: 'CHUNCHI',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|COLTA|:|CAJABAMBA',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '71',
    territory2Name: 'COLTA',
    territory3Id: '548',
    territory3Name: 'CAJABAMBA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|COLTA|:|COLTA',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '71',
    territory2Name: 'COLTA',
    territory3Id: '547',
    territory3Name: 'COLTA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CUMANDA|:|CUMANDA',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '78',
    territory2Name: 'CUMANDA',
    territory3Id: '589',
    territory3Name: 'CUMANDA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUAMOTE|:|GUAMOTE',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '74',
    territory2Name: 'GUAMOTE',
    territory3Id: '558',
    territory3Name: 'GUAMOTE',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|EL_ROSARIO_(GUANO)_(CH)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '75',
    territory2Name: 'GUANO',
    territory3Id: '1923',
    territory3Name: 'EL ROSARIO (GUANO) (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|GUANO_(CH)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '75',
    territory2Name: 'GUANO',
    territory3Id: '565',
    territory3Name: 'GUANO (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|LA_MATRIZ_(GUANO)_(CH)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '75',
    territory2Name: 'GUANO',
    territory3Id: '1924',
    territory3Name: 'LA MATRIZ (GUANO) (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|SAN_ANDRES_(CHI)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '75',
    territory2Name: 'GUANO',
    territory3Id: '571',
    territory3Name: 'SAN ANDRES (CHI)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|PALLATANGA|:|PALLATANGA',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '76',
    territory2Name: 'PALLATANGA',
    territory3Id: '578',
    territory3Name: 'PALLATANGA',
  },
  // {
  //   territoryBaseId: 'EC|:|CHIMBORAZO|:|PENIPE|:|PENIPE',
  //   territory1Id: '8',
  //   territory1Name: 'CHIMBORAZO',
  //   territory2Id: '77',
  //   territory2Name: 'PENIPE',
  //   territory3Id: '77',
  //   territory3Name: 'PENIPE',
  // },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|LICAN',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '516',
    territory3Name: 'LICAN',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|LIZARZABURU',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '523',
    territory3Name: 'LIZARZABURU',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|MALDONADO_(CH)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '525',
    territory3Name: 'MALDONADO (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|RIOBAMBA',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '515',
    territory3Name: 'RIOBAMBA',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|SAN_JUAN_CHICO_(CH)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '532',
    territory3Name: 'SAN JUAN CHICO (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|SAN_LUIS_(CH)',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '519',
    territory3Name: 'SAN LUIS (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|VELASCO',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '527',
    territory3Name: 'VELASCO',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|VELOZ',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '529',
    territory3Name: 'VELOZ',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|YARUQUIES',
    territory1Id: '8',
    territory1Name: 'CHIMBORAZO',
    territory2Id: '69',
    territory2Name: 'RIOBAMBA',
    territory3Id: '530',
    territory3Name: 'YARUQUIES',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LA_MANA|:|LA_MANA_(LR)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '63',
    territory2Name: 'LA MANA',
    territory3Id: '1885',
    territory3Name: 'LA MANA (LR)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LA_MANA|:|SAN_MARCOS',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '63',
    territory2Name: 'LA MANA',
    territory3Id: '1885',
    territory3Name: 'SAN MARCOS',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|ALAQUEZ',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2405',
    territory2Name: 'LATACUNGA',
    territory3Id: '2425',
    territory3Name: 'ALAQUEZ',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|BELISARIO_QUEVEDO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2406',
    territory2Name: 'LATACUNGA',
    territory3Id: '2426',
    territory3Name: 'BELISARIO QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|ELOY_ALFARO__(SAN_FELIPE)_(CO)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '62',
    territory2Name: 'LATACUNGA',
    territory3Id: '482',
    territory3Name: 'ELOY ALFARO  (SAN FELIPE) (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|GUAYTACAMA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2407',
    territory2Name: 'LATACUNGA',
    territory3Id: '2427',
    territory3Name: 'GUAYTACAMA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|IGNACIO_FLORES_(PARQUE_FLORES)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '62',
    territory2Name: 'LATACUNGA',
    territory3Id: '485',
    territory3Name: 'IGNACIO FLORES (PARQUE FLORES)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|JUAN_MONTALVO_(SAN_SEBASTIAN)_(CO)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '62',
    territory2Name: 'LATACUNGA',
    territory3Id: '487',
    territory3Name: 'JUAN MONTALVO (SAN SEBASTIAN) (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LA_MATRIZ_(CO)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '62',
    territory2Name: 'LATACUNGA',
    territory3Id: '488',
    territory3Name: 'LA MATRIZ (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LA_VICTORIA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2405',
    territory2Name: 'LATACUNGA',
    territory3Id: '476',
    territory3Name: 'LA VICTORIA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LASSO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2453',
    territory2Name: 'LASSO (CO)',
    territory3Id: '2454',
    territory3Name: 'LASSO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LATACUNGA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '62',
    territory2Name: 'LATACUNGA',
    territory3Id: '476',
    territory3Name: 'LATACUNGA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|MULALAO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2405',
    territory2Name: 'LATACUNGA',
    territory3Id: '476',
    territory3Name: 'MULALAO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|PATUTAN',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2410',
    territory2Name: 'LATACUNGA',
    territory3Id: '2430',
    territory3Name: 'PATUTAN',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|POALO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2405',
    territory2Name: 'LATACUNGA',
    territory3Id: '476',
    territory3Name: 'POALO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|RUMIPAMBA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2405',
    territory2Name: 'LATACUNGA',
    territory3Id: '476',
    territory3Name: 'RUMIPAMBA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|SAN_BUENAVENTURA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '62',
    territory2Name: 'LATACUNGA',
    territory3Id: '491',
    territory3Name: 'SAN BUENAVENTURA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|SAN_JUAN_DE_PASTOCALLE',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2408',
    territory2Name: 'LATACUNGA',
    territory3Id: '2428',
    territory3Name: 'SAN JUAN DE PASTOCALLE',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|TANICUCHI',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2409',
    territory2Name: 'LATACUNGA',
    territory3Id: '2429',
    territory3Name: 'TANICUCHI',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|TOACASO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '2405',
    territory2Name: 'LATACUNGA',
    territory3Id: '476',
    territory3Name: 'TOACASO',
  },
  // {
  //   territoryBaseId: 'EC|:|COTOPAXI|:|PANGUA|:|EL_CORAZON',
  //   territory1Id: '7',
  //   territory1Name: 'COTOPAXI',
  //   territory2Id: '64',
  //   territory2Name: 'PANGUA',
  //   territory3Id: '64',
  //   territory3Name: 'EL CORAZON',
  // },
  // {
  //   territoryBaseId: 'EC|:|COTOPAXI|:|PANGUA|:|MORASPUNGO',
  //   territory1Id: '7',
  //   territory1Name: 'COTOPAXI',
  //   territory2Id: '64',
  //   territory2Name: 'PANGUA',
  //   territory3Id: '64',
  //   territory3Name: 'MORASPUNGO',
  // },
  // {
  //   territoryBaseId: 'EC|:|COTOPAXI|:|PANGUA|:|PANGUA',
  //   territory1Id: '7',
  //   territory1Name: 'COTOPAXI',
  //   territory2Id: '64',
  //   territory2Name: 'PANGUA',
  //   territory3Id: '64',
  //   territory3Name: 'PANGUA',
  // },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PUJILI|:|PUJILI',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '65',
    territory2Name: 'PUJILI',
    territory3Id: '502',
    territory3Name: 'PUJILI',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PUJILI|:|ZUMBAHUA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '65',
    territory2Name: 'PUJILI',
    territory3Id: '502',
    territory3Name: 'ZUMBAHUA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|ANCHILIVI',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '1190',
    territory3Name: 'ANCHILIVI',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|ANTONIO_JOSE_HOLGUIN_(SANTA_LUCIA)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '506',
    territory3Name: 'ANTONIO JOSE HOLGUIN (SANTA LUCIA)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|CHIPUALO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '957',
    territory3Name: 'CHIPUALO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|CUMCHIBAMBA',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '1184',
    territory3Name: 'CUMCHIBAMBA',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|MULALILLO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '510',
    territory3Name: 'MULALILLO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|MULLIQUINDIL_(SANTA_ANA)(CO)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '511',
    territory3Name: 'MULLIQUINDIL (SANTA ANA)(CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|PANZALEO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '512',
    territory3Name: 'PANZALEO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|PATAIN',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '1189',
    territory3Name: 'PATAIN',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SALACHE',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '1136',
    territory3Name: 'SALACHE',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SALCEDO',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '957',
    territory3Name: 'SALCEDO',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SAN_MIGUEL_(CO)',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '513',
    territory3Name: 'SAN MIGUEL (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|YANAYACU',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '66',
    territory2Name: 'SALCEDO',
    territory3Id: '1182',
    territory3Name: 'YANAYACU',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SAQUISILI|:|SAQUISILI',
    territory1Id: '7',
    territory1Name: 'COTOPAXI',
    territory2Id: '67',
    territory2Name: 'SAQUISILI',
    territory3Id: '517',
    territory3Name: 'SAQUISILI',
  },
  // {
  //   territoryBaseId: 'EC|:|COTOPAXI|:|SIGCHOS|:|SIGCHOS',
  //   territory1Id: '7',
  //   territory1Name: 'COTOPAXI',
  //   territory2Id: '68',
  //   territory2Name: 'SIGCHOS',
  //   territory3Id: '68',
  //   territory3Name: 'SIGCHOS',
  // },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ARENILLAS|:|ARENILLAS',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '80',
    territory2Name: 'ARENILLAS',
    territory3Id: '544',
    territory3Name: 'ARENILLAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ARENILLAS|:|SAN_VICENTE_DEL_JOBO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '80',
    territory2Name: 'ARENILLAS',
    territory3Id: '544',
    territory3Name: 'SAN VICENTE DEL JOBO',
  },
  // {
  //   territoryBaseId: 'EC|:|EL_ORO|:|ATAHUALPA|:|ATAHUALPA',
  //   territory1Id: '9',
  //   territory1Name: 'EL ORO',
  //   territory2Id: '81',
  //   territory2Name: 'ATAHUALPA',
  //   territory3Id: '81',
  //   territory3Name: 'ATAHUALPA',
  // },
  // {
  //   territoryBaseId: 'EC|:|EL_ORO|:|ATAHUALPA|:|PACCHA',
  //   territory1Id: '9',
  //   territory1Name: 'EL ORO',
  //   territory2Id: '81',
  //   territory2Name: 'ATAHUALPA',
  //   territory3Id: '81',
  //   territory3Name: 'PACCHA',
  // },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|BALSAS|:|BALSAS',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '82',
    territory2Name: 'BALSAS',
    territory3Id: '556',
    territory3Name: 'BALSAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|BALSAS|:|BELLAMARIA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '82',
    territory2Name: 'BALSAS',
    territory3Id: '557',
    territory3Name: 'BELLAMARIA',
  },
  // {
  //   territoryBaseId: 'EC|:|EL_ORO|:|CHILLA|:|CHILLA',
  //   territory1Id: '9',
  //   territory1Name: 'EL ORO',
  //   territory2Id: '83',
  //   territory2Name: 'CHILLA',
  //   territory3Id: '83',
  //   territory3Name: 'CHILLA',
  // },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|BAJO_ALTO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '84',
    territory2Name: 'EL GUABO',
    territory3Id: '559',
    territory3Name: 'BAJO ALTO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|BARBONES',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '84',
    territory2Name: 'EL GUABO',
    territory3Id: '559',
    territory3Name: 'BARBONES',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|EL_GUABO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '84',
    territory2Name: 'EL GUABO',
    territory3Id: '559',
    territory3Name: 'EL GUABO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|LA_IBERIA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '84',
    territory2Name: 'EL GUABO',
    territory3Id: '563',
    territory3Name: 'LA IBERIA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|RIO_BONITO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '84',
    territory2Name: 'EL GUABO',
    territory3Id: '559',
    territory3Name: 'RIO BONITO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|ECUADOR',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '85',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '573',
    territory3Name: 'ECUADOR',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|EL_PARAISO_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '85',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '576',
    territory3Name: 'EL PARAISO (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|HUALTACO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '85',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '577',
    territory3Name: 'HUALTACO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|HUAQUILLAS',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '85',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '568',
    territory3Name: 'HUAQUILLAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|MILTON_REYES',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '85',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '581',
    territory3Name: 'MILTON REYES',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|UNION_LOJANA_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '85',
    territory2Name: 'HUAQUILLAS',
    territory3Id: '583',
    territory3Name: 'UNION LOJANA (EO)',
  },
  // {
  //   territoryBaseId: 'EC|:|EL_ORO|:|LAS_LAJAS|:|LAS_LAJAS',
  //   territory1Id: '9',
  //   territory1Name: 'EL ORO',
  //   territory2Id: '92',
  //   territory2Name: 'LAS LAJAS',
  //   territory3Id: '92',
  //   territory3Name: 'LAS LAJAS',
  // },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|EL_CAMBIO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '541',
    territory3Name: 'EL CAMBIO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|EL_PORTON',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '520',
    territory3Name: 'EL PORTON',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|EL_RETIRO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '542',
    territory3Name: 'EL RETIRO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|JUBONES',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '536',
    territory3Name: 'JUBONES',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|LA_PROVIDENCIA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '524',
    territory3Name: 'LA PROVIDENCIA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|MACHALA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '520',
    territory3Name: 'MACHALA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|NUEVE_DE_MAYO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '528',
    territory3Name: 'NUEVE DE MAYO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|PUERTO_BOLIVAR',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '79',
    territory2Name: 'MACHALA',
    territory3Id: '526',
    territory3Name: 'PUERTO BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MARCABELI|:|MARCABELI',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '86',
    territory2Name: 'MARCABELI',
    territory3Id: '585',
    territory3Name: 'MARCABELI',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|BOLIVAR_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '1947',
    territory3Name: 'BOLIVAR (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|BUENAVISTA_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '591',
    territory3Name: 'BUENAVISTA (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|CANA_QUEMADA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '590',
    territory3Name: 'CANA QUEMADA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|LA_PEAÑA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '595',
    territory3Name: 'LA PEAÑA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|LA_VICTORIA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '590',
    territory3Name: 'LA VICTORIA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|LOMA_DE_FRANCO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '1948',
    territory3Name: 'LOMA DE FRANCO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|OCHOA_LEON_(MATRIZ)_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '597',
    territory3Name: 'OCHOA LEON (MATRIZ) (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|PASAJE',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '590',
    territory3Name: 'PASAJE',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|PROGRESO_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '1951',
    territory3Name: 'PROGRESO (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|TRES_CERRITOS',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '87',
    territory2Name: 'PASAJE',
    territory3Id: '1949',
    territory3Name: 'TRES CERRITOS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|EL_PACHE',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '88',
    territory2Name: 'PINAS',
    territory3Id: '606',
    territory3Name: 'EL PACHE',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|LA_MATRIZ_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '88',
    territory2Name: 'PINAS',
    territory3Id: '601',
    territory3Name: 'LA MATRIZ (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|LA_SUSAYA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '88',
    territory2Name: 'PINAS',
    territory3Id: '602',
    territory3Name: 'LA SUSAYA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|PIÑAS',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '88',
    territory2Name: 'PINAS',
    territory3Id: '606',
    territory3Name: 'PIÑAS',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|SARACAY',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '88',
    territory2Name: 'PINAS',
    territory3Id: '624',
    territory3Name: 'SARACAY',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PORTOVELO|:|PORTOVELO',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '89',
    territory2Name: 'PORTOVELO',
    territory3Id: '627',
    territory3Name: 'PORTOVELO',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|BELLAVISTA_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '90',
    territory2Name: 'SANTA ROSA',
    territory3Id: '642',
    territory3Name: 'BELLAVISTA (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|JAMBELI',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '90',
    territory2Name: 'SANTA ROSA',
    territory3Id: '522',
    territory3Name: 'JAMBELI',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|LA_AVANZADA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '90',
    territory2Name: 'SANTA ROSA',
    territory3Id: '640',
    territory3Name: 'LA AVANZADA',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|NUEVO_SANTA_ROSA_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '90',
    territory2Name: 'SANTA ROSA',
    territory3Id: '1957',
    territory3Name: 'NUEVO SANTA ROSA (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|PUERTO_JELI',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '90',
    territory2Name: 'SANTA ROSA',
    territory3Id: '1954',
    territory3Name: 'PUERTO JELI',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|SANTA_ROSA_(EO)',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '90',
    territory2Name: 'SANTA ROSA',
    territory3Id: '638',
    territory3Name: 'SANTA ROSA (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SHUMIRAL|:|SHUMIRAL',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '',
    territory2Name: 'SHUMIRAL',
    territory3Id: '400',
    territory3Name: 'SHUMIRAL',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ZARUMA|:|ZARUMA',
    territory1Id: '9',
    territory1Name: 'EL ORO',
    territory2Id: '91',
    territory2Name: 'ZARUMA',
    territory3Id: '643',
    territory3Name: 'ZARUMA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|ATACAMES',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '98',
    territory2Name: 'ATACAMES',
    territory3Id: '668',
    territory3Name: 'ATACAMES',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|MOMPICHE',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '2411',
    territory2Name: 'ATACAMES',
    territory3Id: '2431',
    territory3Name: 'MOMPICHE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|SAME',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '98',
    territory2Name: 'ATACAMES',
    territory3Id: '2363',
    territory3Name: 'SAME',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|SUA',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '98',
    territory2Name: 'ATACAMES',
    territory3Id: '672',
    territory3Name: 'SUA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|TONCHIGUE',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '98',
    territory2Name: 'ATACAMES',
    territory3Id: '1055',
    territory3Name: 'TONCHIGUE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|TONSUPA',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '98',
    territory2Name: 'ATACAMES',
    territory3Id: '676',
    territory3Name: 'TONSUPA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ELOY_ALFARO|:|BORBON',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '94',
    territory2Name: 'ELOY ALFARO',
    territory3Id: '1139',
    territory3Name: 'BORBON',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ELOY_ALFARO|:|LAS_PENAS',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '94',
    territory2Name: 'ELOY ALFARO',
    territory3Id: '1139',
    territory3Name: 'LAS PENAS',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|5_DE_AGOSTO',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '569',
    territory3Name: '5 DE AGOSTO',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|BARTOLOME_RUIZ_(CESAR_FRANCO_CARRION)',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '564',
    territory3Name: 'BARTOLOME RUIZ (CESAR FRANCO CARRION)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|ESMERALDAS',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '560',
    territory3Name: 'ESMERALDAS',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|JUDICIALES',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '1105',
    territory3Name: 'JUDICIALES',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|LUIS_TELLO__(LAS_PALMAS)',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '572',
    territory3Name: 'LUIS TELLO  (LAS PALMAS)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|SAN_MATEO',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '1104',
    territory3Name: 'SAN MATEO',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|SIMON_PLATA_TORRES',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '1975',
    territory3Name: 'SIMON PLATA TORRES',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|TACHINA',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '561',
    territory3Name: 'TACHINA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|VUELTA_LARGA',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '93',
    territory2Name: 'ESMERALDAS',
    territory3Id: '580',
    territory3Name: 'VUELTA LARGA',
  },
  // {
  //   territoryBaseId: 'EC|:|ESMERALDAS|:|MUISNE|:|MUISNE',
  //   territory1Id: '10',
  //   territory1Name: 'ESMERALDAS',
  //   territory2Id: '95',
  //   territory2Name: 'MUISNE',
  //   territory3Id: '95',
  //   territory3Name: 'MUISNE',
  // },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|LA_INDEPENDENCIA',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '96',
    territory2Name: 'QUININDE',
    territory3Id: '1176',
    territory3Name: 'LA INDEPENDENCIA',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|QUININDE',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '96',
    territory2Name: 'QUININDE',
    territory3Id: '614',
    territory3Name: 'QUININDE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|ROSA_ZARATE',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '96',
    territory2Name: 'QUININDE',
    territory3Id: '604',
    territory3Name: 'ROSA ZARATE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|VICHE',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '2412',
    territory2Name: 'QUININDE',
    territory3Id: '2432',
    territory3Name: 'VICHE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|LAGARTO',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '99',
    territory2Name: 'RIO VERDE',
    territory3Id: '2016',
    territory3Name: 'LAGARTO',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|MONTALVO_(CAB_EN_HORQUETA)',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '99',
    territory2Name: 'RIO VERDE',
    territory3Id: '2017',
    territory3Name: 'MONTALVO (CAB EN HORQUETA)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|RIO_VERDE_(ES)',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '99',
    territory2Name: 'RIO VERDE',
    territory3Id: '2013',
    territory3Name: 'RIO VERDE (ES)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|ROCAFUERTE',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '99',
    territory2Name: 'RIO VERDE',
    territory3Id: '2018',
    territory3Name: 'ROCAFUERTE',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|SAN_LORENZO|:|LA_Y_DE_CALDERON',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '97',
    territory2Name: 'SAN LORENZO',
    territory3Id: '1138',
    territory3Name: 'LA Y DE CALDERON',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|SAN_LORENZO|:|SAN_LORENZO',
    territory1Id: '10',
    territory1Name: 'ESMERALDAS',
    territory2Id: '97',
    territory2Name: 'SAN LORENZO',
    territory3Id: '1138',
    territory3Name: 'SAN LORENZO',
  },
  // {
  //   territoryBaseId: 'EC|:|GALAPAGOS|:|ISABELA|:|ISABELA',
  //   territory1Id: '22',
  //   territory1Name: 'GALAPAGOS',
  //   territory2Id: '223',
  //   territory2Name: 'ISABELA',
  //   territory3Id: '223',
  //   territory3Name: 'ISABELA',
  // },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SAN_CRISTOBAL_(PTO._BAQ._MORENO)|:|EL_PROGRESO_(GA)',
    territory1Id: '22',
    territory1Name: 'GALAPAGOS',
    territory2Id: '222',
    territory2Name: 'SAN CRISTOBAL (PTO. BAQ. MORENO)',
    territory3Id: '1144',
    territory3Name: 'EL PROGRESO (GA)',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SAN_CRISTOBAL_(PTO._BAQ._MORENO)|:|PUERTO_BAQUERIZO_MORENO',
    territory1Id: '22',
    territory1Name: 'GALAPAGOS',
    territory2Id: '222',
    territory2Name: 'SAN CRISTOBAL (PTO. BAQ. MORENO)',
    territory3Id: '891',
    territory3Name: 'PUERTO BAQUERIZO MORENO',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SANTA_CRUZ_(PTO._AYORA)|:|PUERTO_AYORA',
    territory1Id: '22',
    territory1Name: 'GALAPAGOS',
    territory2Id: '224',
    territory2Name: 'SANTA CRUZ (PTO. AYORA)',
    territory3Id: '918',
    territory3Name: 'PUERTO AYORA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ALFREDO_BAQUERIZO_MORENO|:|ALFREDO_BAQUERIZO_MORENO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '101',
    territory2Name: 'ALFREDO BAQUERIZO MORENO',
    territory3Id: '562',
    territory3Name: 'ALFREDO BAQUERIZO MORENO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALAO|:|BALAO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '102',
    territory2Name: 'BALAO',
    territory3Id: '567',
    territory3Name: 'BALAO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALZAR|:|BALZAR',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '103',
    territory2Name: 'BALZAR',
    territory3Id: '570',
    territory3Name: 'BALZAR',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALZAR|:|LA_MARAVILLA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '103',
    territory2Name: 'BALZAR',
    territory3Id: '570',
    territory3Name: 'LA MARAVILLA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BUCAY|:|BUCAY',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '123',
    territory2Name: 'BUCAY',
    territory3Id: '805',
    territory3Name: 'BUCAY',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|COLIMES|:|COLIMES',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '104',
    territory2Name: 'COLIMES',
    territory3Id: '575',
    territory3Name: 'COLIMES',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CORONEL_MARCELINO_MARIDUENA|:|INGENIO_SAN_CARLOS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '120',
    territory2Name: 'CORONEL MARCELINO MARIDUENA',
    territory3Id: '795',
    territory3Name: 'INGENIO SAN CARLOS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CORONEL_MARCELINO_MARIDUENA|:|MARCELINO_MARIDUEÑAS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '120',
    territory2Name: 'CORONEL MARCELINO MARIDUENA',
    territory3Id: '795',
    territory3Name: 'MARCELINO MARIDUEÑAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|DAULE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '584',
    territory3Name: 'DAULE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LA_AURORA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '588',
    territory3Name: 'LA AURORA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LAS_ANIMAS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '584',
    territory3Name: 'LAS ANIMAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LAUREL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '586',
    territory3Name: 'LAUREL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LIMONAL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '626',
    territory3Name: 'LIMONAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LOS_TINTOS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '584',
    territory3Name: 'LOS TINTOS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|MATILDE_ESTHER',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '598',
    territory3Name: 'MATILDE ESTHER',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|PETRILLO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '628',
    territory3Name: 'PETRILLO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|PUENTE_LUCIA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '105',
    territory2Name: 'DAULE',
    territory3Id: '584',
    territory3Name: 'PUENTE LUCIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|DURAN_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '106',
    territory2Name: 'DURAN',
    territory3Id: '633',
    territory3Name: 'DURAN (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|EL_RECREO_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '106',
    territory2Name: 'DURAN',
    territory3Id: '636',
    territory3Name: 'EL RECREO (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|ELOY_ALFARO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '106',
    territory2Name: 'DURAN',
    territory3Id: '633',
    territory3Name: 'ELOY ALFARO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|EL_EMPALME',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '107',
    territory2Name: 'EL EMPALME',
    territory3Id: '995',
    territory3Name: 'EL EMPALME',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|EL_ROSARIO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '2418',
    territory2Name: 'EL EMPALME',
    territory3Id: '2438',
    territory3Name: 'EL ROSARIO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|GUAYAS_(PUEBLO_NUEVO)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '107',
    territory2Name: 'EL EMPALME',
    territory3Id: '658',
    territory3Name: 'GUAYAS (PUEBLO NUEVO)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_TRIUNFO|:|EL_TRIUNFO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '108',
    territory2Name: 'EL TRIUNFO',
    territory3Id: '664',
    territory3Name: 'EL TRIUNFO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_TRIUNFO|:|MANUEL_J_CALLE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '108',
    territory2Name: 'EL TRIUNFO',
    territory3Id: '664',
    territory3Name: 'MANUEL J CALLE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GENERAL_ANTONIO_ELIZALDE|:|GENERAL_ANTONIO_ELIZALDE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '',
    territory2Name: 'GENERAL ANTONIO ELIZALDE',
    territory3Id: '729',
    territory3Name: 'GENERAL ANTONIO ELIZALDE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|AYACUCHO_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '402',
    territory3Name: 'AYACUCHO (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|BOLIVAR_(SAGRARIO)_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '403',
    territory3Name: 'BOLIVAR (SAGRARIO) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CARBO_(CONCEPCION)_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '448',
    territory3Name: 'CARBO (CONCEPCION) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CERECITA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '958',
    territory3Name: 'CERECITA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CHIVERIA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '958',
    territory3Name: 'CHIVERIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CHONGON',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '499',
    territory3Name: 'CHONGON',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|FEBRES_CORDERO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '2373',
    territory3Name: 'FEBRES CORDERO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GARCIA_MORENO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '450',
    territory3Name: 'GARCIA MORENO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GUAYAQUIL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '958',
    territory3Name: 'GUAYAQUIL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|LA_TOMA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'LA TOMA',
    territory3Id: '958',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|LETAMENDI',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '451',
    territory3Name: 'LETAMENDI',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|MORRO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '507',
    territory3Name: 'MORRO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|NUEVE_DE_OCTUBRE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '469',
    territory3Name: 'NUEVE DE OCTUBRE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|OLMEDO_(SAN_ALEJO)_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '471',
    territory3Name: 'OLMEDO (SAN ALEJO) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|PASCUALES_(HASTA_KM_16)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '498',
    territory3Name: 'PASCUALES (HASTA KM 16)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|POSORJA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '508',
    territory3Name: 'POSORJA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|PROGRESO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '521',
    territory3Name: 'PROGRESO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|ROCA_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '475',
    territory3Name: 'ROCA (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|ROCAFUERTE_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '477',
    territory3Name: 'ROCAFUERTE (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SAN_ISIDRO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '958',
    territory3Name: 'SAN ISIDRO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SAN_LORENZO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '958',
    territory3Name: 'SAN LORENZO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SUCRE_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '479',
    territory3Name: 'SUCRE (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|TARQUI_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '480',
    territory3Name: 'TARQUI (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|TENGUEL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '509',
    territory3Name: 'TENGUEL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|URDANETA_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '483',
    territory3Name: 'URDANETA (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|XIMENA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '100',
    territory2Name: 'GUAYAQUIL',
    territory3Id: '484',
    territory3Name: 'XIMENA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ISIDRO_AYORA|:|ISIDRO_AYORA_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '124',
    territory2Name: 'ISIDRO AYORA',
    territory3Id: '807',
    territory3Name: 'ISIDRO AYORA (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LOMAS_DE_SARGENTILLO|:|LOMAS_DE_SARGENTILLO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '121',
    territory2Name: 'LOMAS DE SARGENTILLO',
    territory3Id: '798',
    territory3Name: 'LOMAS DE SARGENTILLO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|5_DE_JUNIO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '670',
    territory3Name: '5 DE JUNIO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|BOLICHE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '670',
    territory3Name: 'BOLICHE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CAMILO_ANDRADE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '686',
    territory3Name: 'CAMILO ANDRADE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CHIRIJOS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '690',
    territory3Name: 'CHIRIJOS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CHOBO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '696',
    territory3Name: 'CHOBO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CORONEL_ENRIQUE_VALDEZ',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '681',
    territory3Name: 'CORONEL ENRIQUE VALDEZ',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ELOY_ALFARO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '2022',
    territory3Name: 'ELOY ALFARO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ERNESTO_SEMINARIO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '679',
    territory3Name: 'ERNESTO SEMINARIO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|JOSE_MARIA_VELASCO_IBARRA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '2024',
    territory3Name: 'JOSE MARIA VELASCO IBARRA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|LAS_PINAS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '2026',
    territory3Name: 'LAS PINAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|MARISCAL_SUCRE_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '692',
    territory3Name: 'MARISCAL SUCRE (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|MILAGRO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '670',
    territory3Name: 'MILAGRO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ROBERTO_ASTUDILLO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '694',
    territory3Name: 'ROBERTO ASTUDILLO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ROSA_MARIA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '2023',
    territory3Name: 'ROSA MARIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|VICENTE_ROCAFUERTE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '109',
    territory2Name: 'MILAGRO',
    territory3Id: '2025',
    territory3Name: 'VICENTE ROCAFUERTE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|BASE_TAURA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '713',
    territory3Name: 'BASE TAURA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|CHURUTE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '713',
    territory3Name: 'CHURUTE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|JESUS_MARIA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '713',
    territory3Name: 'JESUS MARIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|NARANJAL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '713',
    territory3Name: 'NARANJAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|PUERTO_INCA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '719',
    territory3Name: 'PUERTO INCA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|TAURA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '715',
    territory3Name: 'TAURA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|VILLA_NUEVA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '110',
    territory2Name: 'NARANJAL',
    territory3Id: '713',
    territory3Name: 'VILLA NUEVA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJITO|:|NARANJITO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '111',
    territory2Name: 'NARANJITO',
    territory3Id: '729',
    territory3Name: 'NARANJITO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NOBOL|:|NOBOL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '122',
    territory2Name: 'NOBOL',
    territory3Id: '802',
    territory3Name: 'NOBOL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PALESTINA|:|COLORADAL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '112',
    territory2Name: 'PALESTINA',
    territory3Id: '739',
    territory3Name: 'COLORADAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PALESTINA|:|PALESTINA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '112',
    territory2Name: 'PALESTINA',
    territory3Id: '739',
    territory3Name: 'PALESTINA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PEDRO_CARBO|:|PEDRO_CARBO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '113',
    territory2Name: 'PEDRO CARBO',
    territory3Id: '740',
    territory3Name: 'PEDRO CARBO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PEDRO_CARBO|:|SABANILLA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '113',
    territory2Name: 'PEDRO CARBO',
    territory3Id: '748',
    territory3Name: 'SABANILLA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|DATA_DE_PLAYAS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '118',
    territory2Name: 'PLAYAS',
    territory3Id: '781',
    territory3Name: 'DATA DE PLAYAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|ENGABAO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '118',
    territory2Name: 'PLAYAS',
    territory3Id: '781',
    territory3Name: 'ENGABAO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|PLAYAS',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '118',
    territory2Name: 'PLAYAS',
    territory3Id: '781',
    territory3Name: 'PLAYAS',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|PUERTO_DEL_ENGABAO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '118',
    territory2Name: 'PLAYAS',
    territory3Id: '781',
    territory3Name: 'PUERTO DEL ENGABAO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|SAN_ANTONIO',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '118',
    territory2Name: 'PLAYAS',
    territory3Id: '781',
    territory3Name: 'SAN ANTONIO',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PTO_BAQUERIZO_MORENO_(JUJAN)|:|JUJAN',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '101',
    territory2Name: 'PTO BAQUERIZO MORENO (JUJAN)',
    territory3Id: '562',
    territory3Name: 'JUJAN',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|GENERAL_VERNAZA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '116',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Id: '763',
    territory3Name: 'GENERAL VERNAZA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|JUNQUILLAL',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '116',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Id: '2034',
    territory3Name: 'JUNQUILLAL',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|LA_T_DE_SALITRE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '116',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Id: '763',
    territory3Name: 'LA T DE SALITRE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|LA_VICTORIA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '116',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Id: '763',
    territory3Name: 'LA VICTORIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|SALITRE',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '116',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Id: '763',
    territory3Name: 'SALITRE',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|BOCA_DE_CANA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '114',
    territory2Name: 'SAMBORONDON',
    territory3Id: '753',
    territory3Name: 'BOCA DE CANA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|LA_PUNTILLA_(EL_DORADO)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '114',
    territory2Name: 'SAMBORONDON',
    territory3Id: '754',
    territory3Name: 'LA PUNTILLA (EL DORADO)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|SAMBORONDON',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '114',
    territory2Name: 'SAMBORONDON',
    territory3Id: '753',
    territory3Name: 'SAMBORONDON',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|TARIFA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '114',
    territory2Name: 'SAMBORONDON',
    territory3Id: '757',
    territory3Name: 'TARIFA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|KM_26',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '',
    territory2Name: 'KM 26',
    territory3Id: '766',
    territory3Name: 'KM 26',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|SAN JACINTO DE YAGUACHI',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '117',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Id: '766',
    territory3Name: 'SAN JACINTO DE YAGUACHI',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|TRES_POSTES',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '117',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Id: '766',
    territory3Name: '3 POSTES',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|VIRGEN_DE_FATIMA_(KM_26)_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '117',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Id: '768',
    territory3Name: 'VIRGEN DE FATIMA (KM 26) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|YAGUACHI_(GY)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '117',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Id: '766',
    territory3Name: 'YAGUACHI (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|YAGUACHI_VIEJO_(EL_DESEO)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '117',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Id: '773',
    territory3Name: 'YAGUACHI VIEJO (EL DESEO)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SANTA_LUCIA|:|SANTA_LUCIA',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '115',
    territory2Name: 'SANTA LUCIA',
    territory3Id: '761',
    territory3Name: 'SANTA LUCIA',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SIMON_BOLIVAR|:|LORENZO_DE_GARAICOA_(PEDREGAL)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '119',
    territory2Name: 'SIMON BOLIVAR',
    territory3Id: '791',
    territory3Name: 'LORENZO DE GARAICOA (PEDREGAL)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SIMON_BOLIVAR|:|SIMON_BOLIVAR_(LR)',
    territory1Id: '11',
    territory1Name: 'GUAYAS',
    territory2Id: '119',
    territory2Name: 'SIMON BOLIVAR',
    territory3Id: '787',
    territory3Name: 'SIMON BOLIVAR (LR)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|ANDRADE_MARIN',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '126',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '632',
    territory3Name: 'ANDRADE MARIN',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|ATUNTAQUI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '126',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '629',
    territory3Name: 'ATUNTAQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|CHALTURA_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '126',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '637',
    territory3Name: 'CHALTURA (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|IMBAYA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '126',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '641',
    territory3Name: 'IMBAYA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|NATABUELA_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '126',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '634',
    territory3Name: 'NATABUELA (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|SAN_ROQUE',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '126',
    territory2Name: 'ANTONIO ANTE',
    territory3Id: '639',
    territory3Name: 'SAN ROQUE',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|COTACACHI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '127',
    territory2Name: 'COTACACHI',
    territory3Id: '644',
    territory3Name: 'COTACACHI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|QUIROGA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '127',
    territory2Name: 'COTACACHI',
    territory3Id: '648',
    territory3Name: 'QUIROGA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|SAGRARIO_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '127',
    territory2Name: 'COTACACHI',
    territory3Id: '2038',
    territory3Name: 'SAGRARIO (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|SAN_FRANCISCO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '127',
    territory2Name: 'COTACACHI',
    territory3Id: '650',
    territory3Name: 'SAN FRANCISCO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|ADUANA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'ADUANA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|CARANQUI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '605',
    territory3Name: 'CARANQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|CHORLAVI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'CHORLAVI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|EL_OLIVO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'EL OLIVO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|EL_RETORNO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'EL RETORNO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|GUAYAQUIL_DE_ALPACHACA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '607',
    territory3Name: 'GUAYAQUIL DE ALPACHACA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|LA_DOLOROSA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '616',
    territory3Name: 'LA DOLOROSA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|LA_ESPERANZA_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '2036',
    territory3Name: 'LA ESPERANZA (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|PRIORATO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'PRIORATO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAGRARIO_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '608',
    territory3Name: 'SAGRARIO (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_ANTONIO_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '603',
    territory3Name: 'SAN ANTONIO (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_FRANCISCO_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '612',
    territory3Name: 'SAN FRANCISCO (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_MIGUEL_DE_IBARRA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'SAN MIGUEL DE IBARRA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|TABABUELA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '2391',
    territory3Name: 'TABABUELA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|YAGUARCOCHA',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '125',
    territory2Name: 'IBARRA',
    territory3Id: '618',
    territory3Name: 'YAGUARCOCHA',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|DOCTOR_MIGUEL_EGAS_CABEZAS_(PEGUCHE)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '667',
    territory3Name: 'DOCTOR MIGUEL EGAS CABEZAS (PEGUCHE)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|EUGENIO_ESPEJO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '671',
    territory3Name: 'EUGENIO ESPEJO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|GONZALEZ_SUAREZ_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '675',
    territory3Name: 'GONZALEZ SUAREZ (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|ILUMAN',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '706',
    territory3Name: 'ILUMAN',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|JORDAN',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '688',
    territory3Name: 'JORDAN',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|OTAVALO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '665',
    territory3Name: 'OTAVALO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PEGUCHE',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '996',
    territory3Name: 'PEGUCHE',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PERUGACHI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '2413',
    territory2Name: 'OTAVALO',
    territory3Id: '2433',
    territory3Name: 'PERUGACHI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PINSAQUI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '665',
    territory3Name: 'PINSAQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PUERTO_LAGO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '665',
    territory3Name: 'PUERTO LAGO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|SAN_LUIS_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '700',
    territory3Name: 'SAN LUIS (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|SAN_PABLO_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '680',
    territory3Name: 'SAN PABLO (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|SAN_RAFAEL_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '128',
    territory2Name: 'OTAVALO',
    territory3Id: '677',
    territory3Name: 'SAN RAFAEL (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|PIMAMPIRO|:|PIMAMPIRO',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '129',
    territory2Name: 'PIMAMPIRO',
    territory3Id: '711',
    territory3Name: 'PIMAMPIRO',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_URCUQUI|:|SAN_BLAS_(IM)',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '130',
    territory2Name: 'SAN MIGUEL DE URCUQUI',
    territory3Id: '2046',
    territory3Name: 'SAN BLAS (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_URCUQUI|:|URCUQUI',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '130',
    territory2Name: 'SAN MIGUEL DE URCUQUI',
    territory3Id: '717',
    territory3Name: 'URCUQUI',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_URCUQUI|:|YACHAY',
    territory1Id: '12',
    territory1Name: 'IMBABURA',
    territory2Id: '130',
    territory2Name: 'SAN MIGUEL DE URCUQUI',
    territory3Id: '717',
    territory3Name: 'YACHAY',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CALVAS|:|CARIAMANGA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '132',
    territory2Name: 'CALVAS',
    territory3Id: '687',
    territory3Name: 'CARIAMANGA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CALVAS|:|EL_LUCERO',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '132',
    territory2Name: 'CALVAS',
    territory3Id: '687',
    territory3Name: 'EL LUCERO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CATAMAYO|:|CATAMAYO',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '133',
    territory2Name: 'CATAMAYO',
    territory3Id: '689',
    territory3Name: 'CATAMAYO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CATAMAYO|:|SAN_PEDRO_DE_LA_BENDITA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '133',
    territory2Name: 'CATAMAYO',
    territory3Id: '2067',
    territory3Name: 'SAN PEDRO DE LA BENDITA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|ALGARROBILLO',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '134',
    territory2Name: 'CELICA',
    territory3Id: '691',
    territory3Name: 'ALGARROBILLO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|CELICA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '134',
    territory2Name: 'CELICA',
    territory3Id: '691',
    territory3Name: 'CELICA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|CRUZPAMBA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '134',
    territory2Name: 'CELICA',
    territory3Id: '691',
    territory3Name: 'CRUZPAMBA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|POZUL',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '134',
    territory2Name: 'CELICA',
    territory3Id: '691',
    territory3Name: 'POZUL',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|ROBLONES',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '134',
    territory2Name: 'CELICA',
    territory3Id: '691',
    territory3Name: 'ROBLONES',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|SABANILLA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '134',
    territory2Name: 'CELICA',
    territory3Id: '691',
    territory3Name: 'SABANILLA',
  },
  // {
  //   territoryBaseId: 'EC|:|LOJA|:|CHAGUARPAMBA|:|CHAGUARPAMBA',
  //   territory1Id: '13',
  //   territory1Name: 'LOJA',
  //   territory2Id: '135',
  //   territory2Name: 'CHAGUARPAMBA',
  //   territory3Id: '135',
  //   territory3Name: 'CHAGUARPAMBA',
  // },
  {
    territoryBaseId: 'EC|:|LOJA|:|ESPINDOLA|:|27_DE_ABRIL_(CAB._EN_LA_NARANJA)',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '136',
    territory2Name: 'ESPINDOLA',
    territory3Id: '2078',
    territory3Name: '27 DE ABRIL (CAB. EN LA NARANJA)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ESPINDOLA|:|AMALUZA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '136',
    territory2Name: 'ESPINDOLA',
    territory3Id: '2078',
    territory3Name: 'AMALUZA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|GONZANAMA|:|GONZANAMA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '137',
    territory2Name: 'GONZANAMA',
    territory3Id: '707',
    territory3Name: 'GONZANAMA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|CARIGAN',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '2048',
    territory3Name: 'CARIGAN',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|CIUDAD_VICTORIA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '663',
    territory3Name: 'CIUDAD VICTORIA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|EL_SAGRARIO_(LO)',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '666',
    territory3Name: 'EL SAGRARIO (LO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|LOJA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '659',
    territory3Name: 'LOJA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|MALACATOS',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '661',
    territory3Name: 'MALACATOS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SAN_LUCAS',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '678',
    territory3Name: 'SAN LUCAS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SAN_PEDRO_DE_VILCABAMBA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '2377',
    territory2Name: 'LOJA',
    territory3Id: '2378',
    territory3Name: 'SAN PEDRO DE VILCABAMBA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SAN_SEBASTIAN',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '669',
    territory3Name: 'SAN SEBASTIAN',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SANTIAGO',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '685',
    territory3Name: 'SANTIAGO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SUCRE_(LO)',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '673',
    territory3Name: 'SUCRE (LO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|VALLADOLID',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '2414',
    territory2Name: 'LOJA',
    territory3Id: '2434',
    territory3Name: 'VALLADOLID',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|VALLE_(LO)',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '131',
    territory2Name: 'LOJA',
    territory3Id: '674',
    territory3Name: 'VALLE (LO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|YANGANA_(ARSENIO_CASTILLO)',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '2415',
    territory2Name: 'LOJA',
    territory3Id: '2435',
    territory3Name: 'YANGANA (ARSENIO CASTILLO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|MACARA|:|MACARA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '138',
    territory2Name: 'MACARA',
    territory3Id: '709',
    territory3Name: 'MACARA',
  },
  // {
  //   territoryBaseId: 'EC|:|LOJA|:|OLMEDO|:|OLMEDO',
  //   territory1Id: '13',
  //   territory1Name: 'LOJA',
  //   territory2Id: '146',
  //   territory2Name: 'OLMEDO',
  //   territory3Id: '146',
  //   territory3Name: 'OLMEDO',
  // },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|CATACOCHA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '139',
    territory2Name: 'PALTAS',
    territory3Id: '714',
    territory3Name: 'CATACOCHA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|PALTAS',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '139',
    territory2Name: 'PALTAS',
    territory3Id: '714',
    territory3Name: 'PALTAS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|RIO_PLAYAS',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '139',
    territory2Name: 'PALTAS',
    territory3Id: '714',
    territory3Name: 'RIO PLAYAS',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PINDAL|:|PINDAL',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '144',
    territory2Name: 'PINDAL',
    territory3Id: '731',
    territory3Name: 'PINDAL',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PUYANGO|:|ALAMOR',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '140',
    territory2Name: 'PUYANGO',
    territory3Id: '716',
    territory3Name: 'ALAMOR',
  },
  // {
  //   territoryBaseId: 'EC|:|LOJA|:|QUILANGA|:|EL_INGENIO',
  //   territory1Id: '13',
  //   territory1Name: 'LOJA',
  //   territory2Id: '145',
  //   territory2Name: 'QUILANGA',
  //   territory3Id: '145',
  //   territory3Name: 'EL INGENIO',
  // },
  // {
  //   territoryBaseId: 'EC|:|LOJA|:|QUILANGA|:|QUILANGA',
  //   territory1Id: '13',
  //   territory1Name: 'LOJA',
  //   territory2Id: '145',
  //   territory2Name: 'QUILANGA',
  //   territory3Id: '145',
  //   territory3Name: 'QUILANGA',
  // },
  {
    territoryBaseId: 'EC|:|LOJA|:|SARAGURO|:|SARAGURO',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '141',
    territory2Name: 'SARAGURO',
    territory3Id: '720',
    territory3Name: 'SARAGURO',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SOZORANGA|:|EL_EMPALME',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '142',
    territory2Name: 'SOZORANGA',
    territory3Id: '723',
    territory3Name: 'EL EMPALME',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SOZORANGA|:|SOZORANGA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '142',
    territory2Name: 'SOZORANGA',
    territory3Id: '723',
    territory3Name: 'SOZORANGA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ZAPOTILLO|:|LA_CEIBA',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '143',
    territory2Name: 'ZAPOTILLO',
    territory3Id: '725',
    territory3Name: 'LA CEIBA',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ZAPOTILLO|:|ZAPOTILLO',
    territory1Id: '13',
    territory1Name: 'LOJA',
    territory2Id: '143',
    territory2Name: 'ZAPOTILLO',
    territory3Id: '725',
    territory3Name: 'ZAPOTILLO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|BABA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '148',
    territory2Name: 'BABA',
    territory3Id: '727',
    territory3Name: 'BABA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|GUARE_DE_BABA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '148',
    territory2Name: 'BABA',
    territory3Id: '727',
    territory3Name: 'GUARE DE BABA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|ISLA_DE_BEJUCAL',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '148',
    territory2Name: 'BABA',
    territory3Id: '732',
    territory3Name: 'ISLA DE BEJUCAL',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BABAHOYO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'BABAHOYO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BARREIRO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '710',
    territory3Name: 'BARREIRO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BRISAS_DEL_RIO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '2390',
    territory3Name: 'BRISAS DEL RIO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|CAMILO_PONCE_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '708',
    territory3Name: 'CAMILO PONCE (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|CDLA._LAS_TOLAS',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '2389',
    territory3Name: 'CDLA. LAS TOLAS',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|CLEMENTE_BAQUERIZO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '703',
    territory3Name: 'CLEMENTE BAQUERIZO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|EL_SALTO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '712',
    territory3Name: 'EL SALTO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|FEBRES_CORDERO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'FEBRES CORDERO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_CHORRERA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '2388',
    territory3Name: 'LA CHORRERA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_JULIA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'LA JULIA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_UNION',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'LA UNION',
  },
  // {
  //   territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_UNION_DE_CLEMENTINA',
  //   territory1Id: '14',
  //   territory1Name: 'LOS RIOS',
  //   territory2Id: '2417',
  //   territory2Name: 'BABAHOYO',
  //   territory3Id: '2417',
  //   territory3Name: 'LA UNION DE CLEMENTINA',
  // },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|MATA_DE_CACAO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'MATA DE CACAO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|PIMOCHA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'PIMOCHA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|PUEBLO_NUEVO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'PUEBLO NUEVO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|SAN_JOSE_DEL_TAMBO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '959',
    territory3Name: 'SAN JOSE DEL TAMBO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|URB._VALLE_VERDE',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '147',
    territory2Name: 'BABAHOYO',
    territory3Id: '2392',
    territory3Name: 'URB. VALLE VERDE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|LA_CATORCE',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '2416',
    territory2Name: 'BUENA FE',
    territory3Id: '2436',
    territory3Name: 'LA CATORCE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|LOS_ANGELES_(SD)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '156',
    territory2Name: 'BUENA FE',
    territory3Id: '2376',
    territory3Name: 'LOS ANGELES (SD)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|PALMISA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '2416',
    territory2Name: 'BUENA FE',
    territory3Id: '821',
    territory3Name: 'PALMISA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|PATRICIA_PILAR',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '156',
    territory2Name: 'BUENA FE',
    territory3Id: '823',
    territory3Name: 'PATRICIA PILAR',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|SAN_JACINTO_DE_BUENA_FE_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '156',
    territory2Name: 'BUENA FE',
    territory3Id: '821',
    territory3Name: 'SAN JACINTO DE BUENA FE (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|ZULEMA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '',
    territory2Name: 'ZULEMA',
    territory3Id: '821',
    territory3Name: 'ZULEMA',
  },
  // {
  //   territoryBaseId: 'EC|:|LOS_RIOS|:|CARACOL|:|CARACOL',
  //   territory1Id: '14',
  //   territory1Name: 'LOS RIOS',
  //   territory2Id: '2400',
  //   territory2Name: 'CARACOL',
  //   territory3Id: '2400',
  //   territory3Name: 'CARACOL',
  // },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|EL_EMPALME|:|EL_EMPALME',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '107',
    territory2Name: 'EL EMPALME',
    territory3Id: '995',
    territory3Name: 'EL EMPALME',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|LAS_NAVES|:|LAS_NAVES',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '48',
    territory2Name: 'LAS NAVES',
    territory3Id: '48',
    territory3Name: 'LAS NAVES',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MOCACHE|:|MOCACHE',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '158',
    territory2Name: 'MOCACHE',
    territory3Id: '837',
    territory3Name: 'MOCACHE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MONTALVO|:|LA_ESMERALDA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '149',
    territory2Name: 'MONTALVO',
    territory3Id: '755',
    territory3Name: 'LA ESMERALDA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MONTALVO|:|MONTALVO_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '149',
    territory2Name: 'MONTALVO',
    territory3Id: '755',
    territory3Name: 'MONTALVO (LR)',
  },
  // {
  //   territoryBaseId: 'EC|:|LOS_RIOS|:|PALENQUE|:|PALENQUE',
  //   territory1Id: '14',
  //   territory1Name: 'LOS RIOS',
  //   territory2Id: '155',
  //   territory2Name: 'PALENQUE',
  //   territory3Id: '155',
  //   territory3Name: 'PALENQUE',
  // },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLOVIEJO|:|ENTRADA_DE_SAN_JUAN',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '150',
    territory2Name: 'PUEBLOVIEJO',
    territory3Id: '759',
    territory3Name: 'ENTRADA DE SAN JUAN',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLOVIEJO|:|PUEBLO_VIEJO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '150',
    territory2Name: 'PUEBLOVIEJO',
    territory3Id: '759',
    territory3Name: 'PUEBLO VIEJO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLOVIEJO|:|SAN_JUAN_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '150',
    territory2Name: 'PUEBLOVIEJO',
    territory3Id: '760',
    territory3Name: 'SAN JUAN (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|24_DE_MAYO_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '786',
    territory3Name: '24 DE MAYO (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|FUMISA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '764',
    territory3Name: 'FUMISA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|GUAYACAN',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '776',
    territory3Name: 'GUAYACAN',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|LA_ESPERANZA_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '770',
    territory3Name: 'LA ESPERANZA (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|LA_UNION',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '764',
    territory3Name: 'LA UNION',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|NICOLAS_INFANTE_DIAZ',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '780',
    territory3Name: 'NICOLAS INFANTE DIAZ',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|QUEVEDO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '764',
    territory3Name: 'QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SAN_CAMILO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '774',
    territory3Name: 'SAN CAMILO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SAN_CARLOS_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '767',
    territory3Name: 'SAN CARLOS (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SAN_CRISTOBAL_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '782',
    territory3Name: 'SAN CRISTOBAL (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SIETE_DE_OCTUBRE',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '783',
    territory3Name: 'SIETE DE OCTUBRE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|VENUS_DEL_RIO_QUEVEDO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '789',
    territory3Name: 'VENUS DEL RIO QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|VIVA_ALFARO',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '151',
    territory2Name: 'QUEVEDO',
    territory3Id: '793',
    territory3Name: 'VIVA ALFARO',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUINSALOMA|:|QUINSALOMA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '159',
    territory2Name: 'QUINSALOMA',
    territory3Id: '840',
    territory3Name: 'QUINSALOMA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_LUIS_DE_PAMBIL|:|SAN_LUIS_DE_PAMBIL',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '',
    territory2Name: 'SAN LUIS DE PAMBIL',
    territory3Id: '48',
    territory3Name: 'SAN LUIS DE PAMBIL',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|URDANETA|:|CATARAMA',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '152',
    territory2Name: 'URDANETA',
    territory3Id: '797',
    territory3Name: 'CATARAMA',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|URDANETA|:|RICAURTE_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '152',
    territory2Name: 'URDANETA',
    territory3Id: '801',
    territory3Name: 'RICAURTE (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|URDANETA|:|URDANETA_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '152',
    territory2Name: 'URDANETA',
    territory3Id: '797',
    territory3Name: 'URDANETA (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|LA_UNION_DE_VALENCIA_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '157',
    territory2Name: 'VALENCIA',
    territory3Id: '2133',
    territory3Name: 'LA UNION DE VALENCIA (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|NUEVA_UNION',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '157',
    territory2Name: 'VALENCIA',
    territory3Id: '2133',
    territory3Name: 'NUEVA UNION',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|VALENCIA_(LR)',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '157',
    territory2Name: 'VALENCIA',
    territory3Id: '2132',
    territory3Name: 'VALENCIA (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|10_DE_NOVIEMBRE',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '153',
    territory2Name: 'VENTANAS',
    territory3Id: '2126',
    territory3Name: '10 DE NOVIEMBRE',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|VENTANAS',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '153',
    territory2Name: 'VENTANAS',
    territory3Id: '804',
    territory3Name: 'VENTANAS',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|ZAPOTAL',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '2445',
    territory2Name: 'VENTANAS',
    territory3Id: '2446',
    territory3Name: 'ZAPOTAL',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VINCES|:|VINCES',
    territory1Id: '14',
    territory1Name: 'LOS RIOS',
    territory2Id: '154',
    territory2Name: 'VINCES',
    territory3Id: '2129',
    territory3Name: 'VINCES',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|24_DE_MAYO|:|BELLAVISTA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '175',
    territory2Name: '24 DE MAYO',
    territory3Id: '899',
    territory3Name: 'BELLAVISTA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|24_DE_MAYO|:|SUCRE_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '175',
    territory2Name: '24 DE MAYO',
    territory3Id: '899',
    territory3Name: 'SUCRE (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|BAHIA_DE_CARAQUEZ',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Id: '882',
    territory3Name: 'BAHIA DE CARAQUEZ',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|CANITAS',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA_DE_CARAQUEZ_(SUCRE)',
    territory3Id: '882',
    territory3Name: 'CANITAS',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|CHARAPOTO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Id: '2451',
    territory3Name: 'CHARAPOTO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|LEONIDAS_PLAZA_GUTIERREZ',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Id: '886',
    territory3Name: 'LEONIDAS PLAZA GUTIERREZ',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|SAN_CLEMENTE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Id: '888',
    territory3Name: 'SAN CLEMENTE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|SAN_ISIDRO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Id: '882',
    territory3Name: 'SAN ISIDRO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|SAN_JACINTO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '173',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Id: '882',
    territory3Name: 'SAN JACINTO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CALCETA_(BOLIVAR)|:|CALCETA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '161',
    territory2Name: 'CALCETA (BOLIVAR)',
    territory3Id: '756',
    territory3Name: 'CALCETA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|CANUTO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '771',
    territory3Name: 'CANUTO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|CHONE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '765',
    territory3Name: 'CHONE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|PAVON',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '765',
    territory3Name: 'PAVON',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|RICAURTE_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '775',
    territory3Name: 'RICAURTE (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|SAN_ANTONIO_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '777',
    territory3Name: 'SAN ANTONIO (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|SANTA_RITA_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '779',
    territory3Name: 'SANTA RITA (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|SESME',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '162',
    territory2Name: 'CHONE',
    territory3Id: '765',
    territory3Name: 'SESME',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|EL_CARMEN|:|EL_CARMEN',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '163',
    territory2Name: 'EL CARMEN',
    territory3Id: '785',
    territory3Name: 'EL CARMEN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|FLAVIO_ALFARO|:|FLAVIO_ALFARO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '164',
    territory2Name: 'FLAVIO ALFARO',
    territory3Id: '803',
    territory3Name: 'FLAVIO ALFARO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|FLAVIO_ALFARO|:|ZAPALLO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '164',
    territory2Name: 'FLAVIO ALFARO',
    territory3Id: '812',
    territory3Name: 'ZAPALLO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|COAQUE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '179',
    territory2Name: 'JAMA',
    territory3Id: '909',
    territory3Name: 'COAQUE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|DON_JUAN',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '179',
    territory2Name: 'JAMA',
    territory3Id: '909',
    territory3Name: 'DON JUAN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|EL_MATAL',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '179',
    territory2Name: 'JAMA',
    territory3Id: '909',
    territory3Name: 'EL MATAL',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|JAMA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '179',
    territory2Name: 'JAMA',
    territory3Id: '909',
    territory3Name: 'JAMA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JARAMIJO|:|JARAMIJO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '180',
    territory2Name: 'JARAMIJO',
    territory3Id: '910',
    territory3Name: 'JARAMIJO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|DOCTOR_MIGUEL_MORAN_LUCIO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '165',
    territory2Name: 'JIPIJAPA',
    territory3Id: '2139',
    territory3Name: 'DOCTOR MIGUEL MORAN LUCIO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|JIPIJAPA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '165',
    territory2Name: 'JIPIJAPA',
    territory3Id: '822',
    territory3Name: 'JIPIJAPA',
  },
  // {
  //   territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|LAS_TUNAS',
  //   territory1Id: '15',
  //   territory1Name: 'MANABI',
  //   territory2Id: '2422',
  //   territory2Name: 'JIPIJAPA',
  //   territory3Id: '2422',
  //   territory3Name: 'LAS TUNAS',
  // },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|MANUEL_INOCENCIO_PARRALES_Y_GUALE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '165',
    territory2Name: 'JIPIJAPA',
    territory3Id: '2140',
    territory3Name: 'MANUEL INOCENCIO PARRALES Y GUALE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|PUERTO_DE_CAYO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '165',
    territory2Name: 'JIPIJAPA',
    territory3Id: '818',
    territory3Name: 'PUERTO DE CAYO',
  },
  // {
  //   territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|RECINTO_RIO_CHICO',
  //   territory1Id: '15',
  //   territory1Name: 'MANABI',
  //   territory2Id: '2420',
  //   territory2Name: 'JIPIJAPA',
  //   territory3Id: '2420',
  //   territory3Name: 'RECINTO RIO CHICO',
  // },
  // {
  //   territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|SALANGO',
  //   territory1Id: '15',
  //   territory1Name: 'MANABI',
  //   territory2Id: '2423',
  //   territory2Name: 'JIPIJAPA',
  //   territory3Id: '2423',
  //   territory3Name: 'SALANGO',
  // },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|SAN_LORENZO_DE_JIPIJAPA_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '165',
    territory2Name: 'JIPIJAPA',
    territory3Id: '820',
    territory3Name: 'SAN LORENZO DE JIPIJAPA (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|SANCAN',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '165',
    territory2Name: 'JIPIJAPA',
    territory3Id: '822',
    territory3Name: 'SANCAN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JUNIN|:|JUNIN',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '166',
    territory2Name: 'JUNIN',
    territory3Id: '829',
    territory3Name: 'JUNIN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|ELOY_ALFARO_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '167',
    territory2Name: 'MANTA',
    territory3Id: '838',
    territory3Name: 'ELOY ALFARO (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|LOS_ESTEROS',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '167',
    territory2Name: 'MANTA',
    territory3Id: '834',
    territory3Name: 'LOS ESTEROS',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|MANTA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '167',
    territory2Name: 'MANTA',
    territory3Id: '831',
    territory3Name: 'MANTA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|SAN_MATEO_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '167',
    territory2Name: 'MANTA',
    territory3Id: '833',
    territory3Name: 'SAN MATEO (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|TARQUI_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '167',
    territory2Name: 'MANTA',
    territory3Id: '835',
    territory3Name: 'TARQUI (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|EL_COLORADO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '168',
    territory2Name: 'MONTECRISTI',
    territory3Id: '846',
    territory3Name: 'EL COLORADO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|GENERAL_ELOY_ALFARO_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '168',
    territory2Name: 'MONTECRISTI',
    territory3Id: '847',
    territory3Name: 'GENERAL ELOY ALFARO (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|LAS_CAÑAS_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '168',
    territory2Name: 'MONTECRISTI',
    territory3Id: '1095',
    territory3Name: 'LAS CAÑAS (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|LEONIDAS_PROAÑO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '168',
    territory2Name: 'MONTECRISTI',
    territory3Id: '849',
    territory3Name: 'LEONIDAS PROAÑO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|LOS_BAJOS',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '168',
    territory2Name: 'MONTECRISTI',
    territory3Id: '1012',
    territory3Name: 'LOS BAJOS',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|MONTECRISTI',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '168',
    territory2Name: 'MONTECRISTI',
    territory3Id: '841',
    territory3Name: 'MONTECRISTI',
  },
  // {
  //   territoryBaseId: 'EC|:|MANABI|:|OLMEDO|:|OLMEDO',
  //   territory1Id: '15',
  //   territory1Name: 'MANABI',
  //   territory2Id: '177',
  //   territory2Name: 'OLMEDO',
  //   territory3Id: '177',
  //   territory3Name: 'OLMEDO',
  // },
  {
    territoryBaseId: 'EC|:|MANABI|:|PAJAN|:|CASCOL',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '169',
    territory2Name: 'PAJAN',
    territory3Id: '856',
    territory3Name: 'CASCOL',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PAJAN|:|PAJAN',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '169',
    territory2Name: 'PAJAN',
    territory3Id: '856',
    territory3Name: 'PAJAN',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|10_DE_AGOSTO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: '10 DE AGOSTO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|ATAHUALPA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: 'ATAHUALPA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|CHEVE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: 'CHEVE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|COJIMIES',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: 'COJIMIES',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|LA_CHORRERA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: 'LA CHORRERA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|MACHE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: 'MACHE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|PEDERNALES',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '176',
    territory2Name: 'PEDERNALES',
    territory3Id: '900',
    territory3Name: 'PEDERNALES',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PICHINCHA|:|PICHINCHA_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '170',
    territory2Name: 'PICHINCHA',
    territory3Id: '872',
    territory3Name: 'PICHINCHA (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|12_DE_MARZO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '744',
    territory3Name: '12 DE MARZO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|18_DE_OCTUBRE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '747',
    territory3Name: '18 DE OCTUBRE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ABDON_CALDERON_(SAN_FRANCISCO)_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '735',
    territory3Name: 'ABDON CALDERON (SAN FRANCISCO) (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ALAJUELA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '2419',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '2439',
    territory3Name: 'ALAJUELA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ALHAJUELA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'ALHAJUELA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ANDRES_DE_VERA_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '745',
    territory3Name: 'ANDRES DE VERA (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ARENALES',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'ARENALES',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|AYACUCHO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '2424',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '2444',
    territory3Name: 'AYACUCHO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CALDERON_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'CALDERON (MA)',
  },
  // {
  //   territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CHIRIJOS',
  //   territory1Id: '15',
  //   territory1Name: 'MANABI',
  //   territory2Id: '2449',
  //   territory2Name: 'PORTOVIEJO',
  //   territory3Id: '2449',
  //   territory3Name: 'CHIRIJOS',
  // },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|COLON_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '733',
    territory3Name: 'COLON (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CRUCITA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '738',
    territory3Name: 'CRUCITA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|EL_NARANJO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'EL NARANJO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|EL_RODEO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'EL RODEO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|FRANCISCO_PACHECO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '746',
    territory3Name: 'FRANCISCO PACHECO',
  },
  // {
  //   territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|HORONATO_VAZQUES',
  //   territory1Id: '15',
  //   territory1Name: 'MANABI',
  //   territory2Id: '2447',
  //   territory2Name: 'PORTOVIEJO',
  //   territory3Id: '2447',
  //   territory3Name: 'HORONATO VAZQUES',
  // },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|LA_SEQUITA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'LA SEQUITA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PICOAZA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '730',
    territory3Name: 'PICOAZA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PLAYA_PRIETA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'PLAYA PRIETA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PORTOVIEJO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'PORTOVIEJO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PUEBLO_NUEVO_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '752',
    territory3Name: 'PUEBLO NUEVO (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|RIOCHICO_(RIO_CHICO)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '741',
    territory3Name: 'RIOCHICO (RIO CHICO)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|SAN_IGNACIO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '728',
    territory3Name: 'SAN IGNACIO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|SAN_PLACIDO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '2421',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '2441',
    territory3Name: 'SAN PLACIDO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|SIMON_BOLIVAR_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '160',
    territory2Name: 'PORTOVIEJO',
    territory3Id: '749',
    territory3Name: 'SIMON BOLIVAR (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PUERTO_LOPEZ|:|MACHALILLA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '178',
    territory2Name: 'PUERTO LOPEZ',
    territory3Id: '906',
    territory3Name: 'MACHALILLA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PUERTO_LOPEZ|:|PUERTO_LOPEZ',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '178',
    territory2Name: 'PUERTO LOPEZ',
    territory3Id: '905',
    territory3Name: 'PUERTO LOPEZ',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|MEJIA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '171',
    territory2Name: 'ROCAFUERTE',
    territory3Id: '1149',
    territory3Name: 'MEJIA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|RESBALON',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '171',
    territory2Name: 'ROCAFUERTE',
    territory3Id: '874',
    territory3Name: 'RESBALON',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|ROCAFUERTE_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '171',
    territory2Name: 'ROCAFUERTE',
    territory3Id: '874',
    territory3Name: 'ROCAFUERTE (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|SOSOTE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '171',
    territory2Name: 'ROCAFUERTE',
    territory3Id: '1150',
    territory3Name: 'SOSOTE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|CANOA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '181',
    territory2Name: 'SAN VICENTE',
    territory3Id: '913',
    territory3Name: 'CANOA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|NUEVO_BRICENO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '181',
    territory2Name: 'SAN VICENTE',
    territory3Id: '912',
    territory3Name: 'NUEVO BRICENO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|SAN_VICENTE',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '181',
    territory2Name: 'SAN VICENTE',
    territory3Id: '912',
    territory3Name: 'SAN VICENTE',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SANTA_ANA|:|LODANA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '172',
    territory2Name: 'SANTA ANA',
    territory3Id: '876',
    territory3Name: 'LODANA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SANTA_ANA|:|SANTA_ANA_(MA)',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '172',
    territory2Name: 'SANTA ANA',
    territory3Id: '2151',
    territory3Name: 'SANTA ANA (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|BACHILLERO',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '174',
    territory2Name: 'TOSAGUA',
    territory3Id: '896',
    territory3Name: 'BACHILLERO',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|LA_ESTANCILLA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '174',
    territory2Name: 'TOSAGUA',
    territory3Id: '897',
    territory3Name: 'LA ESTANCILLA',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|TOSAGUA',
    territory1Id: '15',
    territory1Name: 'MANABI',
    territory2Id: '174',
    territory2Name: 'TOSAGUA',
    territory3Id: '895',
    territory3Name: 'TOSAGUA',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|GUALAQUIZA|:|GUALAQUIZA',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '183',
    territory2Name: 'GUALAQUIZA',
    territory3Id: '1065',
    territory3Name: 'GUALAQUIZA',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|HUAMBOYA|:|HUAMBOYA',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '188',
    territory2Name: 'HUAMBOYA',
    territory3Id: '2195',
    territory3Name: 'HUAMBOYA',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|LIMON_INDANZA|:|LIMON_INDANZA',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '184',
    territory2Name: 'LIMON INDANZA',
    territory3Id: '1196',
    territory3Name: 'LIMON INDANZA',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|LOGRONO|:|LOGROÑO',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '191',
    territory2Name: 'LOGRONO',
    territory3Id: '1151',
    territory3Name: 'LOGROÑO',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|GENERAL_PROAÑO',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '182',
    territory2Name: 'MACAS (MORONA)',
    territory3Id: '2159',
    territory3Name: 'GENERAL PROAÑO',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|MACAS',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '182',
    territory2Name: 'MACAS (MORONA)',
    territory3Id: '742',
    territory3Name: 'MACAS',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|RIO_BLANCO',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '182',
    territory2Name: 'MACAS (MORONA)',
    territory3Id: '2165',
    territory3Name: 'RIO BLANCO',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|SEVILLA_DON_BOSCO',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '182',
    territory2Name: 'MACAS (MORONA)',
    territory3Id: '2161',
    territory3Name: 'SEVILLA DON BOSCO',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|SINAI',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '182',
    territory2Name: 'MACAS (MORONA)',
    territory3Id: '2162',
    territory3Name: 'SINAI',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|PABLO_SEXTO|:|PABLO_SEXTO',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '192',
    territory2Name: 'PABLO SEXTO',
    territory3Id: '2209',
    territory3Name: 'PABLO SEXTO',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|PALORA|:|PALORA_(METZERA)',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '185',
    territory2Name: 'PALORA',
    territory3Id: '2181',
    territory3Name: 'PALORA (METZERA)',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SAN_JUAN_BOSCO|:|SAN_JUAN_BOSCO',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '189',
    territory2Name: 'SAN JUAN BOSCO',
    territory3Id: '2197',
    territory3Name: 'SAN JUAN BOSCO',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SANTIAGO|:|SANTIAGO_DE_MENDEZ',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '186',
    territory2Name: 'SANTIAGO',
    territory3Id: '1152',
    territory3Name: 'SANTIAGO DE MENDEZ',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SUCUA|:|SANTA_MARIANITA_DE_JESUS_(MS)',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '187',
    territory2Name: 'SUCUA',
    territory3Id: '2194',
    territory3Name: 'SANTA MARIANITA DE JESUS (MS)',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SUCUA|:|SUCUA',
    territory1Id: '16',
    territory1Name: 'MORONA SANTIAGO',
    territory2Id: '187',
    territory2Name: 'SUCUA',
    territory3Id: '778',
    territory3Name: 'SUCUA',
  },
  // {
  //   territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|TAISHA|:|TAISHA',
  //   territory1Id: '16',
  //   territory1Name: 'MORONA SANTIAGO',
  //   territory2Id: '190',
  //   territory2Name: 'TAISHA',
  //   territory3Id: '190',
  //   territory3Name: 'TAISHA',
  // },
  // {
  //   territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|TIWINTZA|:|TIWINTZA',
  //   territory1Id: '16',
  //   territory1Name: 'MORONA SANTIAGO',
  //   territory2Id: '193',
  //   territory2Name: 'TIWINTZA',
  //   territory3Id: '193',
  //   territory3Name: 'TIWINTZA',
  // },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|ARCHIDONA',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '195',
    territory2Name: 'ARCHIDONA',
    territory3Id: '796',
    territory3Name: 'ARCHIDONA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|COTUNDO',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '195',
    territory2Name: 'ARCHIDONA',
    territory3Id: '2217',
    territory3Name: 'COTUNDO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|NUEVA_ESPERANZA',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '195',
    territory2Name: 'ARCHIDONA',
    territory3Id: '796',
    territory3Name: 'NUEVA ESPERANZA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|CARLOS_JULIO_AROSEMENA_TOLA|:|CARLOS_JULIO_AROSEMENA_TOLA',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '198',
    territory2Name: 'CARLOS JULIO AROSEMENA TOLA',
    territory3Id: '799',
    territory3Name: 'CARLOS JULIO AROSEMENA TOLA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|CARLOS_JULIO_AROSEMENA_TOLA|:|TAZAYACU',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '198',
    territory2Name: 'CARLOS JULIO AROSEMENA TOLA',
    territory3Id: '799',
    territory3Name: 'TAZAYACU',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_CHACO|:|EL_CHACO',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '196',
    territory2Name: 'EL CHACO',
    territory3Id: '961',
    territory3Name: 'EL CHACO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|EL_TENA',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '194',
    territory2Name: 'EL TENA',
    territory3Id: '784',
    territory3Name: 'EL TENA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|GONZALO_PIZARRO',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '194',
    territory2Name: 'EL TENA',
    territory3Id: '784',
    territory3Name: 'GONZALO PIZARRO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|PUERTO_NAPO',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '194',
    territory2Name: 'EL TENA',
    territory3Id: '784',
    territory3Name: 'PUERTO NAPO',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|SAN_JUAN_DE_MUYUNA',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '194',
    territory2Name: 'EL TENA',
    territory3Id: '2216',
    territory3Name: 'SAN JUAN DE MUYUNA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|QUIJOS|:|BAEZA',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '197',
    territory2Name: 'QUIJOS',
    territory3Id: '962',
    territory3Name: 'BAEZA',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|QUIJOS|:|SAN_FRANCISCO_DE_BORJA_(VIRGILIO_DAVILA)',
    territory1Id: '17',
    territory1Name: 'NAPO',
    territory2Id: '197',
    territory2Name: 'QUIJOS',
    territory3Id: '963',
    territory3Name: 'SAN FRANCISCO DE BORJA (VIRGILIO DAVILA)',
  },
  // {
  //   territoryBaseId: 'EC|:|ORELLANA|:|AGUARICO|:|AGUARICO',
  //   territory1Id: '24',
  //   territory1Name: 'ORELLANA',
  //   territory2Id: '233',
  //   territory2Name: 'AGUARICO',
  //   territory3Id: '233',
  //   territory3Name: 'AGUARICO',
  // },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|ALEJANDRO_LABAKA',
    territory1Id: '24',
    territory1Name: 'ORELLANA',
    territory2Id: '232',
    territory2Name: 'EL COCA',
    territory3Id: '2333',
    territory3Name: 'ALEJANDRO LABAKA',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|EL_COCA',
    territory1Id: '24',
    territory1Name: 'ORELLANA',
    territory2Id: '232',
    territory2Name: 'EL COCA',
    territory3Id: '922',
    territory3Name: 'EL COCA',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|EL_DORADO',
    territory1Id: '24',
    territory1Name: 'ORELLANA',
    territory2Id: '232',
    territory2Name: 'EL COCA',
    territory3Id: '2334',
    territory3Name: 'EL DORADO',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LA_JOYA_DE_LOS_SACHAS|:|LA_JOYA_DE_LOS_SACHAS',
    territory1Id: '24',
    territory1Name: 'ORELLANA',
    territory2Id: '234',
    territory2Name: 'LA JOYA DE LOS SACHAS',
    territory3Id: '924',
    territory3Name: 'LA JOYA DE LOS SACHAS',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LA_JOYA_DE_LOS_SACHAS|:|SAN_SEBASTIAN_(CAÑON_DE_LOS_MONOS)',
    territory1Id: '24',
    territory1Name: 'ORELLANA',
    territory2Id: '234',
    territory2Name: 'LA JOYA DE LOS SACHAS',
    territory3Id: '2351',
    territory3Name: 'SAN SEBASTIAN (CAÑON DE LOS MONOS)',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LORETO|:|LORETO',
    territory1Id: '24',
    territory1Name: 'ORELLANA',
    territory2Id: '235',
    territory2Name: 'LORETO',
    territory3Id: '1070',
    territory3Name: 'LORETO',
  },
  // {
  //   territoryBaseId: 'EC|:|PASTAZA|:|ARAJUNO|:|ARAJUNO',
  //   territory1Id: '18',
  //   territory1Name: 'PASTAZA',
  //   territory2Id: '202',
  //   territory2Name: 'ARAJUNO',
  //   territory3Id: '202',
  //   territory3Name: 'ARAJUNO',
  // },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|EL_PUYO',
    territory1Id: '18',
    territory1Name: 'PASTAZA',
    territory2Id: '199',
    territory2Name: 'EL PUYO',
    territory3Id: '806',
    territory3Name: 'EL PUYO',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|FATIMA',
    territory1Id: '18',
    territory1Name: 'PASTAZA',
    territory2Id: '199',
    territory2Name: 'EL PUYO',
    territory3Id: '806',
    territory3Name: 'FATIMA',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|TARQUI_(PA)',
    territory1Id: '18',
    territory1Name: 'PASTAZA',
    territory2Id: '199',
    territory2Name: 'EL PUYO',
    territory3Id: '809',
    territory3Name: 'TARQUI (PA)',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|MERA|:|MERA',
    territory1Id: '18',
    territory1Name: 'PASTAZA',
    territory2Id: '200',
    territory2Name: 'MERA',
    territory3Id: '815',
    territory3Name: 'MERA',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|MERA|:|SHELL',
    territory1Id: '18',
    territory1Name: 'PASTAZA',
    territory2Id: '200',
    territory2Name: 'MERA',
    territory3Id: '819',
    territory3Name: 'SHELL',
  },
  // {
  //   territoryBaseId: 'EC|:|PASTAZA|:|SANTA_CLARA|:|SANTA_CLARA',
  //   territory1Id: '18',
  //   territory1Name: 'PASTAZA',
  //   territory2Id: '201',
  //   territory2Name: 'SANTA CLARA',
  //   territory3Id: '201',
  //   territory3Name: 'SANTA CLARA',
  // },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|ASCAZUBI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '236',
    territory2Name: 'CAYAMBE',
    territory3Id: '968',
    territory3Name: 'ASCAZUBI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|CAYAMBE',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '236',
    territory2Name: 'CAYAMBE',
    territory3Id: '965',
    territory3Name: 'CAYAMBE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|JUAN_MONTALVO_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '236',
    territory2Name: 'CAYAMBE',
    territory3Id: '967',
    territory3Name: 'JUAN MONTALVO (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|SAN_JOSE_DE_AYORA_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '236',
    territory2Name: 'CAYAMBE',
    territory3Id: '966',
    territory3Name: 'SAN JOSE DE AYORA (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|SANTA_CLARA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '236',
    territory2Name: 'CAYAMBE',
    territory3Id: '1077',
    territory3Name: 'SANTA CLARA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|SANTA_ROSA_DE_CUZUBAMBA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '236',
    territory2Name: 'CAYAMBE',
    territory3Id: '1018',
    territory3Name: 'SANTA ROSA DE CUZUBAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|ALOAG',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '972',
    territory3Name: 'ALOAG',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|ALOASI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '2248',
    territory3Name: 'ALOASI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|CUTUGLAHUA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '969',
    territory3Name: 'CUTUGLAHUA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|MACHACHI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '971',
    territory3Name: 'MACHACHI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|MEJIA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '971',
    territory3Name: 'MEJIA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|SANTA_ROSA_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '1039',
    territory3Name: 'SANTA ROSA (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|TAMBILLO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '970',
    territory3Name: 'TAMBILLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|TANDAPI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '973',
    territory3Name: 'TANDAPI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|UYUMBICHO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '237',
    territory2Name: 'MEJIA',
    territory3Id: '1015',
    territory3Name: 'UYUMBICHO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_MONCAYO_(TABACUNDO)|:|TABACUNDO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '243',
    territory2Name: 'PEDRO MONCAYO (TABACUNDO)',
    territory3Id: '974',
    territory3Name: 'TABACUNDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_VICENTE_MALDONADO|:|PEDRO_VICENTE_MALDONADO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '250',
    territory2Name: 'PEDRO VICENTE MALDONADO',
    territory3Id: '981',
    territory3Name: 'PEDRO VICENTE MALDONADO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PUERTO_QUITO|:|PUERTO_QUITO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '254',
    territory2Name: 'PUERTO QUITO',
    territory3Id: '982',
    territory3Name: 'PUERTO QUITO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ALANGASI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '342',
    territory3Name: 'ALANGASI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|AMAGUAÑA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '343',
    territory3Name: 'AMAGUAÑA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|BELISARIO_QUEVEDO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '291',
    territory3Name: 'BELISARIO QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CALACALI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '346',
    territory3Name: 'CALACALI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CALDERON_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '348',
    territory3Name: 'CALDERON (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CARCELEN',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '292',
    territory3Name: 'CARCELEN',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CENTRO_HISTORICO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '293',
    territory3Name: 'CENTRO HISTORICO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHECA_(CHILPA)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '356',
    territory3Name: 'CHECA (CHILPA)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHILIBULO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '301',
    territory3Name: 'CHILIBULO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHILLOGALLO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '302',
    territory3Name: 'CHILLOGALLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHIMBACALLE',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '304',
    territory3Name: 'CHIMBACALLE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|COCHAPAMBA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '294',
    territory3Name: 'COCHAPAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|COMITE_DEL_PUEBLO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '297',
    territory3Name: 'COMITE DEL PUEBLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CONOCOTO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '351',
    territory3Name: 'CONOCOTO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|COTOCOLLAO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '299',
    territory3Name: 'COTOCOLLAO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CUMBAYA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '352',
    territory3Name: 'CUMBAYA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CUNUYACU',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '1032',
    territory3Name: 'CUNUYACU',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|EL_CONDADO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '305',
    territory3Name: 'EL CONDADO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|EL_QUINCHE',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '358',
    territory3Name: 'EL QUINCHE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUAMANI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '306',
    territory3Name: 'GUAMANI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUANGOPOLO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '360',
    territory3Name: 'GUANGOPOLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUAYLLABAMBA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '361',
    territory3Name: 'GUAYLLABAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ILALO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '1004',
    territory3Name: 'ILALO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|INAUIO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '309',
    territory3Name: 'INAUIO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ITCHIMBIA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '310',
    territory3Name: 'ITCHIMBIA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ITULCACHI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '998',
    territory3Name: 'ITULCACHI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|JIPIJAPA_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '315',
    territory3Name: 'JIPIJAPA (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|KENNEDY',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '316',
    territory3Name: 'KENNEDY',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ARGELIA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '317',
    territory3Name: 'LA ARGELIA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ARMENIA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'LA ARMENIA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_CONCEPCION',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '318',
    territory3Name: 'LA CONCEPCION',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ECUATORIANA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '319',
    territory3Name: 'LA ECUATORIANA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_FERROVIARIA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '320',
    territory3Name: 'LA FERROVIARIA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_LIBERTAD_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '321',
    territory3Name: 'LA LIBERTAD (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_MAGDALENA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '322',
    territory3Name: 'LA MAGDALENA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_MENA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '323',
    territory3Name: 'LA MENA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_MERCED_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '363',
    territory3Name: 'LA MERCED (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LLANO_CHICO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '366',
    territory3Name: 'LLANO CHICO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LLANO_GRANDE',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'LLANO GRANDE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MARISCAL_SUCRE_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '325',
    territory3Name: 'MARISCAL SUCRE (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MIRAVALLE',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'MIRAVALLE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MITAD_DEL_MUNDO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'MITAD DEL MUNDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MONJAS',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'MONJAS',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|NANEGAL',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'NANEGAL',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|NANEGALITO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '2246',
    territory3Name: 'NANEGALITO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|NAYON',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '370',
    territory3Name: 'NAYON',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PIFO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '375',
    territory3Name: 'PIFO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PINTAG',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '377',
    territory3Name: 'PINTAG',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|POMASQUI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '378',
    territory3Name: 'POMASQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PONCEANO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '327',
    territory3Name: 'PONCEANO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUEMBO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '381',
    territory3Name: 'PUEMBO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUENGASI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '328',
    territory3Name: 'PUENGASI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUSUQUI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'PUSUQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'QUITO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITUMBE',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '329',
    territory3Name: 'QUITUMBE',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|RUMIPAMBA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '331',
    territory3Name: 'RUMIPAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_ANTONIO_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '383',
    territory3Name: 'SAN ANTONIO (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_BARTOLO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '333',
    territory3Name: 'SAN BARTOLO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_ISIDRO_DEL_INCA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '334',
    territory3Name: 'SAN ISIDRO DEL INCA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_JOSE_DE_MORAN',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'SAN JOSE DE MORAN',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_JUAN_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '336',
    territory3Name: 'SAN JUAN (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_JUAN_DE_CALDERON',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '290',
    territory3Name: 'SAN JUAN DE CALDERON',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SILLUNCHI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '1011',
    territory3Name: 'SILLUNCHI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SOLANDA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '338',
    territory3Name: 'SOLANDA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TABABELA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '385',
    territory3Name: 'TABABELA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TUMBACO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '386',
    territory3Name: 'TUMBACO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TURUBAMBA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '340',
    territory3Name: 'TURUBAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|YARUQUI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '387',
    territory3Name: 'YARUQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ZAMBIZA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '211',
    territory2Name: 'QUITO',
    territory3Id: '388',
    territory3Name: 'ZAMBIZA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|COTOGCHOA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '244',
    territory2Name: 'RUMINAHUI',
    territory3Id: '2252',
    territory3Name: 'COTOGCHOA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|FAJARDO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '244',
    territory2Name: 'RUMINAHUI',
    territory3Id: '2251',
    territory3Name: 'FAJARDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|RUMIPAMBA',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '244',
    territory2Name: 'RUMINAHUI',
    territory3Id: '2253',
    territory3Name: 'RUMIPAMBA',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SAN_PEDRO_DE_TABOADA_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '244',
    territory2Name: 'RUMINAHUI',
    territory3Id: '978',
    territory3Name: 'SAN PEDRO DE TABOADA (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SAN_RAFAEL_(PI)',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '244',
    territory2Name: 'RUMINAHUI',
    territory3Id: '977',
    territory3Name: 'SAN RAFAEL (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SANGOLQUI',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '244',
    territory2Name: 'RUMINAHUI',
    territory3Id: '976',
    territory3Name: 'SANGOLQUI',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|SAN_MIGUEL_DE_LOS_BANCOS|:|MINDO',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '247',
    territory2Name: 'SAN MIGUEL DE LOS BANCOS',
    territory3Id: '980',
    territory3Name: 'MINDO',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|SAN_MIGUEL_DE_LOS_BANCOS|:|SAN_MIGUEL_DE_LOS_BANCOS',
    territory1Id: '19',
    territory1Name: 'PICHINCHA',
    territory2Id: '247',
    territory2Name: 'SAN MIGUEL DE LOS BANCOS',
    territory3Id: '979',
    territory3Name: 'SAN MIGUEL DE LOS BANCOS',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|LA_LIBERTAD|:|LA_LIBERTAD',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '241',
    territory2Name: 'LA LIBERTAD',
    territory3Id: '934',
    territory3Name: 'LA LIBERTAD',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|LA_LIBERTAD|:|PUNTA_CENTINELA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '241',
    territory2Name: 'LA LIBERTAD',
    territory3Id: '1157',
    territory3Name: 'PUNTA CENTINELA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|ANCONCITO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '941',
    territory3Name: 'ANCONCITO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|CARLOS_ESPINOZA_LARREA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '942',
    territory3Name: 'CARLOS ESPINOZA LARREA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|GENERAL_ALBERTO_ENRIQUEZ_GALLO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '943',
    territory3Name: 'GENERAL ALBERTO ENRIQUEZ GALLO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|JOSE_LUIS_TAMAYO_(MUEY)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '940',
    territory3Name: 'JOSE LUIS TAMAYO (MUEY)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|PUNTA_CARNERO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '1005',
    territory3Name: 'PUNTA CARNERO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|SALINAS_(SE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '2362',
    territory3Name: 'SALINAS (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|SANTA_ROSA_(SE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '938',
    territory3Name: 'SANTA ROSA (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|VICENTE__ROCAFUERTE_(SE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '242',
    territory2Name: 'SALINAS',
    territory3Id: '944',
    territory3Name: 'VICENTE  ROCAFUERTE (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|ANCON_(SE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '931',
    territory3Name: 'ANCON (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|ATAHUALPA_(SE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '926',
    territory3Name: 'ATAHUALPA (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|AYANGUE',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'AYANGUE',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|BALLENITA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '925',
    territory3Name: 'BALLENITA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|BARCELONA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'BARCELONA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CADEATE',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'CADEATE',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CAPAES',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'CAPAES',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CHANDUY',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '927',
    territory3Name: 'CHANDUY',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|COLONCHE_(AYANQUE_/_JAMBELI_/_MONTEVERDE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '933',
    territory3Name: 'COLONCHE (AYANQUE / JAMBELI / MONTEVERDE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CURIA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'CURIA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|EL_TAMBO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'EL TAMBO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|LA_ENTRADA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'LA ENTRADA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|LIBERTADOR_BOLIVAR',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'LIBERTADOR BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|MANGLARALTO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '928',
    territory3Name: 'MANGLARALTO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|MONTANITA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'MONTANITA',
  },
  // {
  //   territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|OLON',
  //   territory1Id: '26',
  //   territory1Name: 'SANTA ELENA',
  //   territory2Id: '240',
  //   territory2Name: 'SANTA ELENA',
  //   territory3Id: '923',
  //   territory3Name: 'OLON',
  // },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|OLONCITO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'OLONCITO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PALMAR',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'PALMAR',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PROSPERIDAD',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'PROSPERIDAD',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PUNTA_BARANDUA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'PUNTA BARANDUA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PUNTA_BLANCA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'PUNTA BLANCA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SAN_JOSE',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'SAN JOSE',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SAN_PABLO_(SE)',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'SAN PABLO (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SAN_PEDRO',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'SAN PEDRO',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SANTA_ELENA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'SANTA ELENA',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SINCHAL',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'SINCHAL',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|VALDIVIA',
    territory1Id: '26',
    territory1Name: 'SANTA ELENA',
    territory2Id: '240',
    territory2Name: 'SANTA ELENA',
    territory3Id: '923',
    territory3Name: 'VALDIVIA',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LA_CONCORDIA|:|LA_CONCORDIA',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '239',
    territory2Name: 'LA CONCORDIA',
    territory3Id: '953',
    territory3Name: 'LA CONCORDIA',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LA_UNION|:|LA_UNION',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '1193',
    territory2Name: 'LA UNION',
    territory3Id: '1194',
    territory3Name: 'LA UNION',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LAS_MERCEDES|:|LIBERTAD_DEL_TOACHI',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '2403',
    territory2Name: 'LAS MERCEDES',
    territory3Id: '2404',
    territory3Name: 'LIBERTAD DEL TOACHI',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|ABRAHAM_CALAZACON',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '945',
    territory3Name: 'ABRAHAM CALAZACON',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|ALLURIQUIN',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '935',
    territory3Name: 'ALLURIQUIN',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|BOMBOLI',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '946',
    territory3Name: 'BOMBOLI',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|CHIGUILPE',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '930',
    territory3Name: 'CHIGUILPE',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|EL_CADE',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '2374',
    territory3Name: 'EL CADE',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|EL_ESFUERZO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '951',
    territory3Name: 'EL ESFUERZO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|INAEXPO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '2375',
    territory3Name: 'INAEXPO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_14_VIA_QUEVEDO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'KM 14 QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_24_VIA_QUEVEDO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'KM 24 QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_38.5_VIA_QUEVEDO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'KM 38.5 VIA QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_41_VIA_QUEVEDO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'KM 41 VIA QUEVEDO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|LAS_DELICIAS',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'LAS DELICIAS',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|LUZ_DE_AMERICA_(KM26)',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '937',
    territory3Name: 'LUZ DE AMERICA (KM26)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|NUEVO_ISRAEL',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'NUEVO ISRAEL',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|PUERTO_LIMON_(SD)',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '936',
    territory3Name: 'PUERTO LIMON (SD)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|RIO_TOACHI',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '947',
    territory3Name: 'RIO TOACHI',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|RIO_VERDE_(SD)',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '948',
    territory3Name: 'RIO VERDE (SD)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|SAN_JACINTO_DEL_BUA_(SD)',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '939',
    territory3Name: 'SAN JACINTO DEL BUA (SD)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|SANTO_DOMINGO_DE_LOS_COLORADOS',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '949',
    territory3Name: 'SANTO DOMINGO DE LOS COLORADOS',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|VALLE_HERMOSO',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '932',
    territory3Name: 'VALLE HERMOSO',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|ZARACAY',
    territory1Id: '25',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Id: '238',
    territory2Name: 'SANTO DOMINGO',
    territory3Id: '950',
    territory3Name: 'ZARACAY',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|CASCALES|:|CASCALES',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '230',
    territory2Name: 'CASCALES',
    territory3Id: '1013',
    territory3Name: 'CASCALES',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|CASCALES|:|SEVILLA',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '230',
    territory2Name: 'CASCALES',
    territory3Id: '2327',
    territory3Name: 'SEVILLA',
  },
  // {
  //   territoryBaseId: 'EC|:|SUCUMBIOS|:|CUYABENO|:|CUYABENO',
  //   territory1Id: '23',
  //   territory1Name: 'SUCUMBIOS',
  //   territory2Id: '231',
  //   territory2Name: 'CUYABENO',
  //   territory3Id: '231',
  //   territory3Name: 'CUYABENO',
  // },
  // {
  //   territoryBaseId: 'EC|:|SUCUMBIOS|:|CUYABENO|:|TARAPOA',
  //   territory1Id: '23',
  //   territory1Name: 'SUCUMBIOS',
  //   territory2Id: '231',
  //   territory2Name: 'CUYABENO',
  //   territory3Id: '231',
  //   territory3Name: 'TARAPOA',
  // },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|EL_ENO',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '225',
    territory2Name: 'LAGO AGRIO',
    territory3Id: '1014',
    territory3Name: 'EL ENO',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|NUEVA_LOJA_(LAGO_AGRIO)',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '225',
    territory2Name: 'LAGO AGRIO',
    territory3Id: '915',
    territory3Name: 'NUEVA LOJA (LAGO AGRIO)',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|PACAYACU',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '225',
    territory2Name: 'LAGO AGRIO',
    territory3Id: '915',
    territory3Name: 'PACAYACU',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|SANTA_CECILIA',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '225',
    territory2Name: 'LAGO AGRIO',
    territory3Id: '911',
    territory3Name: 'SANTA CECILIA',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LUMBAQUI_(GONZALO_PIZARRO)|:|GONZALO_PIZARRO',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '226',
    territory2Name: 'LUMBAQUI (GONZALO PIZARRO)',
    territory3Id: '2312',
    territory3Name: 'GONZALO PIZARRO',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LUMBAQUI_(GONZALO_PIZARRO)|:|LUMBAQUI',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '226',
    territory2Name: 'LUMBAQUI (GONZALO PIZARRO)',
    territory3Id: '2311',
    territory3Name: 'LUMBAQUI',
  },
  // {
  //   territoryBaseId: 'EC|:|SUCUMBIOS|:|PUTUMAYO|:|PUTUMAYO',
  //   territory1Id: '23',
  //   territory1Name: 'SUCUMBIOS',
  //   territory2Id: '227',
  //   territory2Name: 'PUTUMAYO',
  //   territory3Id: '227',
  //   territory3Name: 'PUTUMAYO',
  // },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|JIVINO_VERDE',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '228',
    territory2Name: 'SHUSHUFINDI',
    territory3Id: '919',
    territory3Name: 'JIVINO VERDE',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SAN_PEDRO_DE_LOS_COFANES',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '228',
    territory2Name: 'SHUSHUFINDI',
    territory3Id: '921',
    territory3Name: 'SAN PEDRO DE LOS COFANES',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SHUSHUFINDI',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '228',
    territory2Name: 'SHUSHUFINDI',
    territory3Id: '919',
    territory3Name: 'SHUSHUFINDI',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SIETE_DE_JULIO',
    territory1Id: '23',
    territory1Name: 'SUCUMBIOS',
    territory2Id: '228',
    territory2Name: 'SHUSHUFINDI',
    territory3Id: '2320',
    territory3Name: 'SIETE DE JULIO',
  },
  // {
  //   territoryBaseId: 'EC|:|SUCUMBIOS|:|SUCUMBIOS|:|SUCUMBIOS',
  //   territory1Id: '23',
  //   territory1Name: 'SUCUMBIOS',
  //   territory2Id: '229',
  //   territory2Name: 'SUCUMBIOS',
  //   territory3Id: '229',
  //   territory3Name: 'SUCUMBIOS',
  // },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AMBATILLO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '983',
    territory3Name: 'AMBATILLO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AMBATO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '983',
    territory3Name: 'AMBATO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|ATAHUALPA_(CHISALATA)_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '854',
    territory3Name: 'ATAHUALPA (CHISALATA) (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|ATOCHA_FICOA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '836',
    territory3Name: 'ATOCHA FICOA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AUGUSTO_N._MARTINEZ_(MUNDUGLEO)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '857',
    territory3Name: 'AUGUSTO N. MARTINEZ (MUNDUGLEO)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|CELIANO_MONGE',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '839',
    territory3Name: 'CELIANO MONGE',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|HUACHI_CHICO_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '842',
    territory3Name: 'HUACHI CHICO (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|HUACHI_GRANDE_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '860',
    territory3Name: 'HUACHI GRANDE (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|HUACHI_LORETO_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '843',
    territory3Name: 'HUACHI LORETO (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|IZAMBA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '824',
    territory3Name: 'IZAMBA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|LA_MERCED_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '845',
    territory3Name: 'LA MERCED (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|LA_PENINSULA_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '848',
    territory3Name: 'LA PENINSULA (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|MATRIZ_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '850',
    territory3Name: 'MATRIZ (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|MONTALVO_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '2256',
    territory3Name: 'MONTALVO (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|PICAIGUA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '2258',
    territory3Name: 'PICAIGUA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|PISHILATA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '851',
    territory3Name: 'PISHILATA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|QUISAPINCHA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '983',
    territory3Name: 'QUISAPINCHA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|SAN_BARTOLOME_DE_PINLLO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '865',
    territory3Name: 'SAN BARTOLOME DE PINLLO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|SAN_FRANCISCO_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '852',
    territory3Name: 'SAN FRANCISCO (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|SANTA_ROSA_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '826',
    territory3Name: 'SANTA ROSA (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|TOTORAS',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '203',
    territory2Name: 'AMBATO',
    territory3Id: '827',
    territory3Name: 'TOTORAS',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|BAÑOS_DE_AGUA_SANTA_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '204',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Id: '883',
    territory3Name: 'BAÑOS DE AGUA SANTA (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|LLIGUA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '204',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Id: '2261',
    territory3Name: 'LLIGUA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|RIO_NEGRO_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '204',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Id: '2262',
    territory3Name: 'RIO NEGRO (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|RIO_VERDE_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '204',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Id: '2263',
    territory3Name: 'RIO VERDE (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|ULBA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '204',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Id: '884',
    territory3Name: 'ULBA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|CEVALLOS|:|CEVALLOS',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '205',
    territory2Name: 'CEVALLOS',
    territory3Id: '885',
    territory3Name: 'CEVALLOS',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|MOCHA|:|MOCHA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '206',
    territory2Name: 'MOCHA',
    territory3Id: '889',
    territory3Name: 'MOCHA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|PATATE|:|PATATE',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '207',
    territory2Name: 'PATATE',
    territory3Id: '890',
    territory3Name: 'PATATE',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|QUERO|:|QUERO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '208',
    territory2Name: 'QUERO',
    territory3Id: '893',
    territory3Name: 'QUERO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|BENITEZ_(PACHANLICA)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2270',
    territory3Name: 'BENITEZ (PACHANLICA)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|BOLIVAR',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2271',
    territory3Name: 'BOLIVAR',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|CHIQUICHA_(CAB._EN_CHIQUICHA_GRANDE)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2273',
    territory3Name: 'CHIQUICHA (CAB. EN CHIQUICHA GRANDE)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|COTALO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2272',
    territory3Name: 'COTALO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|EL_ROSARIO_(RUMICHACA)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2274',
    territory3Name: 'EL ROSARIO (RUMICHACA)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|GARCIA_MORENO_(CHUMAQUI)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2275',
    territory3Name: 'GARCIA MORENO (CHUMAQUI)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|HUAMBALO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2276',
    territory3Name: 'HUAMBALO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|PELILEO_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '901',
    territory3Name: 'PELILEO (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|PELILEO_GRANDE_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2269',
    territory3Name: 'PELILEO GRANDE (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|SALASACA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '209',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Id: '2277',
    territory3Name: 'SALASACA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|BAQUERIZO_MORENO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '2278',
    territory3Name: 'BAQUERIZO MORENO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|CIUDAD_NUEVA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '904',
    territory3Name: 'CIUDAD NUEVA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|EMILIO_MARIA_TERAN',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '903',
    territory3Name: 'EMILIO MARIA TERAN',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|MARCOS_ESPINEL',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '2279',
    territory3Name: 'MARCOS ESPINEL',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|PILLARO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '984',
    territory3Name: 'PILLARO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|PRESIDENTE_URBINA',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '2280',
    territory3Name: 'PRESIDENTE URBINA',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|SAN_ANDRES_(TU)',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '2281',
    territory3Name: 'SAN ANDRES (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|SAN_MIGUELITO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '210',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Id: '2283',
    territory3Name: 'SAN MIGUELITO',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|TISALEO|:|TISALEO',
    territory1Id: '20',
    territory1Name: 'TUNGURAHUA',
    territory2Id: '212',
    territory2Name: 'TISALEO',
    territory3Id: '908',
    territory3Name: 'TISALEO',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|CENTINELA_DEL_CONDOR|:|ZUMBI',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '219',
    territory2Name: 'CENTINELA DEL CONDOR',
    territory3Id: '881',
    territory3Name: 'ZUMBI',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|CHINCHIPE|:|ZUMBA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '214',
    territory2Name: 'CHINCHIPE',
    territory3Id: '1180',
    territory3Name: 'ZUMBA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|EL_PANGUI|:|EL_PANGUI',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '218',
    territory2Name: 'EL PANGUI',
    territory3Id: '986',
    territory3Name: 'EL PANGUI',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|EL_PANGUI|:|PACHICUTZA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '218',
    territory2Name: 'EL PANGUI',
    territory3Id: '988',
    territory3Name: 'PACHICUTZA',
  },
  // {
  //   territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|NANGARITZA|:|NANGARITZA',
  //   territory1Id: '21',
  //   territory1Name: 'ZAMORA CHINCHIPE',
  //   territory2Id: '215',
  //   territory2Name: 'NANGARITZA',
  //   territory3Id: '215',
  //   territory3Name: 'NANGARITZA',
  // },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|PALANDA|:|PALANDA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '220',
    territory2Name: 'PALANDA',
    territory3Id: '1058',
    territory3Name: 'PALANDA',
  },
  // {
  //   territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|PAQUISHA|:|PAQUISHA',
  //   territory1Id: '21',
  //   territory1Name: 'ZAMORA CHINCHIPE',
  //   territory2Id: '221',
  //   territory2Name: 'PAQUISHA',
  //   territory3Id: '221',
  //   territory3Name: 'PAQUISHA',
  // },
  // {
  //   territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YACUAMBI|:|YACUAMBI',
  //   territory1Id: '21',
  //   territory1Name: 'ZAMORA CHINCHIPE',
  //   territory2Id: '216',
  //   territory2Name: 'YACUAMBI',
  //   territory3Id: '216',
  //   territory3Name: 'YACUAMBI',
  // },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YANTZAZA|:|LOS_ENCUENTROS',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '217',
    territory2Name: 'YANTZAZA',
    territory3Id: '987',
    territory3Name: 'LOS ENCUENTROS',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YANTZAZA|:|YANTZAZA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '217',
    territory2Name: 'YANTZAZA',
    territory3Id: '877',
    territory3Name: 'YANTZAZA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|CHAMICO',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '2396',
    territory2Name: 'ZAMORA',
    territory3Id: '858',
    territory3Name: 'CHAMICO',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|CUMBARATZA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '2396',
    territory2Name: 'ZAMORA',
    territory3Id: '2397',
    territory3Name: 'CUMBARATZA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|EL_LIMON',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '213',
    territory2Name: 'ZAMORA',
    territory3Id: '985',
    territory3Name: 'EL LIMON',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|GUADALUPE',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '2396',
    territory2Name: 'ZAMORA',
    territory3Id: '858',
    territory3Name: 'GUADALUPE',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|GUAYZIMI',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '2396',
    territory2Name: 'ZAMORA',
    territory3Id: '858',
    territory3Name: 'GUAYZIMI',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|NAMIREZ',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '2396',
    territory2Name: 'ZAMORA',
    territory3Id: '858',
    territory3Name: 'NAMIREZ',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|PIUNTZA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '2396',
    territory2Name: 'ZAMORA',
    territory3Id: '858',
    territory3Name: 'PIUNTZA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|TIMBARA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '213',
    territory2Name: 'ZAMORA',
    territory3Id: '867',
    territory3Name: 'TIMBARA',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|ZAMORA',
    territory1Id: '21',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Id: '213',
    territory2Name: 'ZAMORA',
    territory3Id: '858',
    territory3Name: 'ZAMORA',
  },
];
