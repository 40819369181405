import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowShipmentTrackingOverviewIntentPayload {
  shipmentObjectId?: number;
}

export class ShowShipmentTrackingOverviewIntent extends NavigationIntent {
  constructor(public payload: ShowShipmentTrackingOverviewIntentPayload = {}) {
    super();
  }
}
