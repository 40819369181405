import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {ENVIRONMENT_INITIALIZER, FactoryProvider, InjectionToken, PLATFORM_ID, ValueProvider} from '@angular/core';

function extAppScript(apiKey: string): string {
  return (
    '(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({' +
    `key: "${apiKey}",` +
    'v: "quarterly",' +
    '});'
  );
}

const BSZ_GOOGLE_MAPS_CONFIG_TOKEN = new InjectionToken<BszGoogleMapsConfiguration>('bsz-google-maps.config');

export type BszGoogleMapsConfiguration = Readonly<{
  /**
   * Whether or not to inject this script on application initialization.
   */
  enabled: boolean;
  /**
   * The API Key.
   */
  apiKey: string;
}>;

/**
 * Builds configured providers for injecting External Application scripts during
 * application initialization.
 * @param config The runtime configuration for the External Application providers.
 * @returns the External Application providers.
 */
export function googleMapsProvider(config: BszGoogleMapsConfiguration): (ValueProvider | FactoryProvider)[] {
  // return makeEnvironmentProviders([
  return [
    {provide: BSZ_GOOGLE_MAPS_CONFIG_TOKEN, useValue: config},
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (platformId: Object, d: Document, {enabled, apiKey}: BszGoogleMapsConfiguration) => {
        return () => {
          if (isPlatformBrowser(platformId) && enabled) {
            // script
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = extAppScript(apiKey);
            d.body.appendChild(s);
          }
        };
      },
      deps: [PLATFORM_ID, DOCUMENT, BSZ_GOOGLE_MAPS_CONFIG_TOKEN],
      multi: true,
    },
  ];
}
