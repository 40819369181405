import {BszManifestFormAction, BszShipmentAddressFormAction, BszShipmentFormAction} from '@basuiz/web-app-applet-api';
import * as intents from '../../intents/intents.index';
import {PortalPage} from '../../navigation/classes/portal-page';
import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import * as pages from '../../pages/pages.index';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';

export function shipmentDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(intents.CreateShipmentIntent, createShipmentHandler);
  addHandler(intents.ShowShipmentOverviewIntent, () => new pages.ShipmentOverviewPage());
  addHandler(
    intents.ShowShipmentAddressOverviewIntent,
    (intent) => new pages.ShipmentAddressOverviewPage({tab: intent.payload.tab})
  );
  addHandler(intents.EntryShipmentAddressIntent, entryShipmentAddressHandler);
  addHandler(intents.ShowManifestOverviewIntent, () => new pages.ManifestOverviewPage());
  addHandler(intents.EntryManifestIntent, entryManifestHandler);
  addHandler(intents.ShowPriceCalculatorIntent, () => new pages.PriceCalculatorPage());
  addHandler(
    intents.ShowShipmentTrackingOverviewIntent,
    (intent) => new pages.ShipmentTrackingOverviewPage({shipmentObjectId: intent.payload.shipmentObjectId})
  );
}

function createShipmentHandler({payload}: intents.CreateShipmentIntent): PortalPage {
  let shipmentFormAction: BszShipmentFormAction;

  if (intents.isCreateShipmentFromPartialIntentPayload(payload)) {
    shipmentFormAction = {
      action: 'createFromPartial',
      shipmentId: payload.shipmentId,
    };
  } else {
    shipmentFormAction = {
      action: 'create',
    };
  }

  return new pages.ShipmentEntryPage({shipmentFormAction: shipmentFormAction});
}

function entryShipmentAddressHandler({payload}: intents.EntryShipmentAddressIntent): PortalPage {
  let shipmentAddressFormAction: BszShipmentAddressFormAction;

  if (intents.isEntryShipmentAddressFromPartialIntentPayload(payload)) {
    shipmentAddressFormAction = {
      action: 'createFromPartial',
      shipmentAddressId: payload.shipmentAddressId,
    };
  } else {
    shipmentAddressFormAction = {
      action: 'create',
    };
  }

  return new pages.ShipmentAddressEntryPage({shipmentAddressFormAction});
}

function entryManifestHandler({payload}: intents.EntryManifestIntent): PortalPage {
  let manifestFormAction: BszManifestFormAction;

  if (intents.isEntryManifestFromPartialIntentPayload(payload)) {
    manifestFormAction = {
      action: 'update',
      manifestId: payload.manifestId,
    };
  } else {
    manifestFormAction = {
      action: 'create',
    };
  }

  return new pages.ManifestEntryPage({manifestFormAction: manifestFormAction});
}
