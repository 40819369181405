import {toFixedAndRound} from './number.utils';

export const NUMBER_CURRENCY_SCALE = 2;

export function parseNumberCurrency(value: number): number {
  return parseFloat(value.toFixed(NUMBER_CURRENCY_SCALE));
}

export function parseNumberCurrencyString(value: number): string {
  return Number(value).toFixed(NUMBER_CURRENCY_SCALE);
}

export function toFixedAndRoundCurrency(num: number): number {
  return toFixedAndRound(num, NUMBER_CURRENCY_SCALE);
}
