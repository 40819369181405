export enum ApiHttpErrorCodes {
  // User Authentication - START
  adminEditorNotAllowedInRequest = 'adminEditorNotAllowedInRequest', // fields from admin-editor user are not allowed in body request
  adminViewerBadRequestUEMAIL = 'adminViewerBadRequestUEMAIL', // fields from admin-viewer user are not found or well-formed in body request
  adminViewerBadRequestUID = 'adminViewerBadRequestUID', // fields from admin-viewer user are not found or well-formed in body request
  emailDuplicate = 'emailDuplicate', // on route 'auth/signup'
  emailPasswordIncorrect = 'emailPasswordIncorrect', // on route 'auth/signin'
  emailVerifyAlreadyDone = 'emailVerifyAlreadyDone', // on route 'auth/emailVerify'
  emailVerifyError = 'emailVerifyError', // on route 'auth/emailVerify'
  emailVerifyUserIsNotActive = 'emailVerifyUserIsNotActive', // on route 'auth/emailVerify'
  ownerUEMAILNotFoundInBody = 'ownerUEMAILNotFoundInBody', // Request must include field 'ownerUEMAIL' in body. This is required for the routes where only admin can access and need to assign an update to another user's object
  ownerUIDMismatchUEMAIL = 'ownerUIDMismatchUEMAIL',
  ownerUIDNotFoundInBody = 'ownerUIDNotFoundInBody', // Request must include field 'ownerUID' in body. This is required for the routes where only admin can access and need to assign an update to another user's object
  resetPasswordPreValidateBadRequest = 'resetPasswordPreValidateBadRequest',
  resetPasswordPreValidateUserNotFound = 'resetPasswordPreValidateUserNotFound',
  resetPasswordRequestBadRequest = 'resetPasswordRequestBadRequest',
  resetPasswordRequestRoleAdminNotAllowed = 'resetPasswordRequestRoleAdminNotAllowed',
  resetPasswordRequestRoleUserNotFound = 'resetPasswordRequestRoleUserNotFound',
  resetPasswordRequestTimeDelayToCreateNewToken = 'resetPasswordRequestTimeDelayToCreateNewToken',
  resetPasswordRequestUpdateUserError = 'resetPasswordRequestUpdateUserError',
  resetPasswordRequestUserIsNotActive = 'resetPasswordRequestUserIsNotActive',
  resetPasswordRequestUserNotFound = 'resetPasswordRequestUserNotFound',
  resetPasswordValidateBadRequest = 'resetPasswordValidateBadRequest',
  resetPasswordValidateHashPasswordError = 'resetPasswordValidateHashPasswordError',
  resetPasswordValidateTokenExpired = 'resetPasswordValidateTokenExpired',
  resetPasswordValidateUpdateUserError = 'resetPasswordValidateUpdateUserError',
  resetPasswordValidateUserIsNotActive = 'resetPasswordValidateUserIsNotActive',
  resetPasswordValidateUserNotFound = 'resetPasswordValidateUserNotFound',
  signupBadRequestError = 'signupBadRequestError', // on the body request of the signup endpoint there's an error
  userFirstSetupAlreadyDone = 'userFirstSetupAlreadyDone', // when user is activated, the first setup can only be done once, otherwise throw and error
  userInsufficientPrivileges = 'userInsufficientPrivileges',
  userIsNotActive = 'userIsNotActive', // on route 'auth/signin'
  userJwtTokenNotFound = 'userJwtTokenNotFound', // An authenticated user was not found in the request header auth token
  userNotAllowedToUpdateObjectOwner = 'userNotAllowedToUpdateObjectOwner', // Request is not allowed to update field 'objectOwnerId' directly.
  userNotFound = 'userNotFound',
  // User Authentication - END
  //
  // Third-Party - SENDINBLUE / BREVO - START
  sibSendTemplateEmailError = 'sibSendTemplateEmailError',
  // Third-Party - SENDINBLUE / BREVO - END
  //
  // paymentIntent and paymentMethod - START
  paymentIntentBadRequestErrorNonAllowedField = 'paymentIntentBadRequestErrorNonAllowedField',
  paymentIntentBadRequestErrorRequiredAmountAndCurrency = 'paymentIntentBadRequestErrorRequiredAmountAndCurrency',
  paymentIntentBadRequestErrorRequiredAmountPositiveNumber = 'paymentIntentBadRequestErrorRequiredAmountPositiveNumber',
  paymentIntentBadRequestErrorRequiredPaymentMethod = 'paymentIntentBadRequestErrorRequiredPaymentMethod',
  paymentIntentBadRequestErrorRequiredPaymentMethodType = 'paymentIntentBadRequestErrorRequiredPaymentMethodType',
  paymentIntentBadRequestErrorStatusNotAllowed = 'paymentIntentBadRequestErrorStatusNotAllowed',
  paymentIntentBadRequestErrorStatusProcessingForCollectionFunds = 'paymentIntentBadRequestErrorStatusProcessingForCollectionFunds',
  paymentIntentBadRequestErrorStatusProcessingForDebitAction = 'paymentIntentBadRequestErrorStatusProcessingForDebitAction',
  paymentIntentBadRequestErrorUnknownAllowedPaymentIntentActions = 'paymentIntentBadRequestErrorUnknownAllowedPaymentIntentActions',
  paymentIntentBadRequestPayoutMaxMinAmountError = 'paymentIntentBadRequestPayoutMaxMinAmountError',
  paymentIntentBadRequestTopupMaxMinAmountError = 'paymentIntentBadRequestTopupMaxMinAmountError',
  paymentIntentCancelTransactionBadRequestError = 'paymentIntentCancelTransactionBadRequestError',
  paymentIntentCancelTransactionNotFound = 'paymentIntentCancelTransactionNotFound',
  paymentIntentCancelTransactionNotUpdated = 'paymentIntentCancelTransactionNotUpdated',
  paymentIntentCancelTransactionRequiresExtTransactionAdminVouch = 'paymentIntentCancelTransactionRequiresExtTransactionAdminVouch',
  paymentIntentCancelTransactionStatusNotAllowed = 'paymentIntentCancelTransactionStatusNotAllowed',
  paymentIntentCaptureTransactionBadRequestError = 'paymentIntentCaptureTransactionBadRequestError',
  paymentIntentCaptureTransactionNotFound = 'paymentIntentCaptureTransactionNotFound',
  paymentIntentCaptureTransactionNotUpdated = 'paymentIntentCaptureTransactionNotUpdated',
  paymentIntentCaptureTransactionRequiresExtTransactionAdminVouch = 'paymentIntentCaptureTransactionRequiresExtTransactionAdminVouch',
  paymentIntentCaptureTransactionRequiresPaymentMethodTypeCollectionFunds = 'paymentIntentCaptureTransactionRequiresPaymentMethodTypeCollectionFunds',
  paymentIntentCaptureTransactionStatusNotAllowed = 'paymentIntentCaptureTransactionStatusNotAllowed',
  paymentIntentCreditActionRequiresWalletDestination = 'paymentIntentCreditActionRequiresWalletDestination',
  paymentIntentDebitActionNotSupportWalletDestination = 'paymentIntentDebitActionNotSupportWalletDestination',
  paymentIntentExecuteTransactionAlreadyExecuted = 'paymentIntentExecuteTransactionAlreadyExecuted',
  paymentIntentExecuteTransactionAppFeeError = 'paymentIntentExecuteTransactionAppFeeError',
  paymentIntentExecuteTransactionBadRequestError = 'paymentIntentExecuteTransactionBadRequestError',
  paymentIntentExecuteTransactionCollectionNotCaptured = 'paymentIntentExecuteTransactionCollectionNotCaptured',
  paymentIntentExecuteTransactionCreditError = 'paymentIntentExecuteTransactionCreditError',
  paymentIntentExecuteTransactionDebitError = 'paymentIntentExecuteTransactionDebitError',
  paymentIntentExecuteTransactionIsDeferredNotAllowed = 'paymentIntentExecuteTransactionIsDeferredNotAllowed',
  paymentIntentExecuteTransactionNotExecuted = 'paymentIntentExecuteTransactionNotExecuted',
  paymentIntentExecuteTransactionNotFound = 'paymentIntentExecuteTransactionNotFound',
  paymentIntentExecuteTransactionNotUpdated = 'paymentIntentExecuteTransactionNotUpdated',
  paymentIntentExecuteTransactionRequiresAppFee = 'paymentIntentExecuteTransactionRequiresAppFee',
  paymentIntentExecuteTransactionRequiresAppFeePositiveNumber = 'paymentIntentExecuteTransactionRequiresAppFeePositiveNumber',
  paymentIntentExecuteTransactionRequiresExtTransactionAdminVouch = 'paymentIntentExecuteTransactionRequiresExtTransactionAdminVouch',
  paymentIntentProcessExtPaymentError = 'paymentIntentProcessExtPaymentError',
  paymentIntentRequiresOrActionCreditOrActionDebit = 'paymentIntentRequiresOrActionCreditOrActionDebit',
  paymentIntentShipmentCollectionCaptureTransactionAlreadyCaptured = 'paymentIntentShipmentCollectionCaptureTransactionAlreadyCaptured',
  paymentIntentShipmentCollectionCaptureTransactionBadRequestError = 'paymentIntentShipmentCollectionCaptureTransactionBadRequestError',
  paymentIntentShipmentCollectionCaptureTransactionLabelCreatedStatusNotFound = 'paymentIntentShipmentCollectionCaptureTransactionLabelCreatedStatusNotFound',
  paymentIntentShipmentCollectionCaptureTransactionNotPaid = 'paymentIntentShipmentCollectionCaptureTransactionNotPaid',
  paymentIntentShipmentCollectionCaptureTransactionRunPaymentIntentError = 'paymentIntentShipmentCollectionCaptureTransactionRunPaymentIntentError',
  paymentIntentShipmentCollectionCaptureTransactionStatusError = 'paymentIntentShipmentCollectionCaptureTransactionStatusError',
  paymentIntentShipmentCollectionCaptureTransactionStatusUpdateError = 'paymentIntentShipmentCollectionCaptureTransactionStatusUpdateError',
  paymentIntentShipmentCollectionExecuteTransactionAlreadyExecuted = 'paymentIntentShipmentCollectionExecuteTransactionAlreadyExecuted',
  paymentIntentShipmentCollectionExecuteTransactionAppFeeNotAllowed = 'paymentIntentShipmentCollectionExecuteTransactionAppFeeNotAllowed',
  paymentIntentShipmentCollectionExecuteTransactionBadRequestError = 'paymentIntentShipmentCollectionExecuteTransactionBadRequestError',
  paymentIntentShipmentCollectionExecuteTransactionGoodsCollectionCurrencyNotFound = 'paymentIntentShipmentCollectionExecuteTransactionGoodsCollectionCurrencyNotFound',
  paymentIntentShipmentCollectionExecuteTransactionLabelCreatedStatusNotFound = 'paymentIntentShipmentCollectionExecuteTransactionLabelCreatedStatusNotFound',
  paymentIntentShipmentCollectionExecuteTransactionNotPaid = 'paymentIntentShipmentCollectionExecuteTransactionNotPaid',
  paymentIntentShipmentCollectionExecuteTransactionProfitCollectionFeeNotFound = 'paymentIntentShipmentCollectionExecuteTransactionProfitCollectionFeeNotFound',
  paymentIntentShipmentCollectionExecuteTransactionProfitVatPercentFeeNotFound = 'paymentIntentShipmentCollectionExecuteTransactionProfitVatPercentFeeNotFound',
  paymentIntentShipmentCollectionExecuteTransactionRunPaymentIntentError = 'paymentIntentShipmentCollectionExecuteTransactionRunPaymentIntentError',
  paymentIntentShipmentCollectionExecuteTransactionStatusError = 'paymentIntentShipmentCollectionExecuteTransactionStatusError',
  paymentIntentShipmentPurchaseTransactionAlreadyCreated = 'paymentIntentShipmentPurchaseTransactionAlreadyCreated',
  paymentIntentShipmentPurchaseTransactionFinalUpdateError = 'paymentIntentShipmentPurchaseTransactionFinalUpdateError',
  paymentIntentShipmentPurchaseTransactionRunPaymentIntentError = 'paymentIntentShipmentPurchaseTransactionRunPaymentIntentError',
  paymentIntentShipmentPurchaseTransactionShipmentCollectionAlreadyCreated = 'paymentIntentShipmentPurchaseTransactionShipmentCollectionAlreadyCreated',
  paymentIntentShipmentPurchaseTransactionShipmentCollectionCreateError = 'paymentIntentShipmentPurchaseTransactionShipmentCollectionCreateError',
  paymentIntentShipmentPurchaseTransactionShipmentCollectionRequiresAmountPositiveNumber = 'paymentIntentShipmentPurchaseTransactionShipmentCollectionRequiresAmountPositiveNumber',
  paymentIntentShipmentPurchaseTransactionTopupFromAvailableWalletError = 'paymentIntentShipmentPurchaseTransactionTopupFromAvailableWalletError',
  paymentIntentShipmentRefundTransactionAlreadyExecuted = 'paymentIntentShipmentRefundTransactionAlreadyExecuted',
  paymentIntentShipmentRefundTransactionBadRequestError = 'paymentIntentShipmentRefundTransactionBadRequestError',
  paymentIntentShipmentRefundTransactionNotPaid = 'paymentIntentShipmentRefundTransactionNotPaid',
  paymentIntentShipmentRefundTransactionRunPaymentIntentError = 'paymentIntentShipmentRefundTransactionRunPaymentIntentError',
  paymentIntentShipmentRefundTransactionStatusError = 'paymentIntentShipmentRefundTransactionStatusError',
  paymentIntentShipmentRefundTransactionStatusUpdateError = 'paymentIntentShipmentRefundTransactionStatusUpdateError',
  paymentIntentUnkownPaymentMethod = 'paymentIntentUnkownPaymentMethod',
  paymentIntentWalletInsufficientFunds = 'paymentIntentWalletInsufficientFunds',
  paymentMethodNotAllowedPaymentIntentAction = 'paymentMethodNotAllowedPaymentIntentAction',
  paymentMethodNotAllowedPaymentIntentWalletDestination = 'paymentMethodNotAllowedPaymentIntentWalletDestination',
  // paymentIntent and paymentMethod - END
  //
  // paylogEcPayphone - START
  paylogEcPayphoneButtonConfirmApiError = 'paylogEcPayphoneButtonConfirmApiError',
  paylogEcPayphoneConfirmationBadRequest = 'paylogEcPayphoneConfirmationBadRequest',
  paylogEcPayphoneConfirmationIdNotFoundError = 'paylogEcPayphoneConfirmationIdNotFoundError',
  paylogEcPayphoneConfirmationPaymentIntentNotCanceled = 'paylogEcPayphoneConfirmationPaymentIntentNotCanceled',
  paylogEcPayphoneConfirmationPaymentIntentTransactionAlreadyExecuted = 'paylogEcPayphoneConfirmationPaymentIntentTransactionAlreadyExecuted',
  paylogEcPayphoneConfirmationPaymentIntentTransactionRunError = 'paylogEcPayphoneConfirmationPaymentIntentTransactionRunError',
  paylogEcPayphoneConfirmationRecordNotFoundError = 'paylogEcPayphoneConfirmationRecordNotFoundError',
  paylogEcPayphoneConfirmationResTransactionStatusNotApproved = 'paylogEcPayphoneConfirmationResTransactionStatusNotApproved',
  paylogEcPayphoneConfirmationUpdateError = 'paylogEcPayphoneConfirmationUpdateError',
  paylogEcPayphoneCreateError = 'paylogEcPayphoneCreateError',
  paylogEcPayphonePrepareError = 'paylogEcPayphonePrepareError',
  paylogEcPayphonePrepareUpdateError = 'paylogEcPayphonePrepareUpdateError',
  paylogEcPayphoneToUpdateNotFound = 'paylogEcPayphoneToUpdateNotFound',
  paylogEcPayphoneUpdateCaseNotValid = 'paylogEcPayphoneUpdateCaseNotValid',
  paylogEcPayphoneUpdateError = 'paylogEcPayphoneUpdateError',
  // paylogEcPayphone - END
  //
  // shipment - START
  shipmentAddressBadRequestError = 'shipmentAddressBadRequestError',
  shipmentAddressSingleLineStreetMaxLengthError = 'shipmentAddressSingleLineStreetMaxLengthError',
  shipmentAlreadyPaid = 'shipmentAlreadyPaid',
  shipmentAnnulBadRequestError = 'shipmentAnnulBadRequestError',
  shipmentAnnulStatusError = 'shipmentAnnulStatusError',
  shipmentCalculateRatesError = 'shipmentCalculateRatesError',
  shipmentCarrierTokenError = 'shipmentCarrierTokenError',
  shipmentGenerateLabelPdfError = 'shipmentGenerateLabelPdfError',
  shipmentGenerateLabelRequestError = 'shipmentGenerateLabelRequestError',
  shipmentGoodsCollectionError = 'shipmentGoodsCollectionError',
  shipmentGoodsCollectionFeeNotFound = 'shipmentGoodsCollectionFeeNotFound',
  shipmentGoodsCollectionLowerThanGoodsValue = 'shipmentGoodsCollectionLowerThanGoodsValue',
  shipmentGoodsCollectionMaxAmountError = 'shipmentGoodsCollectionMaxAmountError',
  shipmentGoodsCollectionMinAmountError = 'shipmentGoodsCollectionMinAmountError',
  shipmentGoodsInsuredError = 'shipmentGoodsInsuredError',
  shipmentGoodsInsuredFeeNotFound = 'shipmentGoodsInsuredFeeNotFound',
  shipmentGoodsInsuredMaxAmountError = 'shipmentGoodsInsuredMaxAmountError',
  shipmentManifestCreateCarrierApiError = 'shipmentManifestCreateCarrierApiError',
  shipmentManifestCreateError = 'shipmentManifestCreateError',
  shipmentManifestCreateNoShipmentsError = 'shipmentManifestCreateNoShipmentsError',
  shipmentManifestCreateSameCarrierSameDateSameSenderDuplicateFound = 'shipmentManifestCreateSameCarrierSameDateSameSenderDuplicateFound',
  shipmentManifestRequestNotSameCarrierInEachShipmentError = 'shipmentManifestRequestNotSameCarrierInEachShipmentError',
  shipmentManifestUpdateAtLeastOneNewShipmentToLinkError = 'shipmentManifestUpdateAtLeastOneNewShipmentToLinkError',
  shipmentManifestUpdateError = 'shipmentManifestUpdateError',
  shipmentManifestUpdateNotSameCarrierAsOriginalManifest = 'shipmentManifestUpdateNotSameCarrierAsOriginalManifest',
  shipmentManifestUpdateNotSameManifestSenderAddressError = 'shipmentManifestUpdateNotSameManifestSenderAddressError',
  shipmentNotFound = 'shipmentNotFound',
  shipmentNotPaid = 'shipmentNotPaid',
  shipmentRatesError = 'shipmentRatesError',
  // shipment - END
  //
  // quotation - START
  quotationCarrierQuoteApiError = 'quotationCarrierQuoteApiError',
  quotationGoodsCollectionMaxAmountError = 'quotationGoodsCollectionMaxAmountError',
  quotationGoodsCollectionMinAmountError = 'quotationGoodsCollectionMinAmountError',
  quotationGoodsInsuredMaxAmountError = 'quotationGoodsInsuredMaxAmountError',
  // quotation - END
  //
  //
  // Third-Party - PAYMENTS / EC-PAYPHONE - START
  ecPayphoneLinksError = 'ecPayphoneLinksError',
  ecPayphonePaymentButtonPrepareError = 'ecPayphonePaymentButtonPrepareError',
  // Third-Party - PAYMENTS / EC-PAYPHONE - END
  //
  // Third-Party - CARRIER / EC-SERVIENTREGA - START
  ecServientregaLabelNotRegistered = 'ecServientregaLabelNotRegistered',
  ecServientregaLabelPdfError = 'ecServientregaLabelPdfError',
  ecServientregaLabelQueryRegisteredError = 'ecServientregaLabelQueryRegisteredError',
  ecServientregaLabelResponseError = 'ecServientregaLabelResponseError',
  ecServientregateLabelRequestError = 'ecServientregateLabelRequestError',
  // Third-Party - CARRIER / EC-SERVIENTREGA - END
  //
  // Third-Party - CARRIER / EC-TRAMACO - START
  ecTramacoLabelCollectionFeeCalculateError = 'ecTramacoLabelCollectionFeeCalculateError',
  ecTramacoLabelCollectionFeeNotFound = 'ecTramacoLabelCollectionFeeNotFound',
  ecTramacoLabelNotRegistered = 'ecTramacoLabelNotRegistered',
  ecTramacoLabelPdfError = 'ecTramacoLabelPdfError',
  ecTramacoLabelRegisteredError = 'ecTramacoLabelRegisteredError',
  ecTramacoLabelReqError = 'ecTramacoLabelReqError',
  ecTramacoLabelResError = 'ecTramacoLabelResError',
  ecTramacoLoginApiError = 'ecTramacoLoginApiError',
  ecTramacoQuoteApiError = 'ecTramacoQuoteApiError',
  ecTramacoTransformLabelReqApiToDb = 'ecTramacoTransformLabelReqApiToDb',
  ecTramacoTransformLabelResApiToDb = 'ecTramacoTransformLabelResApiToDb',
  ecTramacoTransformQuoteReqApiToDb = 'ecTramacoTransformQuoteReqApiToDb',
  // Third-Party - CARRIER / EC-TRAMACO - END
  //
  // Third-Party - CARRIER / <NEW_CARRIER> - START
  // Third-Party - CARRIER / <NEW_CARRIER> - END
}
