import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, IShipment, IUser} from '../../..';
import {UnionTypeFromArray} from '../../../../utils/common';

/**
 * Usage example of EC_TRAMACO_LABEL_TRACKING_URL, change the <%=carrierLabelUniqueId%> for the carrier's unique label ID.
 * Example:
 * - https://www.tramaco.com.ec/tracking/?guia=100000000000002
 *
 * - Guía real: https://www.tramaco.com.ec/tracking/?guia=031101000000009
 */
export const EC_TRAMACO_LABEL_TRACKING_URL = `https://www.tramaco.com.ec/tracking/?guia=<%=carrierLabelUniqueId%>`;

export const EC_TRAMACO_TARIFFS_V1 = 'v-2024-05-18';

export const EC_TRAMACO_MAX_GOODS_INSURED = 10000; // in USD
export const EC_TRAMACO_MAX_GOODS_COLLECTED = 300; // in USD // TODO p1 the real value is "200 USD", we set this to "300" so the FE can show a shipment quote and block it only in the FE, but the API call it will allow this value of 300 which is not allowed by the carrier. Change this to a better solution, also the env variable "EC_TRAMACO_LABEL_COLLECTION_LEVEL_2_MAX" must be corrected to "200"
export const EC_TRAMACO_MAX_GOODS_COLLECTED_REAL = 200; // in USD // TODO p1 delete this when fix the TODO of 'EC_TRAMACO_MAX_GOODS_COLLECTED'. Now this is used to block the selection of this carrier rate ONLY in the FE.

export enum EcTramacoShipmentProduct {
  'CARGA COURIER' = 36, // Use this for parcel weight from 0kg to 50kg inclusive. This product DOES produce VAT (IVA) tax.
  'CARGA LIVIANA' = 1, // Use this for parcel weight from 51kg and upwards. This product DOES NOT produce VAT (IVA) tax.
  'DOCUMENTOS' = 2,
}

export const EcTramacoShipmentProductType = ['CARGA COURIER', 'CARGA LIVIANA', 'DOCUMENTOS'] as const;
export type EcTramacoShipmentProductType = UnionTypeFromArray<typeof EcTramacoShipmentProductType>;

export enum ECTramacoContratoId {
  PROD_MAIN = '6351',
  DEV_MAIN = '5047',
}

export enum ECTramacoLocalidadId {
  PROD_MAIN = '21030',
  DEV_MAIN = '17835',
}

export interface EcTramacoApiEnvironment {
  apiBaseUrl: string;
  contratoId: ECTramacoContratoId;
  localidadId: ECTramacoLocalidadId;
}

export const EcTramacoCuerpoRespuestaCodigo = [
  '1', // OK  => EXITO
  '2', // NOK => ERROR
  '3', // NOK => EXEPCION
] as const;
export type EcTramacoCuerpoRespuestaCodigo = UnionTypeFromArray<typeof EcTramacoCuerpoRespuestaCodigo>;

export const EcTramacoCuerpoRespuestaMensaje = ['EXITO', 'ERROR', 'EXEPCION'] as const;
export type EcTramacoCuerpoRespuestaMensaje = UnionTypeFromArray<typeof EcTramacoCuerpoRespuestaMensaje>;

export interface IEcTramacoCuerpoRespuesta {
  codigo: EcTramacoCuerpoRespuestaCodigo;
  mensaje: EcTramacoCuerpoRespuestaMensaje;
  excepcion?: string;
}

export interface IEcTramacoLoginReq {
  login: string;
  password: string;
}

export interface IEcTramacoLoginRes {
  cuerpoRespuesta: IEcTramacoCuerpoRespuesta;
  salidaAutenticarUsuarioJWTWs: {
    token: string;
  };
}

export interface IEcTramacoCarga {
  alto: number;
  ancho: number;
  largo: number;
  bultos: number; // Don't use this, set it to zero
  cajas: number; // Currently set this to '1', as we only support one box package per shipment
  cantidadDoc?: number;
  contrato: ECTramacoContratoId;
  localidad?: ECTramacoLocalidadId;
  localidadDestino?: ECTramacoLocalidadId;
  peso: number;
  producto: EcTramacoShipmentProduct;
  valorAsegurado?: number; // NOTE: this should be used for shipment.goodsInsured
  valorCobro?: number; // NOTE: this should be used for shipment.goodsCollection
  adjuntos: string; // NOTE: When COD, it must be 'TRUE', when not-COD it MUST be set to an empty string, otherwise the API returns an 500 server error on label generation
  codigoAdjunto?: string; // NOTE: send the COD sequential ID for ecTramaco,
  referenciaTercero?: string;
  descripcion?: string; // NOTE: add here the package item type => ProductCategory
  observacion?: string;
  guia?: string;
}

export interface IEcTramacoAddress {
  nombres: string;
  apellidos: string;
  codigoParroquia: string;
  callePrimaria: string;
  numero: string; // same as Address.doorNumber
  calleSecundaria: string; // could be store an empty string ("")
  referencia: string; // could be store an empty string ("")
  telefono: string; // must be at least 9 characters (otherwise API error)
  tipoIden: string; // For simplicity always use value '07' (Venta consumidor final) which means don't require a valid national ID number. From Tramaco API: "Tipo de identificación (05 – Cédula, 04 – Ruc, 06 – Pasaporte, 07 – Venta consumidor final, 08 – Identificación del exterior, 09 - Placa)"
  ciRuc: string; // For simplicity always use value '0' as it cannot be empty. Value for the national ID number ("Cédula de Identidad" or Ecuadorian "RUC"). Could be store an empty string (""). TODO p2: add this value to the address form in the frontend which is used by the carrier's delivery man to identify the recipient upon package delivery.
  codigoPostal?: string;
  email?: string;
}

export interface IEcTramacoLabelRegisteredTransaccion {
  guia?: string; // Label ID
  origen?: string;
  destino?: string;
  parOrigen?: string;
  parDestino?: string;
  fecha?: string;
  puntoEmision?: string;
  cliente?: string;
  contrato?: string;
  observacion?: string;
  localidad?: string;
  descripcion?: string;
  centroCosto?: string;
  adjunto?: string;
  estado?: string;
  producto?: string;
  contenedor?: string;
  factura?: string;
  pesoCliente?: number;
  pesoReal?: number; // NOTE: used this value as the weight calculated by the carrier, and compare it to the user's input weight in the field 'pesoCliente'
  pesoValidado?: number; // Not found in the carrier's docs, but appear in the API response
  valorAsegurado?: number;
  paquetes?: number;
  gestorEntrega?: string;
  destinoValido?: string;
  quienRecibe?: string;
  entregadoPor?: string;
  latitud?: string; // of the recipient address
  longitud?: string; // of the recipient address
}

export interface IEcTramacoLabelRegisteredTracking {
  estado?: string;
  ciudad?: string;
  descripcion?: string;
  fechaHora?: string;
  transporte?: string;
  transportista?: string;
  usuario?: string;
  celularVpn?: string;
}

/**
 * The 'IEcTramacoQuoteReqApi' must be used as the request's body when calling the API,
 * This is used as a backup to store the body's request of the EcTramaco API endpoint to quote a shipment's price 'POST .../calcularPrecio'.
 * ---
 * The ec_tramaco API supports to send an array of 'lstCargaDestino', in order to quote multiple shipments in one single API call.
 * For simplicity in our DB, we only send one single item inside this array and we transform an object of this interface
 * to an object of the interface 'IEcTramacoQuoteReqDb' to store it in the Database ORM.
 */
export interface IEcTramacoQuoteReqApi {
  codParroquiaRemit: string;
  lstCargaDestino: [
    {
      carga: IEcTramacoCarga;
      codParroquiaDest: string;
      id: ECTramacoContratoId;
    }
  ];
}

/**
 * The interface 'IEcTramacoQuoteReqDb' is a transformed version of 'IEcTramacoQuoteReqApi', which is used for the Database ORM.
 */
export interface IEcTramacoQuoteReqDb {
  codParroquiaRemit: string;
  carga: IEcTramacoCarga;
  codParroquiaDest: string;
  id?: ECTramacoContratoId;
}

/**
 * The 'IEcTramacoQuoteApiRes, is used as a backup to store the response's data of the EcTramaco API endpoint to quote a shipment's price 'POST .../calcularPrecio'.
 * ---
 * The ec_tramaco API supports to send an array of 'lstGuias', in order to receive the quote multiple shipments in one single API call.
 * For simplicity in our DB, we only send one single item inside this array and we transform an object of this interface
 * to an object of the interfac 'IEcTramacoQuoteResDb' to store it in the Database ORM.
 */
export interface IEcTramacoQuoteApiRes {
  cuerpoRespuesta: IEcTramacoCuerpoRespuesta;
  salidaCalcularPrecioGuiaWs: {
    lstGuias: [
      {
        id: ECTramacoContratoId;
        iva: number;
        ivaCalculado: number;
        ivaSeguro: number;
        lstProducto: [
          {
            idProducto: EcTramacoShipmentProduct;
            nombreProducto: keyof typeof EcTramacoShipmentProduct;
            porcentajeIva: number;
            valor: number;
            valorIva: number;
            valorIvaPrima: number;
            valorPrima: number;
          }
        ];
        lstServicio?: any[];
        seguro: number;
        subTotal: number;
        subtotalConIva: number;
        subtotalSinIva: number;
        total: number;
        totalCalculado: number;
      }
    ];
  };
}

/**
 * The interface 'IEcTramacoQuoteResDb' is a transformed version of 'IEcTramacoQuoteApiRes', which is used for the Database ORM.
 */
export interface IEcTramacoQuoteResDb {
  cuerpoRespuesta: IEcTramacoCuerpoRespuesta;
  // salidaCalcularPrecioGuiaWs: {
  //   lstGuias: [
  //     {
  id?: ECTramacoContratoId;
  iva?: number;
  ivaCalculado?: number;
  ivaSeguro?: number;
  // lstProducto: [
  //   {
  idProducto?: EcTramacoShipmentProduct;
  nombreProducto?: keyof typeof EcTramacoShipmentProduct;
  porcentajeIva?: number;
  valor?: number;
  valorIva?: number;
  valorIvaPrima?: number;
  valorPrima?: number;
  //   }
  // ];
  // lstServicio?: any[];
  seguro?: number;
  subTotal?: number;
  subtotalConIva?: number;
  subtotalSinIva?: number;
  total?: number;
  totalCalculado?: number;
  //     }
  //   ];
  // };
}

/**
 * 'IEcTramacoLabelReqApi' is used as a backup to store the body's request of the EcTramaco API endpoint to create a shipment label 'POST .../generarGuia'.
 *
 */
export interface IEcTramacoLabelReqApi {
  lstCargaDestino: [
    {
      id: ECTramacoContratoId;
      destinatario: IEcTramacoAddress;
      carga: IEcTramacoCarga;
      datoAdicional?: {
        motivo?: string;
        citacion?: string;
        boleta?: string;
      };
    }
  ];
  remitente: IEcTramacoAddress;
}

/**
 * The interface 'IEcTramacoLabelReqDb' is a transformed version of 'IEcTramacoLabelReqApi', which is used for the Database ORM.
 */
export interface IEcTramacoLabelReqDb {
  // lstCargaDestino: [
  // {
  id: ECTramacoContratoId;
  destinatario: IEcTramacoAddress;
  carga: IEcTramacoCarga;
  // datoAdicional?: {
  //   motivo?: string;
  //   citacion?: string;
  //   boleta?: string;
  // };
  // }
  // ];
  remitente: IEcTramacoAddress;
}

/**
 * 'IEcTramacoLabelResApi' is used as a backup to store the response data of the EcTramaco API endpoint to create a shipment label 'POST .../generarGuia'.
 *
 */
export interface IEcTramacoLabelResApi {
  cuerpoRespuesta: IEcTramacoCuerpoRespuesta;
  salidaGenerarGuiaWs?: {
    lstGuias?: [
      {
        id?: ECTramacoContratoId;
        guia?: string; // LABEL ID
      }
    ];
  };
}

/**
 * The interface 'IEcTramacoLabelResDb' is a transformed version of 'IEcTramacoLabelResApi', which is used for the Database ORM.
 */
export interface IEcTramacoLabelResDb {
  cuerpoRespuesta: IEcTramacoCuerpoRespuesta;
  // salidaGenerarGuiaWs: {
  // lstGuias: [
  // {
  id?: ECTramacoContratoId;
  guia?: string; // NOTE: label's ID in EcTramaco
  // }
  // ];
  // };
}

/**
 * 'IEcTramacoLabelRegistered' is used as a backup to store the response data of the EcTramaco API endpoint to query an already created shipment label 'POST .../consultarTracking'.
 * This Carrier's endpoint also contains the tracking of the package, and should be used to display the location of the package during delivery.
 *
 */
export interface IEcTramacoLabelRegistered {
  type?: string; // 'respuestaTrackGuiaWs' // Not needed and we MUST delete this from the response before saving to the DB
  cuerpoRespuesta: IEcTramacoCuerpoRespuesta;
  lstSalidaTrackGuiaWs: IEcTramacoLabelRegisteredTracking[];
  lstServicios?: []; // Not needed and we MUST delete this from the response before saving to the DB
  transaccion: IEcTramacoLabelRegisteredTransaccion;
  destinatario: IEcTramacoAddress;
  remitente: IEcTramacoAddress;
}

/**
 * 'IEcTramacoLabelPdf' is used as a backup to store the response of the EcTramaco API endpoint to generate the label PDF 'POST .../generarPdf',
 */
export interface IEcTramacoLabelPdf {
  cuerpoRespuesta?: IEcTramacoCuerpoRespuesta;
  pdfBase64?: string; // Base64 PDF content
  pdfUrl?: string; // URL of the PDF stored in a cloud storage service // TODO p2, add a link to the pdf URL when cloud file storage service is implemented
}

export interface IEcTramacoLabel extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;
  quoteReq?: IEcTramacoQuoteReqDb;
  quoteRes?: IEcTramacoQuoteResDb;
  labelReq?: IEcTramacoLabelReqDb;
  labelRes?: IEcTramacoLabelResDb;
  registered?: IEcTramacoLabelRegistered;
  pdf?: IEcTramacoLabelPdf;

  /**
   * OneToOne relation to shipment
   */
  shipment?: Relation<IShipment>;
}
