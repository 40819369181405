import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {PaymentMethodWalletOverviewPage} from './payment-method-wallet-overview.page';

export interface PaylogEcpayphoneConfirmationPagePayload {
  foo?: never; //todo define the payload properties
}

export class PaylogEcpayphoneConfirmationPage extends PortalPage {
  constructor(public payload: PaylogEcpayphoneConfirmationPagePayload = {}) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new PaymentMethodWalletOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.paylog-ecpayphone-confirmation.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.paylog-ecpayphone-confirmation.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.paylog-ecpayphone-confirmation.window-title');
  }

  static readonly routerConfigPath = 'wallet/ecpayphone-confirmation'; // WARNING: this route MUST be the same as 'PAYMENTS_EC_PAYPHONE_WEB_PAYMENT_WEBHOOK_URL_CONFIRMATION'
  get route(): Array<string> {
    return [`/${PaylogEcpayphoneConfirmationPage.routerConfigPath}`];
  }
}
