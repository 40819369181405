import {
  EcLaarTrayecto,
  IEcLaarLabelRegistered,
  IEcLaarLabelReq,
  IEcLaarLabelRes,
  IEcLaarLoginRes,
  IEcLaarQuoteReq,
  IEcLaarQuoteRes,
  IEcLaarTrackingRes,
} from './ec-laar-label.model';

export const EC_LAAR_LOGIN_RES: IEcLaarLoginRes = {
  token: 'FAKE_JWT_TOKEN',
  nombre: 'username',
  ruc: '1234567890',
  codigoUsuario: 123,
  codigoSucursal: 456,
};

// https://api.laarcourier.com:9727/guias/v2/estados/LC49581808
export const EC_LAAR_TRACKING_RES_MOCK: IEcLaarTrackingRes = {
  guia: 'LC49581808',
  idEstado: 7,
  estado: 'Entregado',
  fecha: '2024-11-19T16:55:44.023',
  coordenadas: {
    latitud: '-1.4889285',
    longitud: '-78.0076831',
  },
};

// https://api.laarcourier.com:9727/cotizadores/tarifa/normal
export const EC_LAAR_QUOTE_REQ_MOCK: IEcLaarQuoteReq = {
  codigoServicio: 201202002002013,
  codigoCiudadOrigen: 201001001001,
  codigoCiudadDestino: 201001001001,
  piezas: 1,
  peso: 2,
};

export const EC_LAAR_QUOTE_RES_MOCK: IEcLaarQuoteRes = {
  Flete: 4.0,
  DescValor: 0.0,
  DescPorce: 0.0,
  Seguro: 0.0,
  TipoSeguro: 'NORMAL',
  Trayecto: EcLaarTrayecto.LOCAL,
};

// https://api.laarcourier.com:9727/guias/contado
export const EC_LAAR_LABEL_REQ_MOCK: IEcLaarLabelReq = {
  origen: {
    identificacionO: '12234567899',
    ciudadO: '201001001001',
    nombreO: 'John Smith',
    direccion: '4th Av. San Juan',
    referencia: '',
    numeroCasa: '',
    postal: '',
    telefono: '123456789',
    celular: '123456789',
  },
  destino: {
    identificacionD: '12234567898', // optional
    ciudadD: '201001001001',
    nombreD: 'Valentina Enriquez',
    direccion: 'Av. Buenos Aires',
    referencia: '5th Av.', // optional
    numeroCasa: '',
    postal: '',
    telefono: '123456789', // optional
    celular: '123456789',
  },
  // numeroGuia: '', // string optional sin caracteres especiales, ni espacios en blanco
  tipoServicio: 201202002002013,
  noPiezas: 1,
  peso: 2,
  valorDeclarado: 10, // optional
  contiene: 'ROPA',
  tamanio: '', // optional
  cod: false, // optional
  costoflete: 0, // "si tiene valor de cod true el campo obligario"
  costoproducto: 0, // "si tiene valor de cod true el campo obligario"
  tipocobro: 0, // optional,
  comentario: 'string', // optional
  extras: {
    Campo1: 'string',
    Campo2: 'string',
    Campo3: 'string',
  },
};

export const EC_LAAR_LABEL_RES_OK_MOCK: IEcLaarLabelRes = {
  guia: 'SISLCSISLC49384969',
  url: 'https://api.laarcourier.com:9727/guias/pdfs/DescargarV2?guia=SISLCSISLC49384969',
};

export const EC_LAAR_LABEL_RES_NOK_MOCK: IEcLaarLabelRes = {
  Message: 'Codigo de ciudad de origen no  existe. :(',
};

export const EC_LAAR_REGISTERED_MOCK: IEcLaarLabelRegistered = {
  noGuia: 'SISLCguiatqqes2024',
  nombreCliente: 'LAARCOURIER SISTEMAS  -  7',
  sucursal: 'QUITO - API',
  ciudadOrigen: 'QUITO',
  ciudadDestino: 'QUITO',
  productoFacturado: 'CARGA_SERV',
  producto: 'Servicio de Carga Express',
  de: 'Joel Corona',
  direccionOrigen: 'Valencia calle #30-584 63541 Cerca de la plaza',
  telefonoOrigen1: '+582416692689 5841',
  telefonoOrigen2: null,
  para: 'LUIS MONTUFAR',
  direccionDestino: 'El Inca',
  telefonoDestino: '0990566211     ',
  telefonoDestino2: '0990566211     ',
  numeroEnvios: 1,
  pesoKilos: 10.0,
  remitente: null,
  remitenteFecha: null,
  remitenteFecha1: null,
  destinatario: null,
  destinatarioFecha: null,
  destinatarioFecha1: null,
  observaciones: null,
  estadoActual: 'Pendiente',
  estadoActualCodigo: 1,
  entregadoSMS: null,
  fechaEnvioSMS: null,
  entregadoEmail: null,
  fechaEnvioEmail: null,
  acuseRecibido: null,
  confirmacionLectura: null,
  cc: null,
};

// https://fenixoper.laarcourier.com/Tracking/GuiaCompleta.aspx?guia=LC49581808
export const EC_LAAR_REGISTERED_MOCK_PROD: IEcLaarLabelRegistered = {
  noGuia: 'LC49581808',
  nombreCliente: 'SENDIFICO S.A.S.  -  82601',
  sucursal: 'MATRIZ',
  ciudadOrigen: 'QUITO',
  ciudadDestino: 'PUYO',
  productoFacturado: 'CARGA_SERV',
  producto: 'Servicio de Carga Express',
  de: 'Armando Fonseca // Domicilio',
  direccionOrigen: 'Arturo Yanez 243  Diagonal a la parada de buses San Jose de Cutuglagua',
  telefonoOrigen1: '0967019527 -',
  telefonoOrigen2: null,
  para: 'Ulvio Elías Pilamala Villena',
  direccionDestino: 'Antonio José de Sucre',
  telefonoDestino: '096 820 9112   ',
  telefonoDestino2: '-              ',
  numeroEnvios: 1,
  pesoKilos: 8.0,
  remitente: null,
  remitenteFecha: '19 Nov 2024 09:37:20:80',
  remitenteFecha1: '20241119 09:37:20',
  destinatario: 'ulvio vilema ',
  destinatarioFecha: '19 Nov 2024 16:55:23:00',
  destinatarioFecha1: '20241119 16:55:23',
  observaciones: null,
  estadoActual: 'Entregado',
  estadoActualCodigo: 7,
  entregadoSMS: null,
  fechaEnvioSMS: null,
  entregadoEmail: null,
  fechaEnvioEmail: null,
  acuseRecibido: null,
  confirmacionLectura: null,
  cc: null,
  // tracking: {
  //   '2.00': {
  //     nombre: 'Por Recolectar',
  //     fecha: '2024-11-18T08:42:27.583',
  //   },
  //   '3.00': {
  //     nombre: 'Recolectado',
  //     fecha: '2024-11-19T09:37:20.847',
  //   },
  //   '4.00': {
  //     nombre: 'En Bodega',
  //     fecha: '2024-11-19T09:37:20.883',
  //   },
  //   '6.00': {
  //     nombre: 'Zona de Entrega',
  //     fecha: '2024-11-19T09:38:35.623',
  //   },
  //   '7.00': {
  //     nombre: 'Entregado',
  //     fecha: '2024-11-19T16:55:23',
  //   },
  // },
  // novedades: [],
  // imagenes: [
  //   {
  //     imagen: '',
  //     name: 'LC49581808_191120241.jpg',
  //     fecha: '11/19/2024 4:55:42 PM',
  //     estado: 'Entregado',
  //     novedad: 'ENTREGADO',
  //     tipo: 'Distribucion',
  //     fileExtension: '.jpg',
  //   },
  //   {
  //     imagen: '',
  //     name: 'LC49581808_191120242.jpg',
  //     fecha: '11/19/2024 4:55:42 PM',
  //     estado: 'Entregado',
  //     novedad: 'ENTREGADO',
  //     tipo: 'Distribucion',
  //     fileExtension: '.jpg',
  //   },
  //   {
  //     imagen: '',
  //     name: 'LC49581808_191120243.jpg',
  //     fecha: '11/19/2024 4:55:42 PM',
  //     estado: 'Entregado',
  //     novedad: 'ENTREGADO',
  //     tipo: 'Distribucion',
  //     fileExtension: '.jpg',
  //   },
  //   {
  //     imagen: '',
  //     name: 'LC49581808_191120244.jpg',
  //     fecha: '11/19/2024 4:55:42 PM',
  //     estado: 'Entregado',
  //     novedad: 'ENTREGADO',
  //     tipo: 'Distribucion',
  //     fileExtension: '.jpg',
  //   },
  // ],
  // novedadesRecoleccion: null,
  // imagenesRecoleccion: null,
  // coordenadasRecoleccion: null,
  // coordenadasEntrega: null,
  // novedadesAduana: [],
};
